import * as Sentry from '@sentry/react';
import axios from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setTokens, signOut } from 'auth';

// returns diff to exp in ms
const getExpirationTimeDifference = (token) => {
   const t = jwtDecode(token);
   if (!t?.exp) return null;
   return dayjs.unix(t.exp).diff(new Date(), 'ms');
};

const refreshToken = () =>
   axios
      .post('token/refresh', {
         refresh_token: localStorage.getItem('refreshToken'),
      })
      .then(({ data }) => {
         setTokens(data.token, data.refresh_token);
      })
      .catch((e) => {
         signOut();
         Sentry.captureException(e);
      });

axios.interceptors.request.use(
   (c) => {
      if (
         c.url === '/login' ||
         c.url === '/user-forgot-password' ||
         c.url.includes('/user-forgot-password-new-password') ||
         c.url === 'token/refresh' ||
         c.url.endsWith('without-auth')
      ) {
         return c;
      }
      const t = localStorage.getItem('token');
      if (!t) {
         signOut();
         return c;
      }
      const exp = getExpirationTimeDifference(t);
      if (exp <= 0) {
         signOut();
         return c;
      }
      if (exp <= 120000) {
         refreshToken();
      }
      const newC = {
         ...c,
      };
      newC.headers.Authorization = `Bearer ${t}`;
      return newC;
   },
   (e) => {
      return Promise.reject(e);
   },
);

const AppAuth = ({ children }) => {
   const dispatch = useDispatch();
   const { authenticated } = useSelector((s) => s.app.auth);
   const t = localStorage.getItem('token');
   useEffect(() => {
      if (!t || authenticated) return;
      const rt = localStorage.getItem('refreshToken');
      setTokens(t, rt);
      dispatch({ type: 'SIGN_IN_FROM_TOKEN' });
   }, [t, authenticated]);

   return children;
};

export default AppAuth;
