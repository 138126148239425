import styled from 'styled-components';

const Container = styled.div`
   background: #fff;
   border-radius: 4px;
   padding-top: 1rem;
   padding-bottom: 1rem;
`;

const FullInternalContainer = ({ children, className }) => {
   return <Container className={`container-fluid ${className}`}>{children}</Container>;
};

export default FullInternalContainer;
