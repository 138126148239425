import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import InternalContainer from 'Layouts/InternalContainer';
import Month from 'Pages/PaintShopStatistics/Month';
import Error from 'Shared/Error';
import Loading from 'Shared/Loading';
import { PRODUCT_TYPES_POLISH } from 'Shared/product';
import defaults from 'Shared/useQuery';

const fetchAll = () =>
   axios.get('/paint-shop/statistics', {
      params: {},
   });

const pointsPolish = {
   ...PRODUCT_TYPES_POLISH,
   panel: 'Panele',
   pole: 'Słupki 60x40',
   poleWithAutomation: 'Reszta słupków',
   rod: 'Ceowniki i obejmy',
   rail: 'Sztachety',
   default: 'Domyślnie',
};

const PaintShopStatistics = () => {
   const dispatch = useDispatch();
   const paintShopStatistics = useSelector((s) => s.warehouse.paintShopStatistics);
   const { isLoading, error } = useQuery(['fetchPaintShopStatistics', {}], () => fetchAll(), {
      ...defaults,
      onSuccess: ({ data }) => {
         dispatch({
            type: 'FETCH_PAINT_SHOP_STATISTICS',
            data: data.statistics,
         });
      },
   });
   const rendered =
      paintShopStatistics &&
      Object.keys(paintShopStatistics.weights).map((key) => {
         return (
            <li key={key}>
               {pointsPolish[key] || key}: {paintShopStatistics.weights[key]} punktów
            </li>
         );
      });
   return (
      <InternalContainer>
         <Helmet>
            <title>Lakiernia &gt; Statystyki | Konsport</title>
         </Helmet>
         <div className="row">
            <div className="col">
               {isLoading && <Loading />}
               {error && <Error />}
            </div>
         </div>
         {!!paintShopStatistics && (
            <div className="row">
               <div className="col" />
               <Month title="Obecna zmiana" month={paintShopStatistics.currentShift} />
            </div>
         )}
         {!!paintShopStatistics && (
            <div className="row">
               <Month title="Poprzedni miesiąc" month={paintShopStatistics.prevMonth} />
               <Month title="Obecny miesiąc" month={paintShopStatistics.currentMonth} />
            </div>
         )}
         {paintShopStatistics && (
            <div className="row">
               <div className="col">
                  <Header>
                     <FontAwesomeIcon icon={faCalculator} />
                     Jak to policzyliśmy?
                  </Header>
                  <p>
                     Dany produkt mnożony jest przez następującą ilość punktów, a cała belka to{' '}
                     {paintShopStatistics.log} punktów.
                  </p>
                  <Points>{rendered}</Points>
               </div>
            </div>
         )}
      </InternalContainer>
   );
};

const Header = styled.h3`
   margin-top: 2rem;
   margin-bottom: 1rem;
   .svg-inline--fa {
      margin-right: 1rem;
   }
`;

const Points = styled.ul`
   margin-bottom: 2rem;
`;

export default PaintShopStatistics;
