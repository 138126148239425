import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import { setTokens } from 'auth';
import { Button, Typography } from 'designSystem';
import Accounts from 'Pages/Login/Accounts';
import { Response } from 'Shared/Response';

const IconStyle = {
   position: 'absolute',
   opacity: '0.5',
   top: '10px',
   left: '15px',
};

const validationSchema = yup.object().shape({
   email: yup.string().email().required(),
   password: yup.string().min(3).required(),
});

const Login = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [info, setInfo] = useState('');
   const [error, setError] = useState(false);
   const authenticated = useSelector((s) => s.app.auth.authenticated);
   const formik = useFormik({
      initialValues: {
         email: '',
         password: '',
      },
      onSubmit: async (values) => {
         setInfo('');
         setError(false);
         await axios
            .post('/login', {
               username: values.email,
               password: values.password,
            })
            .then(({ data }) => {
               setTokens(data.token, data.refresh_token);
               dispatch({ type: 'SIGN_IN' });
            })
            .catch((err) => {
               if (!err.response?.status) {
                  Sentry.captureException(err);
                  setInfo(t('common:error.errorOccurred'));
                  setError(true);
                  return;
               }
               setInfo(t('common:error.incorrectLoginOrPassword'));
               setError(true);
            });
      },
      validationSchema,
   });

   if (authenticated) {
      return <Navigate to={{ pathname: '/' }} />;
   }

   return (
      <FormikProvider value={formik}>
         <div className="container-xxl">
            <LoginContainerRow className="row">
               <ContainerCol4 className="col-sm-4">
                  <LoginFormContainer>
                     <form onSubmit={formik.handleSubmit}>
                        <Accounts />
                        <div>
                           <Typography variant="h1">{t('common:link.logIn')}</Typography>{' '}
                           <Typography variant="body2" gutterBottom>
                              <Trans i18nKey="common:message.logIn" components={{ br: <br /> }} />
                           </Typography>
                        </div>
                        <div />
                        <InputContainer className="form-group">
                           <FontAwesomeIcon icon={faUser} style={IconStyle} />
                           <LoginFormInput
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder={t('common:link.logIn')}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              autoFocus
                           />
                        </InputContainer>
                        <InputContainer className="form-group">
                           <FontAwesomeIcon icon={faLock} style={IconStyle} />
                           <LoginFormInput
                              type="password"
                              name="password"
                              className="form-control"
                              placeholder={t('common:label.password')}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                           />
                        </InputContainer>
                        <ButtonsContainer>
                           <Button type="submit" fullWidth loading={formik.isSubmitting}>
                              {t('common:link.logIn')}
                           </Button>
                        </ButtonsContainer>
                        <div>
                           <Link to="/forgot-password" style={{ color: '#959595' }}>
                              <ForgotPasswordSpan>
                                 {t('common:link.forgotPassword')}
                              </ForgotPasswordSpan>
                           </Link>
                        </div>
                        <ResponseContainer>
                           <Response error={error} messages={[info]} />
                        </ResponseContainer>
                     </form>
                  </LoginFormContainer>
               </ContainerCol4>
               <div className="col-sm-8">
                  <div>
                     <Image src="img/home-background.jpg" alt="" />
                  </div>
               </div>
            </LoginContainerRow>
         </div>
      </FormikProvider>
   );
};

const LoginContainerRow = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
`;

const ContainerCol4 = styled.div`
   position: relative;
   &:after {
      content: '';
      position: absolute;
      top: 0;
      background: #ffffff;
      width: 90px;
      height: 100%;
      right: -45px;
   }
`;

const LoginFormContainer = styled.div`
   height: 100%;
   padding: ${process.env.REACT_APP_API_URL === 'http://localhost:8070/api'
      ? '1rem 3rem 1rem 3rem'
      : '8rem 3rem 6rem 3rem'};
   background: #ffffff;
   border-radius: 22px 0 0 22px;
`;

const LoginFormInput = styled.input`
   background: #f2f2f2;
   border-radius: 22px;
   border: none;
   padding-left: 2.5rem;

   &:focus {
      background: #f2f2f2;
      outline: none;
      border: none;
      box-shadow: none;
   }
`;

const InputContainer = styled.div`
   position: relative;
   margin-bottom: 0.5rem;
`;

const ButtonsContainer = styled.div`
   display: flex;
   flex-direction: row;
   align-content: center;
   justify-content: flex-start;
   margin: 1rem 0 1.5rem 0;
`;

const ForgotPasswordSpan = styled.span`
   color: #959595;
   &:hover {
      cursor: pointer;
   }
`;

const Image = styled.img`
   width: 100%;
   border-radius: 22px;
`;

const ResponseContainer = styled.div`
   margin-top: 2rem;
`;

export default Login;
