import styled, { css } from 'styled-components';

import { ChipStyledProps } from './Chip.types';

export const Chip = styled.span<ChipStyledProps>(
   ({ theme: { spacing, palette, borderRadius }, $color }) => css`
      display: inline-flex;
      flex-shrink: 0;
      font-size: 18px;
      font-weight: 600;
      padding: ${spacing(1, 4)};
      background: ${palette.background.status[$color]};
      color: ${palette.text.status[$color]};
      border: 1px solid ${palette.border.status[$color]};
      border-radius: ${borderRadius(10)};
   `,
);
