import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const CloseCalendarButton = ({ setShow }) => {
   return (
      <Button className="btn btn-danger btn-icon-left" onClick={() => setShow((p) => !p)}>
         <FontAwesomeIcon icon={faTimes} />
         zamknij kalendarz
      </Button>
   );
};

const Button = styled.button`
   width: 100%;
   margin-bottom: 1rem;
`;

export default CloseCalendarButton;
