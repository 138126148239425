import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Context, { findDay } from 'Pages/PaintShopCalendar/context';

const types = {
   'color-change': 'Zmiana koloru',
   failure: 'Awaria',
   custom: 'Inne',
};

const extraMap = {
   amountOfPaint: 'Ilość farby',
};

const IssuesSummary = ({ index }) => {
   const { refetch } = useContext(Context);
   const [show, setShow] = useState(false);
   const currentDay = useSelector((s) => s.paintShopCalendar.currentDay);
   const days = useSelector((s) => s.paintShopCalendar.days);
   const day = findDay(days, dayjs(currentDay).format('DD-MM-YYYY'));
   if (!day?.shifts?.[index]?.issues.length) return null;

   const removeIssue = async (createdAt) => {
      await axios.delete(
         `/paint-shop/calendar/days/${dayjs(currentDay).format(
            'YYYY-MM-DD',
         )}/shifts/${index}/issues/${dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')}`,
      );
      refetch();
   };

   const rendered = day.shifts[index].issues.map((i) => {
      const extra = [];
      if (i.extra) {
         Object.keys(i.extra).forEach((k) => {
            extra.push(
               <span key={k}>
                  {extraMap[k]}: {i.extra[k]}
               </span>,
            );
         });
      }
      return (
         <TableRow key={i.createdAt}>
            <td>{types[i.type]}</td>
            <td>{i.costsInMinutes} minut</td>
            <td>{i.createdAt}</td>
            <td>{i.description}</td>
            <td>{extra}</td>
            <td>
               <IconContainer className="icon-container" onClick={() => removeIssue(i.createdAt)}>
                  <RemoveIcon icon={faTimes} />
               </IconContainer>
            </td>
         </TableRow>
      );
   });

   return (
      <Container>
         <span>
            Szczegóły {day.shifts[index].issues.length}
            <button type="button" className="btn btn-link" onClick={() => setShow((s) => !s)}>
               ({show ? 'schowaj' : 'pokaż'})
            </button>
         </span>
         {show && (
            <table className="table table-sm">
               <tbody>{rendered}</tbody>
            </table>
         )}
      </Container>
   );
};

const Container = styled.div``;

const RemoveIcon = styled(FontAwesomeIcon)`
   color: #fff;
`;

const IconContainer = styled.div`
   display: none !important;
   background-color: #dc3545;
   width: 1.2rem;
   border-radius: 50%;
   padding: 2px;
   display: flex;
   justify-content: center;
   margin: 50%;
   &:hover {
      cursor: pointer;
      background-color: #c82333;
   }
`;

const TableRow = styled.tr`
   :hover {
      .icon-container {
         display: flex !important;
      }
   }
`;

export default IssuesSummary;
