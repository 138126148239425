import {
   palette as deprecatedPalette,
   Palette as DeprecatedPalette,
} from 'deprecatedTheme/palette';
import { screen as deprecatedScreen, Screen as DeprecatedScreen } from 'deprecatedTheme/screen';
import {
   spacing as deprecatedSpacing,
   Spacings as DeprecatedSpacings,
} from 'deprecatedTheme/spacing';
import { FontSize, fontSize } from 'designSystem/tokens/fontSize';
import { DEFAULT_LINE_HEIGHT } from 'designSystem/tokens/lineHeight';
import { createPalette } from 'designSystem/tokens/palette';
import { Palette, PaletteMode } from 'designSystem/tokens/types/types';
import { Unit } from 'designSystem/tokens/units';

import { spacing, Spacings } from '../tokens/spacing';

export type Theme = {
   spacing: (...args: Spacings) => string;
   borderRadius: (...args: Spacings) => string;
   lineHeight: number;
   palette: Palette;
   fontSize: (size: FontSize) => string;
   deprecated: {
      palette: DeprecatedPalette;
      screen: DeprecatedScreen;
      spacing: (...args: (keyof DeprecatedSpacings)[]) => string;
   };
};

export const createTheme = (defaultUnit: Unit, mode: PaletteMode): Theme => {
   return {
      spacing: (...spacings) => spacing(defaultUnit, ...spacings),
      borderRadius: (...spacings) => spacing(defaultUnit, ...spacings),
      fontSize: (size) => fontSize(defaultUnit, size),
      palette: createPalette(mode),
      lineHeight: DEFAULT_LINE_HEIGHT,
      // To be removed after migration
      deprecated: {
         palette: deprecatedPalette,
         screen: deprecatedScreen,
         spacing: deprecatedSpacing,
      },
   };
};
