import styled from 'styled-components';

import Add from './Add';

const Hooks = ({ order }) => {
   const rendered = order.hooks.map((h) => <li key={h.name}>{h.name}</li>);

   return (
      <div>
         <div>
            <SavedHooks>{rendered}</SavedHooks>
         </div>
         <Add order={order} />
      </div>
   );
};

const SavedHooks = styled.ul`
   padding-left: 1.5rem;
   font-size: 0.9rem;

   button {
      padding: 0;
      font-size: 1rem;
   }
`;

export default Hooks;
