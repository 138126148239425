import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Order from './Order';

const Orders = () => {
   const orders = useSelector((s) => s.warehouse.orders);
   const rendered = orders.map((o) => <Order key={o.id} order={o} />);

   return (
      <Container>
         <div>
            <table className="table">
               <thead>
                  <Tr>
                     <th>Id</th>
                     <th>Lista produktów</th>
                     <th>Kolor</th>
                     <th>Pomalowano</th>
                  </Tr>
               </thead>
               <tbody>{rendered}</tbody>
            </table>
         </div>
      </Container>
   );
};

const Container = styled.div``;

const Tr = styled.tr`
   & > th {
      &:nth-of-type(1) {
         width: 10%;
      }
      &:nth-of-type(2) {
         width: 60%;
      }
      &:nth-of-type(3) {
         width: 6%;
      }
      &:nth-of-type(4) {
         width: 24%;
      }
   }
`;

export default Orders;
