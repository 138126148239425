import {
   faBoxOpen,
   faHammer,
   faPaintRoller,
   faPallet,
   faSprayCan,
   faPaperPlane,
   faHelmetSafety,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

const accounts = [
   {
      email: 'seebcioo+knskierlakiernia@gmail.com',
      name: 'KIEROWNIK LAKIERNI',
      icons: <FontAwesomeIcon icon={faPaintRoller} />,
   },
   {
      email: 'seebcioo+knslakiernia@gmail.com',
      name: 'LAKIERNIA',
      icons: (
         <>
            <FontAwesomeIcon icon={faPaintRoller} />
            <FontAwesomeIcon icon={faHammer} />
         </>
      ),
   },
   {
      email: 'seebcioo+knsnapylanie@gmail.com',
      name: 'NAPYLANIE',
      icons: (
         <>
            <FontAwesomeIcon icon={faPaintRoller} />
            <FontAwesomeIcon icon={faSprayCan} />
         </>
      ),
   },
   {
      email: 'seebcioo+knskiermagazynu@gmail.com',
      name: 'KIEROWNIK MAGAZYNU',
      icons: <FontAwesomeIcon icon={faBoxOpen} />,
   },
   {
      email: 'seebcioo+knsmagazynwozek@gmail.com',
      name: 'WÓZKOWY',
      icons: <FontAwesomeIcon icon={faPallet} />,
   },
   {
      email: 'seebcioo+knswarehousesending@gmail.com',
      name: 'SENDING',
      icons: <FontAwesomeIcon icon={faPaperPlane} />,
   },
   {
      email: 'seebcioo+knsproduction@gmail.com',
      name: 'PRODUCTION',
      icons: <FontAwesomeIcon icon={faHelmetSafety} />,
   },
   {
      email: 'seebcioo+knspacking@gmail.com',
      name: 'ROLE_PACKING',
      icons: <FontAwesomeIcon icon={faHelmetSafety} />,
   },
   {
      email: 'seebcioo+knspackingintowarehouse@gmail.com',
      name: 'ROLE_PACKING_INTO_WAREHOUSE',
      icons: <FontAwesomeIcon icon={faHelmetSafety} />,
   },
];

const Accounts = () => {
   const formik = useFormikContext();
   const rendered = accounts.map((a) => (
      <Button
         key={a.email}
         type="button"
         className="btn btn-light"
         onClick={() => {
            formik.setFieldValue('email', a.email);
            formik.setFieldValue('password', a.email);
            setTimeout(() => formik.submitForm());
         }}
      >
         <Icons>{a.icons}</Icons>
         {a.name}
      </Button>
   ));
   if (process.env.REACT_APP_API_URL !== 'http://localhost/api') return null;
   return <Container>{rendered}</Container>;
};

const Container = styled.div`
   display: flex;
   flex-direction: column;
`;

const Icons = styled.div`
   & > .svg-inline--fa {
      margin-right: 1rem;
   }
`;

const Button = styled.button`
   margin-bottom: 0.5rem;
   display: flex;
   justify-content: space-between;
`;

export default Accounts;
