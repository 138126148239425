// merge for example 1 x ABC and 1 x ABC to 2 x ABC, where ABC is a product id
const mergeProducts = (parts) =>
   parts.map((p) => {
      const products = p.products
         .map((pr, pri) => {
            let sameIndex = -1;
            const same = p.products.find((prf, prfi) => {
               if (!prf) return false;
               sameIndex = prfi;

               return prfi > pri && prf.id === pr.id;
            });

            if (same) {
               // ESLINT_WARN_TO_BE_FIXED
               // eslint-disable-next-line no-param-reassign
               delete p.products[sameIndex];

               return {
                  ...pr,
                  amount: pr.amount + same.amount,
               };
            }
            return pr;
         })
         // remove empty elements after deleting them
         .filter(() => true);

      return {
         ...p,
         products,
      };
   });

export default mergeProducts;
