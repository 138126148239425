import styled, { css } from 'styled-components';

import { DividerStyledProps } from './Divider.types';

export const Divider = styled.hr<DividerStyledProps>(
   ({ theme: { palette }, $color }) => css`
      margin: 0;
      padding: 0;
      border: 0;
      border-top: 1px solid ${palette.border.divider[$color]};
      opacity: 1;
   `,
);
