import * as S from './Typography.styled';
import { AsVariant, HeadingVariant, Props, TextVariant } from './Typography.types';

const HEADING_VARIANTS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const isHeading = (variant: HeadingVariant | TextVariant): variant is HeadingVariant => {
   return HEADING_VARIANTS.includes(variant);
};

const VARIANT_MAPPING: Record<HeadingVariant | TextVariant, AsVariant> = {
   h1: 'h1',
   h2: 'h2',
   h3: 'h3',
   h4: 'h4',
   h5: 'h5',
   h6: 'h6',
   body1: 'p',
   body2: 'p',
   body3: 'p',
   subtitle1: 'span',
   subtitle2: 'span',
};

export const Typography = ({
   variant = 'body2',
   gutterBottom = false,
   decoration = 'none',
   align = 'inherit',
   color,
   className,
   as,
   children,
}: Props): JSX.Element => {
   if (isHeading(variant)) {
      return (
         <S.Heading
            $align={align}
            $color={color}
            className={className}
            $decoration={decoration}
            $variant={variant}
            $gutterBottom={gutterBottom}
            as={as ?? VARIANT_MAPPING[variant]}
         >
            {children}
         </S.Heading>
      );
   }

   return (
      <S.Text
         $align={align}
         $color={color}
         $decoration={decoration}
         $variant={variant}
         className={className}
         $gutterBottom={gutterBottom}
         as={as ?? VARIANT_MAPPING[variant]}
      >
         {children}
      </S.Text>
   );
};
