import axios from 'axios';
import { useFormik } from 'formik';
import { useContext } from 'react';
import styled from 'styled-components';

import { Context } from '../Context';

const initialValues = {};

const cancel = (groupId) => axios.post(`/warehouse/order-part-groups/${groupId}/cancel`);

const CancelButton = ({ className, group }) => {
   const { refetch } = useContext(Context);
   const formik = useFormik({
      initialValues,
      onSubmit: () => {
         cancel(group.id).then(() => {
            refetch();
         });
      },
   });
   if (!group.canBeCancelled) return null;

   return (
      <Button
         type="button"
         className={`btn btn-danger ${className}`}
         onClick={formik.submitForm}
         disabled={formik.isSubmitting}
      >
         Anuluj plan
      </Button>
   );
};

const Button = styled.button`
   width: 100%;
`;
export default CancelButton;
