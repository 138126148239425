import { faEquals, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { AVERAGE_LOGS_PER_HOUR } from '../context';

const LogsCalculations = () => {
   const formik = useFormikContext();
   const hoursInShift = formik.values.minutesInShift ? formik.values.minutesInShift / 60 : '';
   return (
      <Container>
         <Item>{AVERAGE_LOGS_PER_HOUR}</Item>
         <MultiplyIcon icon={faTimes} />
         <Item>{hoursInShift} H</Item>
         <EqualsIcon icon={faEquals} />
      </Container>
   );
};

const Container = styled.div`
   line-height: 3rem;
`;

const Item = styled.span`
   font-size: 2.6rem;
   margin-right: 1rem;
   min-width: 82px;
   display: inline-block;
   text-align: right;
`;

const MultiplyIcon = styled(FontAwesomeIcon)`
   font-size: 1.6rem;
   margin-right: 1rem;
`;

const EqualsIcon = styled(FontAwesomeIcon)`
   font-size: 1.6rem;
   margin-right: 1rem;
`;

export default LogsCalculations;
