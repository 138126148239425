import axios from 'axios';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Response } from 'Shared/Response';

import { Context } from '../Context';

const initialValues = {};

const addOrderPartToOrderPartsGroup = (groupId, partId) =>
   axios.post(`/warehouse/order-part-groups/${groupId}/order-parts/${partId}/add-order-part`);

const createOptions = ({ groups, companyName }) =>
   groups
      .filter((g) => g.companyName === companyName)
      .map((g) => {
         const ids = g.orderParts.map(({ order }) => order.zamowieniaId);
         return (
            <option key={g.id} value={g.id}>
               {g.companyName} ({g.createdAt}) | {ids.join(', ')}
            </option>
         );
      });

const CreatedByInternalClientGroupAction = ({ order, orderPart, showGroups, setShowGroups }) => {
   const dispatch = useDispatch();
   const [ordersGroupId, setOrdersGroupId] = useState('');
   const groupsReadyToTakeExtraOrders = useSelector(
      (s) => s.warehouse.orderGroupsReadyToTakeExtraOrders,
   );
   const { refetch } = useContext(Context);
   const formik = useFormik({
      initialValues,
      onSubmit: () => {
         addOrderPartToOrderPartsGroup(ordersGroupId, orderPart.id).then(() => {
            refetch();
            dispatch({
               type: 'ADD_ORDER_PART_TO_ORDER_PARTS_GROUP',
               ordersGroupId,
            });
         });
      },
   });
   const options = createOptions({
      groups: groupsReadyToTakeExtraOrders,
      companyName: order.companyName,
   });
   const disabled = !options?.length;
   const addToGroupDisabled = formik.isSubmitting || !ordersGroupId;

   return (
      <Container>
         <BtnContainer>
            <button
               type="button"
               className="btn btn-success"
               onClick={() => setShowGroups((prevShowGroups) => !prevShowGroups)}
               disabled={disabled}
            >
               Dodaj do istniejącej grupy
            </button>
            {disabled && <small style={{ marginTop: '.5rem' }}>Brak grup dla tego klienta.</small>}
         </BtnContainer>
         {showGroups && (
            <div>
               <Label>
                  Grupa
                  <select
                     className="form-control"
                     onBlur={() => {
                        //
                     }}
                     onChange={(e) => setOrdersGroupId(e.target.value)}
                     value={ordersGroupId}
                  >
                     <option value="" />
                     {options}
                  </select>
               </Label>
            </div>
         )}
         {ordersGroupId && (
            <SaveContainer>
               <button
                  type="button"
                  className="btn btn-success"
                  onClick={formik.submitForm}
                  disabled={addToGroupDisabled}
               >
                  Dodaj
               </button>
            </SaveContainer>
         )}
         {orderPart?.error && <Response error={orderPart?.error} messages={[orderPart?.info]} />}
      </Container>
   );
};

const Container = styled.div`
   display: flex;
   flex-direction: column;

   & > div {
      margin-bottom: 1rem;
   }
`;

const Label = styled.label`
   width: 220px;
`;

const SaveContainer = styled.div`
   display: flex;
   justify-content: flex-end;
`;

const BtnContainer = styled.div`
   display: flex;
   flex-direction: column;
`;

export default CreatedByInternalClientGroupAction;
