import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { filtersChanged, wareHouseFiltersInitialState } from 'state/warehouse';

const OrderZamowieniaId = ({ className }) => {
   const dispatch = useDispatch();
   const filters = useSelector((state) => state.warehouse.filters);
   const formik = useFormik({
      initialValues: {
         zamowieniaId: filters.zamowieniaId || '',
      },
   });
   useEffect(() => {
      formik.setFieldValue('zamowieniaId', filters.zamowieniaId);
   }, [filters.zamowieniaId]);
   useEffect(() => {
      if (!formik.values.zamowieniaId) return;
      dispatch(
         filtersChanged({
            ...wareHouseFiltersInitialState,
            zamowieniaId: parseInt(formik.values.zamowieniaId, 10) || '',
         }),
      );
   }, [formik.values.zamowieniaId]);

   return (
      <div className={className}>
         <Label>
            Numer zamówienia
            <input
               type="text"
               className="form-control"
               name="zamowieniaId"
               placeholder="Numer zamówienia"
               value={formik.values.zamowieniaId}
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
            />
         </Label>
      </div>
   );
};

const Label = styled.label`
   width: 100%;
`;

export default OrderZamowieniaId;
