import { useState } from 'react';
import styled from 'styled-components';

import UseQueryStates from 'Shared/UseQueryStates';
import ShowHideButton from 'Shared/Warehouse/ShowHideButton';

import GroupWithoutFinalPalettes from './GroupWithoutFinalPalettes';

const WarehouseGroups = ({ groups, error, dataUpdatedAt, isLoading }) => {
   const [render, setRender] = useState(true);
   const rendered =
      render &&
      groups.map((g) => {
         return <GroupWithoutFinalPalettes key={g.id} group={g} />;
      });

   return (
      <Container>
         <div>
            <h2 className="h3">
               Grupy do spakowania na strefie | Spakowane na strefie
               <ShowHideButton render={render} onClick={() => setRender((p) => !p)} />
            </h2>
         </div>
         <table className="table table-hover">
            <thead>
               <tr>
                  <th>Grupa</th>
                  <th>Klient</th>
                  <th>Zamówienia / części zamówień</th>
                  <th />
               </tr>
            </thead>
            <tbody>{rendered}</tbody>
         </table>
         <UseQueryStates error={error} updatedAt={dataUpdatedAt} isLoading={isLoading} />
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
   & > div {
      margin-bottom: 0.5rem;
   }
`;

export default WarehouseGroups;
