type HexColor = string;

type GlobalColor = {
   0: HexColor;
   100: HexColor;
   200: HexColor;
   300: HexColor;
   400: HexColor;
   500: HexColor;
   600: HexColor;
   700: HexColor;
   800: HexColor;
   900: HexColor;
};

type GlobalColors = {
   red: GlobalColor;
   blue: GlobalColor;
   green: GlobalColor;
   yellow: GlobalColor;
   gray: GlobalColor;
   black: HexColor;
   white: HexColor;
};

export const GLOBAL_COLORS: GlobalColors = {
   red: {
      0: '#fce8e8',
      100: '#fcd1d1',
      200: '#fdb5b7',
      300: '#ff8d8f',
      400: '#ff5359',
      500: '#e5001a',
      600: '#b90013',
      700: '#95000d',
      800: '#760008',
      900: '#5c0005',
   },
   blue: {
      0: '#e6f4fc',
      100: '#b5e3fe',
      200: '#7ecefe',
      300: '#49b2ff',
      400: '#008df0',
      500: '#0069d0',
      600: '#004ba5',
      700: '#003b88',
      800: '#002d67',
      900: '#001f57',
   },
   green: {
      0: '#e4fceb',
      100: '#c6f7d6',
      200: '#9eefbb',
      300: '#78e0a0',
      400: '#4bcd84',
      500: '#2fb06b',
      600: '#27975b',
      700: '#1f7e4b',
      800: '#16623a',
      900: '#0e4729',
   },
   yellow: {
      0: '#fff9ef',
      100: '#fef2dd',
      200: '#fde9c3',
      300: '#fddd99',
      400: '#fdd365',
      500: '#f0c334',
      600: '#cfa72c',
      700: '#af8e23',
      800: '#91741b',
      900: '#735c14',
   },
   gray: {
      0: '#eef0f2',
      100: '#d8dce0',
      200: '#bdc7d0',
      300: '#9aabb9',
      400: '#8092a0',
      500: '#697b89',
      600: '#546674',
      700: '#445460',
      800: '#34424d',
      900: '#212c34',
   },
   black: '#12191f',
   white: '#ffffff',
};
