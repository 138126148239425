import { useContext } from 'react';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import Products from 'Shared/Warehouse/Products';
import { CompanyName } from 'Shared/Warehouse/Warehouse';

import { Context } from '../Context';

const Group = ({ group }) => {
   const { setGroupForFinalPaletteModal } = useContext(Context);
   const deliveringToPackingPlace = group.palettes.some((p) => {
      return p.orderParts.some((op) => {
         return op.warehouseStatus === 'PACKED_DELIVERING_TO_PACKING_PLACE';
      });
   });
   const palettes = group.palettes.map((p) => {
      const products = p.orderParts.map((op) => {
         return <Products key={op.id} products={op.products} />;
      });
      const ordersPublicIds = p.orderParts.map((op) => {
         return (
            <li key={op.id}>
               <ZamowieniaLink
                  displayId={op.order.zamowieniaId}
                  zamowieniaId={op.order.zamowieniaId}
               />
            </li>
         );
      });
      return (
         <tr key={p.publicId}>
            <td>{p.publicId}</td>
            <td>
               <ul className="list-unstyled">{ordersPublicIds}</ul>
            </td>
            <td>{products}</td>
         </tr>
      );
   });

   return (
      <tr>
         <td>{group.publicId}</td>
         <td>
            <CompanyName
               orderPartsGroup={group}
               createdByExternalClient={
                  group.palettes[0].orderParts[0].order.createdByExternalClient
               }
            />
         </td>
         <td>
            <table className="table table-hover table-sm">
               <thead>
                  <tr>
                     <th>Paleta</th>
                     <th>Zamówienia / Części zamówień</th>
                     <th>Produkty</th>
                  </tr>
               </thead>
               <tbody>{palettes}</tbody>
            </table>
         </td>
         <td>
            {deliveringToPackingPlace && <span>Czeka na dostarczenie ze strefy</span>}
            {!deliveringToPackingPlace && (
               <button
                  className="btn btn-success"
                  type="button"
                  onClick={() => setGroupForFinalPaletteModal(group)}
               >
                  Spakuj
               </button>
            )}
         </td>
      </tr>
   );
};

export default Group;
