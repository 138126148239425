import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { defaultsOneTime } from 'Shared/useQuery';
import { fetchAllByRole } from 'Shared/user';
import Error from 'ui/Error';

const Supervisor = () => {
   const formik = useFormikContext();
   const role = 'ROLE_PAINT_SHOP_SUPERVISOR';
   const [options, setOptions] = useState([]);
   const { isLoading, error } = useQuery(['fetchAll', { role }], () => fetchAllByRole(role), {
      ...defaultsOneTime,
      onSuccess: ({ data }) => {
         const o = data.users.map((u) => (
            <option key={u.id} value={u.id}>
               {u.email}
            </option>
         ));
         setOptions(o);
      },
   });
   if (isLoading) return null;
   if (!isLoading && error) {
      return (
         <Error>{error.response?.data?.message ?? 'Wystąpił błąd. Spróbuj jeszcze raz.'}</Error>
      );
   }
   return (
      <Container>
         <Label>
            <Icon icon={faUserShield} />
            Kierownik zmiany
            <select
               className="form-control"
               name="supervisor"
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
               value={formik.values.supervisor}
               disabled={formik.values.status === 'LOCKED'}
            >
               <option value="">Wybierz</option>
               {options}
            </select>
            <Error>
               {formik.touched?.supervisor && formik.errors?.supervisor && formik.errors.supervisor}
            </Error>
         </Label>
      </Container>
   );
};

const Container = styled.div`
   display: flex;
   margin-top: 1rem;
`;

const Icon = styled(FontAwesomeIcon)`
   margin-right: 0.5rem;
`;

const Label = styled.label`
   width: 100%;
`;

export default Supervisor;
