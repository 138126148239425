import { faDivide } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { openSplitOrderModal } from 'state/warehouse';

const SplitOrderButton = ({ order }) => {
   const dispatch = useDispatch();
   const click = () => {
      dispatch(openSplitOrderModal(order.id));
   };

   return (
      <div>
         <Btn
            type="button"
            className="btn btn-link btn-icon-left"
            onClick={click}
            disabled={!order.canBeSplit}
         >
            <FontAwesomeIcon icon={faDivide} />
            Rozdziel
         </Btn>
         {!order.canBeSplit && (
            <WhyCannotSplitP>
               Nie możesz rozdzielić ponieważ inna część zamówienia jest w grupie, która jest
               oznaczona do spakowania na gotowo (lub jest na dalszym etapie z punktu widzenia
               logistycznego).
            </WhyCannotSplitP>
         )}
      </div>
   );
};

const Btn = styled.button`
   width: 100%;
`;

const WhyCannotSplitP = styled.p`
   text-align: justify;
   font-size: 10px;
   color: rgb(108, 117, 125);
`;

export default SplitOrderButton;
