import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { loadUser, rolesTranslation } from 'auth';

import Nav from './Nav';

const UserInfo = ({ user }) => {
   const { t } = useTranslation();
   if (!user) return null;

   return (
      <UserContainer>
         <span>{t('common:title.user', { name: user.username })}</span>
         {rolesTranslation[user.roles[0]] && <span style={{ margin: '0 0.5rem' }}>|</span>}
         {rolesTranslation[user.roles[0]] && <span>{rolesTranslation[user.roles[0]]}</span>}
      </UserContainer>
   );
};

const Header = () => {
   const user = loadUser();
   return (
      <Container className="sticky-top">
         <div className="container-xxl">
            <div className="row">
               <Col className="col">
                  <section>
                     <Link to="/">
                        <LogoImg
                           src="https://kns-assets-common.s3-eu-west-1.amazonaws.com/img/logo-white.png"
                           alt="logo"
                        />
                     </Link>
                     <UserInfo user={user} />
                  </section>
                  <section>
                     <Nav />
                  </section>
               </Col>
            </div>
         </div>
      </Container>
   );
};

const Container = styled.header`
   background: #2d3234;
`;

const Col = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 2rem;
   padding-top: 1rem;

   & > section {
      width: 50%;
   }
`;

const LogoImg = styled.img`
   width: 200px;
   height: auto;
`;

const UserContainer = styled.div`
   margin-top: 0.5rem;
   color: #fff;
`;

export default Header;
