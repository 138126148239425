import axios from 'axios';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import defaults from 'Shared/useQuery';
import UseQueryStates from 'Shared/UseQueryStates';
import { STATUS_TO_BE_SEND } from 'Shared/Warehouse/OrderPartsGroup';
import { CompanyName } from 'Shared/Warehouse/Warehouse';

import FinalPalette from '../FinalPalette';

const fetchPalettes = ({ groupPackedAt, companyName }) =>
   axios.get('/warehouse/groups/to-be-sent', {
      params: {
         group_packed_at_from: groupPackedAt.from,
         group_packed_at_to: groupPackedAt.to,
         company_name: companyName,
      },
   });

const ToBeSent = ({ groupPackedAt }) => {
   const dispatch = useDispatch();
   const filters = useSelector((s) => s.warehouse.filters);
   const groups = useSelector((s) => s.warehouse.groupsToBeSend);
   const enabled = filters.orderPartsGroupStatus === STATUS_TO_BE_SEND && !!groupPackedAt.from;
   const { error, dataUpdatedAt, isLoading, refetch } = useQuery(
      [
         'fetchGroupsToBeSend',
         {
            filters: {
               ...filters,
               groupPackedAt,
            },
         },
      ],
      () => fetchPalettes({ ...filters, groupPackedAt }),
      {
         ...defaults,
         enabled,
         onSuccess: ({ data }) => {
            dispatch({
               type: 'FETCH_GROUPS_TO_BE_SEND',
               groups: data.groups,
               clients: data.clients,
            });
         },
      },
   );
   useEffect(() => {
      if (enabled) refetch();
   }, [enabled, groupPackedAt.from]);
   if (filters.orderPartsGroupStatus !== STATUS_TO_BE_SEND) return null;
   const rendered = groups?.map((g) => {
      const palettes = g.finalPalettes.map((p) => <FinalPalette key={p.id} finalPalette={p} />);
      const { createdByExternalClient } = g.finalPalettes[0].orderParts[0].order;

      return (
         <tr key={g.id}>
            <td>{g.publicId}</td>
            <td>
               <CompanyName orderPartsGroup={g} createdByExternalClient={createdByExternalClient} />
            </td>
            <td>{g.packedAt}</td>
            <td>{palettes}</td>
         </tr>
      );
   });

   return (
      <>
         <table className="table table-hover">
            <thead>
               <tr>
                  <th>Grupa</th>
                  <th>Klient</th>
                  <th>Kiedy</th>
                  <th>Palety</th>
               </tr>
            </thead>
            <tbody>{rendered}</tbody>
         </table>
         <UseQueryStates error={error} dataUpdatedAt={dataUpdatedAt} isLoading={isLoading} />
      </>
   );
};

export default ToBeSent;
