const minute = 60000;

const defaults = {
   refetchInterval: minute,
   refetchIntervalInBackground: true,
   retry: 1,
   refetchOnWindowFocus: false,
};

export const defaultsOneTime = {
   retry: 1,
   refetchOnWindowFocus: false,
   refetchIntervalInBackground: false,
};

export default defaults;
