import styled, { css } from 'styled-components';

import { ReactComponent as QuestionMarkIcon } from 'common/assets/vectors/20_question_mark_icon.svg';

import { CoverProps, WrapperProps } from './ColorCard.types';

export const Cover = styled.div<CoverProps>(
   ({ theme: { borderRadius, palette }, $url, $square }) => css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: ${borderRadius(4)};
      background-color: ${palette.background.colorCard.defaultCover};
      background-image: ${`url(${$url})`};
      background-size: cover;
      background-position: center;
      margin: -1px;

      ${$square
         ? css`
              padding-bottom: 100%;
           `
         : css`
              padding-bottom: 45%;
           `}
   `,
);

export const Wrapper = styled.div<WrapperProps>(
   ({ theme: { borderRadius, palette }, $isDisabled, $isActive }) => css`
      border: 1px solid ${palette.border.colorCard.defaultCard};
      border-radius: ${borderRadius(4)};
      transition: box-shadow 0.25s;
      cursor: ${$isActive || $isDisabled ? 'default' : 'pointer'};
      background-color: ${palette.background.colorCard.defaultCard};
      width: 100%;

      &:hover {
         box-shadow: 0px 5px 15px rgba(78, 97, 112, 0.25);
      }

      ${$isActive &&
      css`
         &,
         &:hover {
            cursor: default;
            box-shadow: 0px 3px 15px #e5001980;
            background-color: ${palette.background.colorCard.activeCard};
            border-color: ${palette.border.colorCard.activeCard};
         }
      `}

      ${$isDisabled &&
      css`
         &:hover {
            box-shadow: none;
         }
      `}
   `,
);

export const CoverLabel = styled.div(
   () => css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   `,
);

export const Label = styled.div(
   ({ theme: { spacing } }) => css`
      padding: ${spacing(3, 4)};
   `,
);

export const ExtraChargeInfo = styled.span(
   ({ theme: { spacing, borderRadius, palette } }) => css`
      position: absolute;
      top: 100%;
      right: ${spacing(4)};
      background: ${palette.background.colorCard.tooltip};
      padding: ${spacing(2, 4)};
      border-radius: ${borderRadius(4, 0, 4, 4)};
      display: none;
      box-shadow: 0px 5px 15px rgba(78, 97, 112, 0.25);
   `,
);

export const ExtraCharge = styled.div(
   ({ theme: { spacing } }) => css`
      position: absolute;
      top: 0;
      right: 0;
      padding: ${spacing(2)};

      &:hover {
         ${ExtraChargeInfo} {
            display: block;
         }
      }
   `,
);

export const StyledQuestionMarkIcon = styled(QuestionMarkIcon)(
   () => css`
      display: block;
   `,
);

export const ExtraChargeIcon = styled.span(
   ({ theme: { palette } }) => css`
      display: block;
      background: ${palette.background.iconButton.neutral.default};
      border-radius: 50%;
   `,
);
