import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Order from './Order';

const Orders = ({ refetch }) => {
   const orders = useSelector((s) => s.warehouse.orders);
   const rendered = orders.map((o) => <Order key={o.id} order={o} refetch={refetch} />);

   return (
      <Container>
         <div>
            <table className="table">
               <thead>
                  <Tr>
                     <th>Id / klient</th>
                     <th>Lista produktów</th>
                  </Tr>
               </thead>
               <tbody>{rendered}</tbody>
            </table>
         </div>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
   position: relative;
`;

const Tr = styled.tr`
   & > th {
      &:nth-of-type(1) {
         width: 16%;
      }
      &:nth-of-type(2) {
         width: 84%;
      }
   }
`;

export default Orders;
