import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import Products from 'Shared/Warehouse/Products';

const Order = ({ order }) => {
   return (
      <tr>
         <td>
            <ZamowieniaLink displayId={order.zamowieniaId} zamowieniaId={order.zamowieniaId} />
            <br />
            <span>{order.companyName}</span>
         </td>
         <td>
            <Products products={order.products} />
         </td>
      </tr>
   );
};

export default Order;
