import dayjs from 'dayjs';
import styled from 'styled-components';

import Products from 'Shared/Warehouse/Products';
import { Countdown } from 'Shared/Warehouse/Warehouse';

const OrderParts = ({ orderParts }) => {
   const rendered = orderParts.map((op) => (
      <Row key={op.id}>
         <Info>
            <span>{op.order.zamowieniaId}</span>
            <Countdown date={dayjs(op.order.checkedInAt)} />
         </Info>
         <ProductsCol>
            <Products products={op.products} />
         </ProductsCol>
      </Row>
   ));

   return (
      <table className="table">
         <tbody>{rendered}</tbody>
      </table>
   );
};

const Row = styled.tr`
   font-size: 1.6rem;
`;

const Info = styled.td`
   width: 25%;
`;

const ProductsCol = styled.td`
   width: 75%;
`;

export default OrderParts;
