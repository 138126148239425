import styled, { css, FlattenSimpleInterpolation } from 'styled-components/macro';

import { Theme } from 'designSystem/theme/theme';

import {
   ButtonBaseProps,
   ButtonColor,
   ButtonSize,
   ButtonVariant,
   ContentProps,
} from './Button.types';

const getColors = (
   isLoading: boolean,
   variant: ButtonVariant,
   color: ButtonColor,
   palette: Theme['palette'],
): FlattenSimpleInterpolation => {
   const border = palette.border.button[variant][color];
   const background = palette.background.button[variant][color];
   const text = palette.text.button[variant][color];

   return css`
      border-color: ${border.default};
      background: ${background.default};
      color: ${text.default};

      &:hover {
         border-color: ${border.hover};
         background: ${background.hover};
         color: ${text.hover};
      }

      &:focus {
         border-color: ${border.focus};
         background: ${background.focus};
         color: ${text.focus};
      }

      &:active {
         border-color: ${border.active};
         background: ${background.active};
         color: ${text.active};
      }

      &:disabled,
      &:disabled:hover {
         border-color: ${border.disabled};
         background: ${background.disabled};
         color: ${text.disabled};
      }

      ${isLoading &&
      css`
         &,
         &:hover,
         &:disabled,
         &:disabled:hover {
            border-color: ${border.loading};
            background: ${background.loading};
            color: ${text.loading};
            cursor: default;
         }
      `}
   `;
};

const getSize = (size: ButtonSize, spacing: Theme['spacing']): FlattenSimpleInterpolation => {
   switch (size) {
      case 'large':
         return css`
            padding: ${spacing(4, 10)};
            line-height: 22px;
            font-size: 18px;
         `;
      case 'medium':
      default:
         return css`
            padding: ${spacing(3, 6)};
            line-height: 22px;
            font-size: 16px;
         `;
   }
};

export const ButtonBase = styled.button<ButtonBaseProps>(
   ({ theme: { spacing, palette, borderRadius }, size, color, fullWidth, variant, isLoading }) => {
      return css`
         position: relative;
         display: flex;
         align-items: center;
         justify-content: center;
         margin: ${spacing(0)};
         border: 1px solid transparent;
         border-radius: ${borderRadius(4)};
         font-weight: 600;
         transition: background 250ms, color 250ms, border-color 250ms;
         cursor: pointer;

         &,
         &:hover {
            text-decoration: none;
         }

         &:disabled,
         &:disabled:hover {
            cursor: default;
         }

         ${fullWidth &&
         css`
            width: 100%;
         `}

         ${getSize(size, spacing)};
         ${getColors(isLoading, variant, color, palette)};
      `;
   },
);

export const Content = styled.span<ContentProps>(({ isLoading }) => {
   const loadingStyles =
      isLoading &&
      css`
         color: transparent;
      `;

   return css`
      display: flex;
      align-items: center;

      &,
      &:hover {
         text-decoration: none;
      }

      ${loadingStyles};
   `;
});

export const StartIcon = styled.span`
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: ${({ theme: { spacing } }) => spacing(4)};
   max-height: 24px;
   fill: currentColor;
`;

export const EndIcon = styled(StartIcon)`
   margin-left: ${({ theme: { spacing } }) => spacing(4)};
`;
