import { faTape, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
   packingPlacesForklift,
   PACKING_PLACE_DIRECT,
   PACKING_PLACE_WAREHOUSE,
} from 'Shared/Warehouse/OrderPartsGroup';

const PackingPlace = () => {
   const dispatch = useDispatch();
   const packingPlace = useSelector((s) => s.warehouse.filters.packingPlace);
   const filter = (value) => {
      dispatch({ type: 'CHANGE_FILTER', key: 'packingPlace', value });
   };
   return (
      <Container>
         <Header>Miejsce dostarczenia</Header>
         <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label className={`btn btn-light ${packingPlace === '' ? 'active' : ''}`}>
               <input type="radio" autoComplete="off" onClick={() => filter('')} />
               wszędzie
            </label>
            <Direct
               className={`btn btn-light ${packingPlace === PACKING_PLACE_DIRECT ? 'active' : ''}`}
            >
               <input
                  type="radio"
                  autoComplete="off"
                  onClick={() => filter(PACKING_PLACE_DIRECT)}
               />
               <Icon icon={faTape} />
               {packingPlacesForklift[PACKING_PLACE_DIRECT]}
            </Direct>
            <Warehouse
               className={`btn btn-light } ${
                  packingPlace === PACKING_PLACE_WAREHOUSE ? 'active' : ''
               }`}
            >
               <input
                  type="radio"
                  autoComplete="off"
                  onClick={() => filter(PACKING_PLACE_WAREHOUSE)}
               />
               <Icon icon={faWarehouse} />
               {packingPlacesForklift[PACKING_PLACE_WAREHOUSE]}
            </Warehouse>
         </div>
      </Container>
   );
};

const Container = styled.div``;

const Header = styled.span`
   display: block;
`;

const Direct = styled.label`
   color: #ffc107;
`;

const Warehouse = styled.label`
   color: #343a40;
`;

const Icon = styled(FontAwesomeIcon)`
   margin-right: 0.5rem;
`;

export default PackingPlace;
