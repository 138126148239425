import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { filtersChanged, wareHouseFiltersInitialState } from 'state/warehouse';

const Container = styled.div`
   padding-bottom: 0.4rem;
`;

const ResetBtn = styled.button`
   padding: 0.2rem 0.75rem;
   .svg-inline--fa {
      width: 2rem;
      height: 2rem;
      margin-left: 0.6rem;
      vertical-align: middle;
   }
`;

const PaintShopResetFiltersButton = () => {
   const dispatch = useDispatch();

   const reset = () =>
      dispatch(
         filtersChanged({
            ...wareHouseFiltersInitialState,
            importedAt: new Date(),
         }),
      );

   return (
      <Container>
         <ResetBtn type="button" onClick={reset} className="btn btn-light">
            Resetuj
            <FontAwesomeIcon icon={faTimes} />
         </ResetBtn>
      </Container>
   );
};

export default PaintShopResetFiltersButton;
