import dayjs from 'dayjs';
import styled from 'styled-components';

const UseQueryStates = ({ error, updatedAt, isLoading }) => {
   return (
      <>
         {!!updatedAt && (
            <small>(ostatnia aktualizacja: {dayjs(updatedAt).format('HH:mm:ss')})</small>
         )}
         {!!isLoading && <Loading>Pobieranie danych...</Loading>}
         {!!error && <Error>Wystąpił błąd</Error>}
      </>
   );
};

const Loading = styled.p``;

const Error = styled.p`
   font-size: 2rem;
   color: #ff0000;
`;

export default UseQueryStates;
