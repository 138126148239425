import axios from 'axios';
import { useFormik } from 'formik';
import { useContext } from 'react';

import { STATUS_PACKED } from 'Shared/Warehouse/OrderPartsGroup';

import { Context } from './Context';

const initialValues = {};

const ToBeSentButton = ({ group }) => {
   const { refetch } = useContext(Context);
   const formik = useFormik({
      initialValues,
      onSubmit: async () => {
         await axios.post(`/warehouse/order-part-groups/${group.id}/to-be-send`);
         refetch();
      },
   });

   if (group.status !== STATUS_PACKED) return null;
   return (
      <button
         className="btn btn-success"
         onClick={formik.submitForm}
         type="button"
         disabled={formik.isSubmitting}
      >
         Zakończone / Do wysłania
      </button>
   );
};

export default ToBeSentButton;
