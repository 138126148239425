import { faTape, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useFormik } from 'formik';
import { useContext } from 'react';
import Countdown from 'react-countdown';
import styled from 'styled-components';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import {
   packingPlacesForklift,
   PACKING_PLACE_DIRECT,
   PACKING_PLACE_WAREHOUSE,
} from 'Shared/Warehouse/OrderPartsGroup';
import Products from 'Shared/Warehouse/Products';

import Context from '../context';

const renderer = ({ hours, minutes }) => `${hours} godzin ${minutes} minut`;

const initialValues = {};

const delivered = (groupId, partId) =>
   axios.post(
      `/warehouse/order-part-groups/${groupId}/order-parts/${partId}/delivered-to-packing-place`,
   );

const OrderPart = ({ orderPart, group }) => {
   const { refetch } = useContext(Context);
   const formik = useFormik({
      initialValues,
      onSubmit: () => {
         delivered(group.id, orderPart.id).then(() => {
            refetch();
         });
      },
   });
   const icon = group.packingPlace === PACKING_PLACE_DIRECT ? faTape : faWarehouse;

   return (
      <tr>
         <td>{group.publicId}</td>
         <td>
            <ZamowieniaLink
               zamowieniaId={orderPart.order.zamowieniaId}
               displayId={orderPart.order.zamowieniaId}
            />
         </td>
         <td>{group.companyName}</td>
         <td>
            <Countdown
               date={orderPart.startedDeliveringToPackingPlaceAt}
               renderer={renderer}
               overtime
            />
         </td>
         <td>
            <Icon icon={icon} warehouse={group.packingPlace === PACKING_PLACE_WAREHOUSE} />
            <PackingPlace warehouse={group.packingPlace === PACKING_PLACE_WAREHOUSE}>
               {packingPlacesForklift[group.packingPlace]}
            </PackingPlace>
         </td>
         <td>
            <Products products={orderPart.products} />
         </td>
         <td>
            <button
               type="button"
               className="btn btn-success"
               onClick={formik.submitForm}
               disabled={formik.isSubmitting}
            >
               Dostarczono
            </button>
         </td>
      </tr>
   );
};

const Icon = styled(FontAwesomeIcon)`
   margin-right: 0.5rem;
   color: ${(props) => (props.warehouse ? '#343a40' : '#ffc107')};
`;

const PackingPlace = styled.span`
   color: ${(props) => (props.warehouse ? '#343a40' : '#ffc107')};
`;

export default OrderPart;
