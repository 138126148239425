import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { findDay, timeReadable } from '../context';

const ShiftTimeAfterIssues = ({ index }) => {
   const days = useSelector((s) => s.paintShopCalendar.days);
   const currentDay = useSelector((s) => s.paintShopCalendar.currentDay);
   const day = findDay(days, dayjs(currentDay).format('DD-MM-YYYY'));
   const shift = day?.shifts?.[index];
   if (!shift || !shift.issuesCostsInMinutes) return null;
   const diff = shift.minutesInShift - shift.issuesCostsInMinutes;
   return (
      <Container>
         Czas trwania zmiany po awariach:
         <br />
         {timeReadable(diff)}
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
   font-size: 2rem;
`;

export default ShiftTimeAfterIssues;
