import axios from 'axios';
import { useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import InternalContainer from 'Layouts/InternalContainer';
import LastUpdatedAt from 'Pages/Dashboards/Warehouse/LastUpdatedAt';
import defaults from 'Shared/useQuery';
import FinalPaletteModal from 'Shared/Warehouse/FinalPaletteModal/FinalPaletteModal';
import SearchGroupByZamowieniaId from 'Shared/Warehouse/SearchGroupByZamowieniaId/SearchGroupByZamowieniaId';

import { Context } from './Context';
import Group from './Group/Group';

const WarehousePackingIntoWarehouse = () => {
   const [groups, setGroups] = useState([]);
   const [groupForFinalPaletteModal, setGroupForFinalPaletteModal] = useState(null);
   const { refetch, dataUpdatedAt } = useQuery(
      ['fetchGroupsForPacking'],
      () => {
         return axios.get('/warehouse/groups-for-packing-into-warehouse');
      },
      {
         ...defaults,
         onSuccess: ({ data }) => {
            setGroups(data.groups);
         },
      },
   );
   const rendered = groups.map((g) => <Group key={g.id} group={g} />);
   const value = useMemo(
      () => ({ refetch, setGroupForFinalPaletteModal }),
      [refetch, setGroupForFinalPaletteModal],
   );

   return (
      <Context.Provider value={value}>
         <Helmet>
            <title>Magazyn &gt; Pakowanie na magazyn | Konsport</title>
         </Helmet>
         <InternalContainer>
            <div className="row">
               <div className="col">
                  <SearchGroupByZamowieniaId />
                  <HeaderContainer>
                     <h1>Magazyn | Pakowanie na magazyn</h1>
                  </HeaderContainer>
                  <table className="table table-hover">
                     <thead>
                        <tr>
                           <th>Grupa</th>
                           <th>Klient</th>
                           <th>Palety</th>
                           <th />
                        </tr>
                     </thead>
                     <tbody>{rendered}</tbody>
                  </table>
                  <LastUpdatedAt updatedAt={dataUpdatedAt} />
               </div>
            </div>
         </InternalContainer>
         {groupForFinalPaletteModal && (
            <FinalPaletteModal
               group={groupForFinalPaletteModal}
               close={() => setGroupForFinalPaletteModal(null)}
               refetch={refetch}
            />
         )}
      </Context.Provider>
   );
};

const HeaderContainer = styled.div`
   margin-bottom: 2rem;
`;

export default WarehousePackingIntoWarehouse;
