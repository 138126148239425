import styled from 'styled-components';

import Order from 'Pages/PaintShopStatistics/Order';

const Day = ({ day }) => {
   const orders = day.orders.map((o) => {
      return (
         <Order
            key={o.id}
            log={o.log}
            products={o.products}
            weight={o.weight}
            zamowieniaId={o.zamowieniaId}
            restOfProducts={o.restOfProducts}
         />
      );
   });
   return (
      <tr>
         <DayCol>{day.day}</DayCol>
         <td>{day.weight}</td>
         <td>{day.log}</td>
         <td>
            <table className="table table-sm">
               <thead>
                  <tr>
                     <th>Id</th>
                     <th>Punktowo</th>
                     <th>Belkowo</th>
                     <th>Produkty</th>
                  </tr>
               </thead>
               <tbody>{orders}</tbody>
            </table>
         </td>
      </tr>
   );
};

const DayCol = styled.td`
   min-width: 120px;
`;

export default Day;
