import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const ResetFiltersButton = ({ filters = null, className }) => {
   const dispatch = useDispatch();

   return (
      <div className={className}>
         <Button
            type="button"
            onClick={() => dispatch({ type: 'RESET_FILTERS', filters })}
            className="btn btn-light"
         >
            Resetuj
            <FontAwesomeIcon icon={faTimes} />
         </Button>
      </div>
   );
};

const Button = styled.button`
   width: 100%;
   padding: 0.2rem 0.75rem;
   margin-bottom: 0.4rem;
   .svg-inline--fa {
      width: 2rem;
      height: 2rem;
      margin-left: 0.6rem;
      vertical-align: middle;
   }
`;

export default ResetFiltersButton;
