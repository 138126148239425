import { faHandPointRight } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

const LastIssues = () => {
   const formik = useFormikContext();

   return (
      <div>
         {!!formik.errors.packedByFilledInManually && (
            <span>Uzupełnij pole &quot;Spakowane przez&quot; by spakować</span>
         )}
         {!Object.keys(formik.errors).length && (
            <Ready>
               Gotowe! Spakuj na gotow za pomocą przycisku po prawej
               <FontAwesomeIcon icon={faHandPointRight} />
            </Ready>
         )}
      </div>
   );
};

const Ready = styled.span`
   .svg-inline--fa {
      margin-left: 0.5rem;
   }
`;

export default LastIssues;
