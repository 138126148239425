import styled from 'styled-components';

const Container = styled.div`
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background: rgba(255, 255, 255, 0.85);
   display: flex;
   align-items: center;
   justify-content: center;
`;

export const SIZE_SMALL = 'SIZE_SMALL';
export const SIZE_MEDIUM = 'SIZE_MEDIUM';

const getWidth = (size) => {
   switch (size) {
      case SIZE_SMALL:
         return 50;
      case SIZE_MEDIUM:
         return 100;
      default:
         return 150;
   }
};

export const Loader = ({ size }) => {
   const width = getWidth(size);

   return (
      <Container>
         <img src="/gif/konsport_preloader.gif" alt="loader" width={width} />
      </Container>
   );
};
