import { GLOBAL_COLORS } from './globalColors';
import { Palette } from './types/types';

export const createLightPalette = (): Palette => {
   return {
      background: {
         alert: {
            error: GLOBAL_COLORS.red[0],
            success: GLOBAL_COLORS.green[0],
            info: GLOBAL_COLORS.blue[0],
            warning: GLOBAL_COLORS.yellow[0],
         },
         colorCard: {
            defaultCard: GLOBAL_COLORS.white,
            defaultCover: GLOBAL_COLORS.gray[400],
            activeCard: GLOBAL_COLORS.red[500],
            tooltip: GLOBAL_COLORS.white,
         },
         button: {
            contained: {
               primary: {
                  default: GLOBAL_COLORS.red[500],
                  hover: GLOBAL_COLORS.red[600],
                  focus: GLOBAL_COLORS.red[600],
                  active: GLOBAL_COLORS.red[700],
                  loading: GLOBAL_COLORS.red[500],
                  disabled: GLOBAL_COLORS.gray[100],
               },
               secondary: {
                  default: GLOBAL_COLORS.blue[500],
                  hover: GLOBAL_COLORS.blue[600],
                  focus: GLOBAL_COLORS.blue[600],
                  active: GLOBAL_COLORS.blue[700],
                  loading: GLOBAL_COLORS.blue[500],
                  disabled: GLOBAL_COLORS.gray[100],
               },
               neutral: {
                  default: GLOBAL_COLORS.gray[700],
                  hover: GLOBAL_COLORS.gray[800],
                  focus: GLOBAL_COLORS.gray[800],
                  active: GLOBAL_COLORS.gray[900],
                  loading: GLOBAL_COLORS.gray[700],
                  disabled: GLOBAL_COLORS.gray[100],
               },
               neutralLight: {
                  default: GLOBAL_COLORS.gray[500],
                  hover: GLOBAL_COLORS.gray[600],
                  focus: GLOBAL_COLORS.gray[600],
                  active: GLOBAL_COLORS.gray[700],
                  loading: GLOBAL_COLORS.gray[500],
                  disabled: GLOBAL_COLORS.gray[100],
               },
               light: {
                  default: GLOBAL_COLORS.gray[0],
                  hover: GLOBAL_COLORS.gray[100],
                  focus: GLOBAL_COLORS.gray[100],
                  active: GLOBAL_COLORS.gray[200],
                  loading: GLOBAL_COLORS.gray[0],
                  disabled: GLOBAL_COLORS.gray[100],
               },
            },
            outlined: {
               primary: {
                  default: 'transparent',
                  hover: GLOBAL_COLORS.red[500],
                  focus: GLOBAL_COLORS.red[500],
                  active: GLOBAL_COLORS.red[600],
                  loading: 'transparent',
                  disabled: GLOBAL_COLORS.gray[100],
               },
               secondary: {
                  default: 'transparent',
                  hover: GLOBAL_COLORS.blue[500],
                  focus: GLOBAL_COLORS.blue[500],
                  active: GLOBAL_COLORS.blue[600],
                  loading: 'transparent',
                  disabled: GLOBAL_COLORS.gray[100],
               },
               neutral: {
                  default: 'transparent',
                  hover: GLOBAL_COLORS.gray[700],
                  focus: GLOBAL_COLORS.gray[700],
                  active: GLOBAL_COLORS.gray[800],
                  loading: 'transparent',
                  disabled: GLOBAL_COLORS.gray[100],
               },
               neutralLight: {
                  default: 'transparent',
                  hover: GLOBAL_COLORS.gray[500],
                  focus: GLOBAL_COLORS.gray[500],
                  active: GLOBAL_COLORS.gray[600],
                  loading: 'transparent',
                  disabled: GLOBAL_COLORS.gray[100],
               },
               light: {
                  default: 'transparent',
                  hover: GLOBAL_COLORS.gray[100],
                  focus: GLOBAL_COLORS.gray[100],
                  active: GLOBAL_COLORS.gray[200],
                  loading: 'transparent',
                  disabled: GLOBAL_COLORS.gray[100],
               },
            },
         },
         iconButton: {
            primary: {
               default: GLOBAL_COLORS.red[500],
               hover: GLOBAL_COLORS.red[600],
               focus: GLOBAL_COLORS.red[600],
               active: GLOBAL_COLORS.red[700],
               loading: GLOBAL_COLORS.red[500],
               disabled: GLOBAL_COLORS.gray[100],
            },
            secondary: {
               default: GLOBAL_COLORS.blue[500],
               hover: GLOBAL_COLORS.blue[600],
               focus: GLOBAL_COLORS.blue[600],
               active: GLOBAL_COLORS.blue[700],
               loading: GLOBAL_COLORS.blue[500],
               disabled: GLOBAL_COLORS.gray[100],
            },
            neutral: {
               default: GLOBAL_COLORS.gray[700],
               hover: GLOBAL_COLORS.gray[800],
               focus: GLOBAL_COLORS.gray[800],
               active: GLOBAL_COLORS.gray[900],
               loading: GLOBAL_COLORS.gray[700],
               disabled: GLOBAL_COLORS.gray[100],
            },
            neutralLight: {
               default: GLOBAL_COLORS.gray[500],
               hover: GLOBAL_COLORS.gray[600],
               focus: GLOBAL_COLORS.gray[600],
               active: GLOBAL_COLORS.gray[700],
               loading: GLOBAL_COLORS.gray[500],
               disabled: GLOBAL_COLORS.gray[100],
            },
            light: {
               default: GLOBAL_COLORS.gray[0],
               hover: GLOBAL_COLORS.gray[100],
               focus: GLOBAL_COLORS.gray[100],
               active: GLOBAL_COLORS.gray[200],
               loading: GLOBAL_COLORS.gray[0],
               disabled: GLOBAL_COLORS.gray[100],
            },
         },
         status: {
            blue: GLOBAL_COLORS.blue[400],
            blueSubtle: GLOBAL_COLORS.blue[300],
            red: GLOBAL_COLORS.red[500],
            redSubtle: GLOBAL_COLORS.red[300],
            green: GLOBAL_COLORS.green[500],
            greenSubtle: GLOBAL_COLORS.green[300],
            yellow: GLOBAL_COLORS.yellow[500],
            yellowSubtle: GLOBAL_COLORS.yellow[300],
         },
         paginator: {
            contained: {
               neutral: GLOBAL_COLORS.gray[700],
               light: GLOBAL_COLORS.white,
            },
            dashed: {
               neutral: 'transparent',
               light: 'transparent',
            },
         },
         paper: {
            body: GLOBAL_COLORS.gray[0],
            orderForm: GLOBAL_COLORS.white,
            dialog: GLOBAL_COLORS.white,
            orderFormSection: GLOBAL_COLORS.gray[0],
            orderFormSectionDarker: GLOBAL_COLORS.gray[100],
            orderFormSectionDark: GLOBAL_COLORS.gray[700],
            orderFormSectionAccent: GLOBAL_COLORS.white,
         },
         field: {
            switch: {
               default: {
                  inactive_container: GLOBAL_COLORS.gray[300],
                  inactive_thumb: GLOBAL_COLORS.white,
                  active_container: GLOBAL_COLORS.green[500],
                  active_thumb: GLOBAL_COLORS.white,
               },
            },
         },
      },
      border: {
         alert: {
            error: GLOBAL_COLORS.red[600],
            success: GLOBAL_COLORS.green[800],
            info: GLOBAL_COLORS.blue[600],
            warning: GLOBAL_COLORS.yellow[800],
         },
         colorCard: {
            defaultCard: GLOBAL_COLORS.gray[100],
            activeCard: GLOBAL_COLORS.red[500],
         },
         button: {
            contained: {
               primary: {
                  default: GLOBAL_COLORS.red[500],
                  hover: GLOBAL_COLORS.red[600],
                  focus: GLOBAL_COLORS.red[600],
                  active: GLOBAL_COLORS.red[700],
                  loading: GLOBAL_COLORS.red[500],
                  disabled: GLOBAL_COLORS.gray[100],
               },
               secondary: {
                  default: GLOBAL_COLORS.blue[500],
                  hover: GLOBAL_COLORS.blue[600],
                  focus: GLOBAL_COLORS.blue[600],
                  active: GLOBAL_COLORS.blue[700],
                  loading: GLOBAL_COLORS.blue[500],
                  disabled: GLOBAL_COLORS.gray[100],
               },
               neutral: {
                  default: GLOBAL_COLORS.gray[700],
                  hover: GLOBAL_COLORS.gray[800],
                  focus: GLOBAL_COLORS.gray[800],
                  active: GLOBAL_COLORS.gray[900],
                  loading: GLOBAL_COLORS.gray[700],
                  disabled: GLOBAL_COLORS.gray[100],
               },
               neutralLight: {
                  default: GLOBAL_COLORS.gray[500],
                  hover: GLOBAL_COLORS.gray[600],
                  focus: GLOBAL_COLORS.gray[600],
                  active: GLOBAL_COLORS.gray[700],
                  loading: GLOBAL_COLORS.gray[500],
                  disabled: GLOBAL_COLORS.gray[100],
               },
               light: {
                  default: GLOBAL_COLORS.gray[0],
                  hover: GLOBAL_COLORS.gray[100],
                  focus: GLOBAL_COLORS.gray[100],
                  active: GLOBAL_COLORS.gray[200],
                  loading: GLOBAL_COLORS.gray[0],
                  disabled: GLOBAL_COLORS.gray[100],
               },
            },
            outlined: {
               primary: {
                  default: GLOBAL_COLORS.red[500],
                  hover: GLOBAL_COLORS.red[500],
                  focus: GLOBAL_COLORS.red[500],
                  active: GLOBAL_COLORS.red[600],
                  loading: GLOBAL_COLORS.red[500],
                  disabled: GLOBAL_COLORS.gray[100],
               },
               secondary: {
                  default: GLOBAL_COLORS.blue[500],
                  hover: GLOBAL_COLORS.blue[500],
                  focus: GLOBAL_COLORS.blue[500],
                  active: GLOBAL_COLORS.blue[600],
                  loading: GLOBAL_COLORS.blue[500],
                  disabled: GLOBAL_COLORS.gray[100],
               },
               neutral: {
                  default: GLOBAL_COLORS.gray[700],
                  hover: GLOBAL_COLORS.gray[700],
                  focus: GLOBAL_COLORS.gray[700],
                  active: GLOBAL_COLORS.gray[800],
                  loading: GLOBAL_COLORS.gray[700],
                  disabled: GLOBAL_COLORS.gray[100],
               },
               neutralLight: {
                  default: GLOBAL_COLORS.gray[500],
                  hover: GLOBAL_COLORS.gray[500],
                  focus: GLOBAL_COLORS.gray[500],
                  active: GLOBAL_COLORS.gray[600],
                  loading: GLOBAL_COLORS.gray[500],
                  disabled: GLOBAL_COLORS.gray[100],
               },
               light: {
                  default: GLOBAL_COLORS.gray[100],
                  hover: GLOBAL_COLORS.gray[100],
                  focus: GLOBAL_COLORS.gray[100],
                  active: GLOBAL_COLORS.gray[100],
                  loading: GLOBAL_COLORS.gray[100],
                  disabled: GLOBAL_COLORS.gray[100],
               },
            },
         },
         status: {
            blue: GLOBAL_COLORS.blue[400],
            blueSubtle: GLOBAL_COLORS.blue[300],
            red: GLOBAL_COLORS.red[500],
            redSubtle: GLOBAL_COLORS.red[300],
            green: GLOBAL_COLORS.green[500],
            greenSubtle: GLOBAL_COLORS.green[300],
            yellow: GLOBAL_COLORS.yellow[500],
            yellowSubtle: GLOBAL_COLORS.yellow[300],
         },
         iconButton: {
            primary: {
               default: GLOBAL_COLORS.red[500],
               hover: GLOBAL_COLORS.red[600],
               focus: GLOBAL_COLORS.red[600],
               active: GLOBAL_COLORS.red[700],
               loading: GLOBAL_COLORS.red[500],
               disabled: GLOBAL_COLORS.gray[100],
            },
            secondary: {
               default: GLOBAL_COLORS.blue[500],
               hover: GLOBAL_COLORS.blue[600],
               focus: GLOBAL_COLORS.blue[600],
               active: GLOBAL_COLORS.blue[700],
               loading: GLOBAL_COLORS.blue[500],
               disabled: GLOBAL_COLORS.gray[100],
            },
            neutral: {
               default: GLOBAL_COLORS.gray[700],
               hover: GLOBAL_COLORS.gray[800],
               focus: GLOBAL_COLORS.gray[800],
               active: GLOBAL_COLORS.gray[900],
               loading: GLOBAL_COLORS.gray[700],
               disabled: GLOBAL_COLORS.gray[100],
            },
            neutralLight: {
               default: GLOBAL_COLORS.gray[500],
               hover: GLOBAL_COLORS.gray[600],
               focus: GLOBAL_COLORS.gray[600],
               active: GLOBAL_COLORS.gray[700],
               loading: GLOBAL_COLORS.gray[500],
               disabled: GLOBAL_COLORS.gray[100],
            },
            light: {
               default: GLOBAL_COLORS.gray[0],
               hover: GLOBAL_COLORS.gray[100],
               focus: GLOBAL_COLORS.gray[100],
               active: GLOBAL_COLORS.gray[200],
               loading: GLOBAL_COLORS.gray[0],
               disabled: GLOBAL_COLORS.gray[100],
            },
         },
         field: {
            switch: {
               default: {
                  inactive_container: GLOBAL_COLORS.gray[500],
                  inactive_thumb: GLOBAL_COLORS.white,
                  active_container: GLOBAL_COLORS.green[500],
                  active_thumb: GLOBAL_COLORS.white,
               },
            },
         },
         paginator: {
            contained: {
               neutral: GLOBAL_COLORS.gray[700],
               light: GLOBAL_COLORS.white,
            },
            dashed: {
               neutral: GLOBAL_COLORS.gray[700],
               light: GLOBAL_COLORS.white,
            },
         },
         productDescriptionSection: GLOBAL_COLORS.blue[500],
         divider: {
            light: GLOBAL_COLORS.gray[0],
            dark: GLOBAL_COLORS.gray[100],
         },
      },
      text: {
         externalLink: GLOBAL_COLORS.blue[500],
         alert: {
            error: {
               body: GLOBAL_COLORS.red[900],
               headline: GLOBAL_COLORS.red[600],
            },
            info: {
               body: GLOBAL_COLORS.blue[900],
               headline: GLOBAL_COLORS.blue[600],
            },
            success: {
               body: GLOBAL_COLORS.green[900],
               headline: GLOBAL_COLORS.green[700],
            },
            warning: {
               body: GLOBAL_COLORS.yellow[900],
               headline: GLOBAL_COLORS.yellow[800],
            },
         },
         button: {
            contained: {
               primary: {
                  default: GLOBAL_COLORS.white,
                  hover: GLOBAL_COLORS.white,
                  focus: GLOBAL_COLORS.white,
                  active: GLOBAL_COLORS.white,
                  loading: GLOBAL_COLORS.white,
                  disabled: GLOBAL_COLORS.gray[300],
               },
               secondary: {
                  default: GLOBAL_COLORS.white,
                  hover: GLOBAL_COLORS.white,
                  focus: GLOBAL_COLORS.white,
                  active: GLOBAL_COLORS.white,
                  loading: GLOBAL_COLORS.white,
                  disabled: GLOBAL_COLORS.gray[300],
               },
               neutral: {
                  default: GLOBAL_COLORS.white,
                  hover: GLOBAL_COLORS.white,
                  focus: GLOBAL_COLORS.white,
                  active: GLOBAL_COLORS.white,
                  loading: GLOBAL_COLORS.white,
                  disabled: GLOBAL_COLORS.gray[300],
               },
               neutralLight: {
                  default: GLOBAL_COLORS.white,
                  hover: GLOBAL_COLORS.white,
                  focus: GLOBAL_COLORS.white,
                  active: GLOBAL_COLORS.white,
                  loading: GLOBAL_COLORS.white,
                  disabled: GLOBAL_COLORS.gray[300],
               },
               light: {
                  default: GLOBAL_COLORS.gray[700],
                  hover: GLOBAL_COLORS.gray[700],
                  focus: GLOBAL_COLORS.gray[700],
                  active: GLOBAL_COLORS.gray[700],
                  loading: GLOBAL_COLORS.gray[700],
                  disabled: GLOBAL_COLORS.gray[300],
               },
            },
            outlined: {
               primary: {
                  default: GLOBAL_COLORS.red[500],
                  hover: GLOBAL_COLORS.white,
                  focus: GLOBAL_COLORS.white,
                  active: GLOBAL_COLORS.white,
                  loading: GLOBAL_COLORS.red[500],
                  disabled: GLOBAL_COLORS.gray[300],
               },
               secondary: {
                  default: GLOBAL_COLORS.blue[500],
                  hover: GLOBAL_COLORS.white,
                  focus: GLOBAL_COLORS.white,
                  active: GLOBAL_COLORS.white,
                  loading: GLOBAL_COLORS.blue[500],
                  disabled: GLOBAL_COLORS.gray[300],
               },
               neutral: {
                  default: GLOBAL_COLORS.gray[600],
                  hover: GLOBAL_COLORS.white,
                  focus: GLOBAL_COLORS.white,
                  active: GLOBAL_COLORS.white,
                  loading: GLOBAL_COLORS.gray[600],
                  disabled: GLOBAL_COLORS.gray[300],
               },
               neutralLight: {
                  default: GLOBAL_COLORS.gray[600],
                  hover: GLOBAL_COLORS.white,
                  focus: GLOBAL_COLORS.white,
                  active: GLOBAL_COLORS.white,
                  loading: GLOBAL_COLORS.gray[600],
                  disabled: GLOBAL_COLORS.gray[300],
               },
               light: {
                  default: GLOBAL_COLORS.gray[100],
                  hover: GLOBAL_COLORS.gray[700],
                  focus: GLOBAL_COLORS.gray[700],
                  active: GLOBAL_COLORS.gray[700],
                  loading: GLOBAL_COLORS.gray[100],
                  disabled: GLOBAL_COLORS.gray[300],
               },
            },
         },
         iconButton: {
            primary: {
               default: GLOBAL_COLORS.white,
               hover: GLOBAL_COLORS.white,
               focus: GLOBAL_COLORS.white,
               active: GLOBAL_COLORS.white,
               loading: GLOBAL_COLORS.white,
               disabled: GLOBAL_COLORS.gray[300],
            },
            secondary: {
               default: GLOBAL_COLORS.white,
               hover: GLOBAL_COLORS.white,
               focus: GLOBAL_COLORS.white,
               active: GLOBAL_COLORS.white,
               loading: GLOBAL_COLORS.white,
               disabled: GLOBAL_COLORS.gray[300],
            },
            neutral: {
               default: GLOBAL_COLORS.white,
               hover: GLOBAL_COLORS.white,
               focus: GLOBAL_COLORS.white,
               active: GLOBAL_COLORS.white,
               loading: GLOBAL_COLORS.white,
               disabled: GLOBAL_COLORS.gray[300],
            },
            neutralLight: {
               default: GLOBAL_COLORS.white,
               hover: GLOBAL_COLORS.white,
               focus: GLOBAL_COLORS.white,
               active: GLOBAL_COLORS.white,
               loading: GLOBAL_COLORS.white,
               disabled: GLOBAL_COLORS.gray[300],
            },
            light: {
               default: GLOBAL_COLORS.gray[700],
               hover: GLOBAL_COLORS.gray[700],
               focus: GLOBAL_COLORS.gray[700],
               active: GLOBAL_COLORS.gray[700],
               loading: GLOBAL_COLORS.gray[700],
               disabled: GLOBAL_COLORS.gray[300],
            },
         },
         status: {
            blue: GLOBAL_COLORS.white,
            blueSubtle: GLOBAL_COLORS.white,
            red: GLOBAL_COLORS.white,
            redSubtle: GLOBAL_COLORS.white,
            green: GLOBAL_COLORS.white,
            greenSubtle: GLOBAL_COLORS.white,
            yellow: GLOBAL_COLORS.white,
            yellowSubtle: GLOBAL_COLORS.white,
         },
         paginator: {
            contained: {
               neutral: GLOBAL_COLORS.white,
               light: GLOBAL_COLORS.gray[800],
            },
            dashed: {
               neutral: GLOBAL_COLORS.black,
               light: GLOBAL_COLORS.white,
            },
         },
         typography: {
            text: {
               default: GLOBAL_COLORS.black,
               light: GLOBAL_COLORS.white,
               neutral: GLOBAL_COLORS.gray[400],
               negative: GLOBAL_COLORS.red[500],
               positive: GLOBAL_COLORS.green[500],
            },
            heading: {
               default: GLOBAL_COLORS.black,
               light: GLOBAL_COLORS.white,
               neutral: GLOBAL_COLORS.gray[400],
               negative: GLOBAL_COLORS.red[500],
               positive: GLOBAL_COLORS.green[500],
            },
         },
         field: {
            switch: {
               default: {
                  inactive_container: GLOBAL_COLORS.gray[500],
                  inactive_thumb: GLOBAL_COLORS.white,
                  active_container: GLOBAL_COLORS.green[500],
                  active_thumb: GLOBAL_COLORS.white,
               },
            },
         },
         link: {
            default: GLOBAL_COLORS.blue[500],
            hover: GLOBAL_COLORS.blue[600],
         },
         dialog: GLOBAL_COLORS.white,
      },
      outline: GLOBAL_COLORS.blue[500],
   };
};
