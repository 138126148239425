/* eslint-disable no-case-declarations */

import axios from 'axios';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_ATTR = 'UPDATE_PRODUCT_ATTR';
export const UPDATE_PRODUCT_ATTRIBUTES = 'UPDATE_PRODUCT_ATTRIBUTES';
export const ADD_PRODUCT_ATTACHMENT = 'ADD_PRODUCT_ATTACHMENT';
export const REMOVE_PRODUCT_ATTACHMENT = 'REMOVE_PRODUCT_ATTACHMENT';
export const UPDATE_PRODUCT_PRICE = 'UPDATE_PRODUCT_PRICE';
export const SET_ORDER_NAME = 'SET_ORDER_NAME';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const REMOVE_ORDER = 'REMOVE_ORDER';
export const SET_ORDERS = 'SET_ORDERS';

export const productPriceCalculated = (
   orderId,
   productId,
   priceWithTax,
   priceWithoutTax,
   priceDiscountedWithTax,
) => ({
   type: 'PRODUCT_PRICE_CALCULATED',
   payload: {
      orderId,
      productId,
      priceWithTax,
      priceWithoutTax,
      priceDiscountedWithTax,
   },
});

const CHANGE_PRODUCT_QUANTITY = 'CHANGE_PRODUCT_QUANTITY';
export const changeProductQuantity = (orderId, productId, quantity) => ({
   type: CHANGE_PRODUCT_QUANTITY,
   payload: {
      orderId,
      productId,
      quantity,
   },
});

export const fetchOrders = (finallyCb) => (dispatch) =>
   axios
      .get('/orders')
      .then(({ data }) =>
         dispatch({
            type: 'FETCH_ORDERS',
            payload: {
               orders: data.orders,
            },
         }),
      )
      .finally(finallyCb);

export const fetchOrder =
   (id, finallyCb = null) =>
   (dispatch) =>
      axios
         .get(`/orders/${id}`)
         .then(({ data }) =>
            dispatch({
               type: 'FETCH_ORDER',
               order: data.order,
            }),
         )
         .finally(finallyCb);

const orderInitialState = {
   orders: [],
   defaultOrderColor: undefined,
};

const updateProduct = (state, action) => {
   const orderIndex = state.orders.findIndex((order) => order.id === action.orderId);
   const index = state.orders[orderIndex].products.findIndex((product) => product.id === action.id);

   return update(state, {
      orders: {
         [orderIndex]: {
            products: {
               [index]: {
                  [action.prop]: { $set: action.value },
               },
            },
         },
      },
   });
};

const updateProductAttr = (state, action) => {
   const ordersArr = [...state.orders];
   const orderIndex = ordersArr.findIndex((order) => order.id === action.orderId);
   const productsArr = ordersArr[orderIndex].products;
   const productIndex = productsArr.findIndex((product) => product.id === action.id);
   const attrIndex = productsArr[productIndex].attributes.findIndex((a) => a.key === action.prop);

   return update(state, {
      orders: {
         [orderIndex]: {
            products: {
               [productIndex]: {
                  attributes: {
                     [attrIndex]: {
                        value: { $set: action.value },
                     },
                  },
               },
            },
         },
      },
   });
};

const updateProductAttributes = (state, action) => {
   const ordersArr = [...state.orders];
   const orderIndex = ordersArr.findIndex((order) => order.id === action.orderId);
   const productsArr = ordersArr[orderIndex].products;
   const productIndex = productsArr.findIndex((product) => product.id === action.id);

   return update(state, {
      orders: {
         [orderIndex]: {
            products: {
               [productIndex]: {
                  attributes: { $set: action.value },
               },
            },
         },
      },
   });
};

const updateProductPrice = (state, action) => {
   const orderIndex = state.orders.findIndex((o) => o.id === action.orderId);
   const productsArr = state.orders[orderIndex].products;
   const productIndex = productsArr.findIndex((p) => p.id === action.productId);

   return update(state, {
      orders: {
         [orderIndex]: {
            products: {
               [productIndex]: {
                  priceWithoutTax: { $set: action.priceWithoutTax },
                  priceWithTax: { $set: action.priceWithTax },
                  priceAfterDiscount: { $set: action.priceAfterDiscount },
                  tax: { $set: action.tax },
                  currency: { $set: action.currency },
                  [action.prop]: { $set: action.value },
               },
            },
         },
      },
   });
};

const addAttachmentToProduct = (state, action) => {
   const orderIndex = state.orders.findIndex((o) => o.id === action.orderId);
   const productsArr = state.orders[orderIndex].products;
   const productIndex = productsArr.findIndex((p) => p.id === action.productId);

   return update(state, {
      orders: {
         [orderIndex]: {
            products: {
               [productIndex]: {
                  attachments: { $push: action.attachments },
               },
            },
         },
      },
   });
};

const removeAttachmentFromProduct = (state, action) => {
   const orderIndex = state.orders.findIndex((o) => o.id === action.orderId);
   const productsArr = state.orders[orderIndex].products;
   const productIndex = productsArr.findIndex((p) => p.id === action.productId);
   const attachmentIndex = productsArr[productIndex].attachments.findIndex(
      (a) => a.id === action.id,
   );

   return update(state, {
      orders: {
         [orderIndex]: {
            products: {
               [productIndex]: {
                  attachments: { $splice: [[attachmentIndex, 1]] },
               },
            },
         },
      },
   });
};

const duplicateProduct = (state, action) => {
   const ordersArr = [...state.orders];
   const orderIndex = ordersArr.findIndex((order) => order.id === action.orderId);
   const productsArr = ordersArr[orderIndex].products;
   const product = productsArr.find((p) => p.id === action.id);

   const newProducts = [...state.orders[orderIndex].products];
   newProducts.push({
      ...product,
      id: uuidv4(),
   });

   return update(state, {
      orders: {
         [orderIndex]: {
            products: { $set: newProducts },
         },
      },
   });
};

const discountAddedToProduct = (state, action) => {
   const orderIndex = state.orders.findIndex((o) => o.id === action.orderId);
   const productIndex = state.orders[orderIndex].products.findIndex((p) => p.id === action.id);

   return update(state, {
      orders: {
         [orderIndex]: {
            products: {
               [productIndex]: {
                  discount: { $set: action.discount },
               },
            },
         },
      },
   });
};

const removeProduct = (state, action) => {
   const ordersArr = [...state.orders];
   const orderIndex = ordersArr.findIndex((order) => order.id === action.orderId);
   const productsArr = ordersArr[orderIndex].products;
   const productIndex = productsArr.findIndex((p) => p.id === action.id);

   const newProducts = [...state.orders[orderIndex].products];
   newProducts.splice(productIndex, 1);

   return update(state, {
      orders: {
         [orderIndex]: {
            products: { $splice: [[productIndex, 1]] },
         },
      },
   });
};

const removeOrder = (state, action) => {
   const ordersArr = [...state.orders];
   const orderIndex = ordersArr.findIndex((o) => o.id === action.orderId);

   return update(state, {
      orders: { $splice: [[orderIndex, 1]] },
   });
};

const setOrderName = (state, action) => {
   const orderIndex = state.orders.findIndex((order) => order.id === action.orderId);

   return update(state, {
      orders: {
         [orderIndex]: {
            name: { $set: action.name },
         },
      },
   });
};

// eslint-disable-next-line default-param-last
const order = (state = orderInitialState, action) => {
   switch (action.type) {
      case 'CREATE_ORDER':
         return {
            ...state,
            orders: [...state.orders, action.order],
         };
      case UPDATE_PRODUCT:
         return updateProduct(state, action);
      case UPDATE_PRODUCT_ATTR:
         return updateProductAttr(state, action);
      case UPDATE_PRODUCT_ATTRIBUTES:
         return updateProductAttributes(state, action);
      case ADD_PRODUCT_ATTACHMENT:
         return addAttachmentToProduct(state, action);
      case REMOVE_PRODUCT_ATTACHMENT:
         return removeAttachmentFromProduct(state, action);
      case UPDATE_PRODUCT_PRICE:
         return updateProductPrice(state, action);
      case 'ADD_PRODUCT_TO_ORDER':
         return {
            ...state,
            orders: state.orders.map((o) =>
               o.id === action.orderId
                  ? {
                       ...o,
                       products: [...o.products, action.product],
                    }
                  : o,
            ),
         };
      case 'DUPLICATE_PRODUCT':
         return duplicateProduct(state, action);
      case 'DISCOUNT_ADDED_TO_PRODUCT':
         return discountAddedToProduct(state, action);
      case REMOVE_PRODUCT:
         return removeProduct(state, action);
      case REMOVE_ORDER:
         return removeOrder(state, action);
      case SET_ORDERS:
         return {
            ...state,
            orders: action.orders,
         };
      case SET_ORDER_NAME:
         return setOrderName(state, action);
      case CHANGE_PRODUCT_QUANTITY:
         const chpq__orderIndex = state.orders.findIndex((o) => o.id === action.payload.orderId);
         const chpq__productIndex = state.orders[chpq__orderIndex].products.findIndex(
            (p) => p.id === action.payload.productId,
         );

         return update(state, {
            orders: {
               [chpq__orderIndex]: {
                  products: {
                     [chpq__productIndex]: {
                        quantity: { $set: action.payload.quantity },
                     },
                  },
               },
            },
         });
      case 'PRODUCT_PRICE_CALCULATED':
         const ppc__orderIndex = state.orders.findIndex((o) => o.id === action.payload.orderId);
         const ppc__productIndex = state.orders[ppc__orderIndex].products.findIndex(
            (p) => p.id === action.payload.productId,
         );

         return update(state, {
            orders: {
               [ppc__orderIndex]: {
                  products: {
                     [ppc__productIndex]: {
                        priceWithTax: { $set: action.payload.priceWithTax },
                        priceWithoutTax: { $set: action.payload.priceWithoutTax },
                        priceDiscountedWithoutTax: {
                           $set: action.payload.priceDiscountedWithoutTax,
                        },
                        priceDiscountedWithTax: { $set: action.payload.priceDiscountedWithTax },
                     },
                  },
               },
            },
         });
      case 'FETCH_ORDERS':
         return update(state, {
            orders: { $set: action.payload.orders },
         });
      case 'FETCH_ORDER':
         return {
            ...state,
            orders: state.orders.length
               ? state.orders.map((o) => (o.id === action.order.id ? action.order : o))
               : [action.order],
         };
      case 'SET_DEFAULT_ORDER_COLOR':
         return {
            ...state,
            defaultOrderColor: action.defaultOrderColor,
         };
      default:
         return state;
   }
};

export default order;
