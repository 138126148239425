import { Navigate } from 'react-router-dom';

import { loadUser, signOut } from 'auth';

const isAuthorised = (roles, accessOnlyForRoles) =>
   roles.some((r) => accessOnlyForRoles.includes(r));

const PrivateRoute = ({ element, accessOnlyForRoles }) => {
   const user = loadUser();

   if (!user) {
      signOut();
      return <Navigate to={{ pathname: '/sign-in' }} />;
   }

   // TODO each user should have ROLE_USER
   // TODO each user should have ROLE_USER
   // TODO each user should have ROLE_USER
   const authorised = accessOnlyForRoles.length
      ? isAuthorised(user.roles, accessOnlyForRoles)
      : true;

   if (!authorised) {
      signOut();
      return <Navigate to={{ pathname: '/sign-in' }} />;
   }

   return element;
};

export default PrivateRoute;
