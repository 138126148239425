import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import CreatedByInternalClientActions from 'Pages/WarehouseManager/OrdersWithoutGroup/CreatedByInternalClientActions';
import WareHouseCountdown from 'Pages/WarehouseManager/WareHouseCountdown';
import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { paletteTypes } from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';
import { CompanyName } from 'Shared/Warehouse/Warehouse';

import SplitOrderButton from '../SplitOrderButton';

const Palette = ({ palette }) => {
   if (!palette) return null;
   return (
      <div>
         <p>
            Ilość: {palette.amount} | Typ: {paletteTypes[palette.type]}
         </p>
      </div>
   );
};

const OrderPart = ({ order, orderPart, stickyView = false }) => {
   const dispatch = useDispatch();

   return (
      <tr id={orderPart.id}>
         <td>
            <ZamowieniaLink displayId={order.zamowieniaId} zamowieniaId={order.zamowieniaId} />
         </td>
         <td>
            <CompanyName
               orderPartsGroup={order}
               createdByExternalClient={order.createdByExternalClient}
            />
         </td>
         <td>
            {order.startedPaintingAt && (
               <WareHouseCountdown date={dayjs(order.startedPaintingAt)} />
            )}
            <p>{order.checkedInAt}</p>
         </td>
         <td>
            <Products products={orderPart.products} />
         </td>
         <td>
            <Palette palette={orderPart.palette} />
            {stickyView && <CreatedByInternalClientActions order={order} orderPart={orderPart} />}
            <SplitOrderButton order={order} />
            {!stickyView && (
               <div>
                  <StartPlaningButton
                     type="button"
                     className="btn btn-light btn-icon"
                     onClick={() =>
                        dispatch({
                           type: 'START_PLANING',
                           order,
                           orderPart,
                           companyName: order.companyName,
                        })
                     }
                  >
                     <FontAwesomeIcon icon={faClipboard} />
                     Rozplanuj
                  </StartPlaningButton>
               </div>
            )}
         </td>
      </tr>
   );
};

const StartPlaningButton = styled.button`
   width: 100%;
   margin-top: 1rem;
   .svg-inline--fa {
      color: #000;
   }
`;

export default OrderPart;
