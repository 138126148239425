import styled from 'styled-components';

import { colors } from 'deprecatedTheme/colors';

const Error = ({ children }) => <Wrapper name="error">{children}</Wrapper>;

const Wrapper = styled.span`
   display: block;
   padding: 0;
   margin: 12px 0 0;
   color: ${colors.primary};
   font-size: 12px;
   font-weight: 600;
   text-align: center;
`;

export default Error;
