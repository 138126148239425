import { useFormikContext } from 'formik';

const regeneratePublicIds = (palettes) => {
   return palettes.map((p, i) => {
      const publicId = `${i + 1}/${palettes.length}`;
      return {
         ...p,
         publicId,
      };
   });
};

const MergeSelect = ({ orderPart, paletteIndex }) => {
   const formik = useFormikContext();
   const options = formik.values.palettes.map((p, i) => {
      if (i === paletteIndex) return null;
      return (
         <option key={p.publicId} value={p.publicId}>
            {p.publicId}
         </option>
      );
   });
   const merge = (e) => {
      const { value: palettePublicId } = e.target;
      const palettes = formik.values.palettes.map((p, i) => {
         if (p.publicId === palettePublicId) {
            const paletteWithNewPart = {
               ...p,
               orderParts: [...p.orderParts, orderPart],
            };
            return paletteWithNewPart;
         }
         if (i === paletteIndex) {
            const orderParts = p.orderParts.filter((op) => {
               return op.id !== orderPart.id;
            });
            const paletteWithRemovedPart = {
               ...p,
               orderParts,
            };
            return paletteWithRemovedPart;
         }
         return p;
      });
      const palettesOnlyWithParts = palettes.filter((p) => {
         return p.orderParts.length;
      });
      const palettesWithRegeneratedPublicIds = regeneratePublicIds(palettesOnlyWithParts);
      formik.setFieldValue('palettes', palettesWithRegeneratedPublicIds);
   };
   const thereIsOnlyOnePalette = formik.values.palettes.length === 1;
   return (
      <select className="form-control" onChange={merge} disabled={thereIsOnlyOnePalette}>
         <option value="">Połącz z inną paletą</option>
         {options}
      </select>
   );
};

export default MergeSelect;
