import styled from 'styled-components';

import { orderPartsGroupStatuses } from 'Shared/Warehouse/OrderPartsGroup';

import FinalPalette from './FinalPalette';

const GroupWithFinalPalettes = ({ group }) => {
   const finalPaletts = group.finalPalettes.map((p) => {
      return (
         <tr key={p.id}>
            <td>
               <FinalPalette finalPalette={p} />
            </td>
         </tr>
      );
   });
   return (
      <tr key={group.id}>
         <td>
            <Description>
               <span>Klient: {group.companyName}</span>
               <span>Utworzono: {group.createdAt}</span>
               <span>Spakowano na gotowo: {group.packedAt}</span>
               <span>Wysłano: {group.sentAt}</span>
               <span>Status: {orderPartsGroupStatuses[group.status]}</span>
               <span>Id grupy: {group.publicId}</span>
            </Description>
         </td>
         <td>
            <table className="table table-hover table-sm">
               <thead>
                  <tr>
                     <th />
                  </tr>
               </thead>
               <tbody>{finalPaletts}</tbody>
            </table>
         </td>
      </tr>
   );
};

const Description = styled.div`
   display: flex;
   flex-direction: column;
`;

export default GroupWithFinalPalettes;
