import { faBox, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import styled from 'styled-components';

import { CompanyName } from 'Shared/Warehouse/Warehouse';
import { Loader } from 'ui/Loader';

import LastIssues from './LastIssues';
import PaletteForm from './PaletteForm';

const validate = (values) => {
   let errors = {};
   if (!values.packedByFilledInManually) {
      errors = {
         ...errors,
         packedByFilledInManually: 'Nieprawidłowa wartość.',
      };
   }

   return errors;
};

const availableAttributes = [
   'amount',
   'width',
   'height',
   'length',
   'width2',
   'width3',
   'height2',
   'height3',
];

const attributesFromPalette = (palette) => {
   return Object.entries(palette).reduce((acc, [key, value]) => {
      if (availableAttributes.includes(key)) {
         // eslint-disable-next-line no-param-reassign
         acc = [
            ...acc,
            {
               key,
               value,
            },
         ];
      }
      return acc;
   }, []);
};

const FinalPaletteModal = ({ group, close, refetch }) => {
   const formik = useFormik({
      initialValues: {
         palettes: [],
         packedByFilledInManually: '',
      },
      onSubmit: (values) => {
         const palettes = values.palettes.map((p) => {
            const orderParts = p.orderParts.map((op) => {
               return {
                  id: op.id,
               };
            });
            const attributes = attributesFromPalette(p);
            return {
               id: p.id,
               orderParts,
               attributes,
            };
         });
         const data = {
            palettes,
            packedByFilledInManually: values.packedByFilledInManually,
         };
         axios.post(`/warehouse/groups/${group.id}/pack-final-palettes`, data).then(() => {
            close();
            refetch();
         });
      },
      validate,
   });
   useEffect(() => {
      if (formik.values.palettes.length) return;
      const palettes = group.palettes.map((p) => {
         return {
            id: p.id,
            publicId: p.publicId,
            orderParts: p.orderParts,
            type: p.type,
         };
      });
      formik.setFieldValue('palettes', palettes);
   }, []);
   const rendered = formik.values.palettes.map((p, i) => {
      return <PaletteForm key={p.id} palette={p} index={i} />;
   });
   const disabled = formik.isSubmitting || Object.keys(formik.errors).length;

   return (
      <Modal className="modal" tabIndex="-1" role="dialog">
         <div className="modal-dialog modal-xl" role="document">
            <Container className="modal-content">
               <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                     <div className="modal-header">
                        <h3 className="modal-title">
                           <span>Wysyłasz:&nbsp;</span>
                           <CompanyName
                              orderPartsGroup={group}
                              createdByExternalClient={
                                 group.palettes[0].orderParts[0].order.createdByExternalClient
                              }
                           />
                           <span>| Id grupy: {group.publicId}</span>
                        </h3>
                        <button
                           type="button"
                           className="close"
                           data-dismiss="modal"
                           aria-label="Close"
                           onClick={close}
                        >
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     <Body className="modal-body">
                        <Palettes>
                           <PalettesHeader>
                              <FontAwesomeIcon icon={faBoxOpen} />
                              Palety
                           </PalettesHeader>
                           <div>{rendered}</div>
                           <PackedByFilledInManuallyContainer>
                              <div>
                                 <label htmlFor="packedByFilledInManually">
                                    Spakowane przez
                                    <input
                                       type="text"
                                       name="packedByFilledInManually"
                                       id="packedByFilledInManually"
                                       className="form-control"
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       value={formik.values.packedByFilledInManually}
                                    />
                                 </label>
                              </div>
                           </PackedByFilledInManuallyContainer>
                        </Palettes>
                     </Body>
                     <FooterContainer className="modal-footer">
                        <LastIssues />
                        <ModalActions>
                           <Packed type="submit" className="btn btn-success" disabled={disabled}>
                              <FontAwesomeIcon icon={faBox} />
                              Spakowane na gotowo
                           </Packed>
                           <button
                              type="button"
                              className="btn btn-danger"
                              data-dismiss="modal"
                              onClick={close}
                           >
                              Zamknij
                           </button>
                        </ModalActions>
                     </FooterContainer>
                  </form>
               </FormikProvider>
               {formik.isSubmitting && <Loader />}
            </Container>
         </div>
      </Modal>
   );
};

const Container = styled.div`
   overflow-y: auto;
   max-height: calc(100vh - 1.75rem);
`;

const Body = styled.div`
   & > div {
      margin-bottom: 1rem;
   }
`;

const FooterContainer = styled.div`
   display: flex;
   justify-content: space-between;
`;

const ModalActions = styled.div`
   .btn {
      margin-left: 1rem;
   }
`;

const Packed = styled.button`
   .svg-inline--fa {
      margin-right: 0.5rem;
   }
`;

const Modal = styled.div`
   display: block;
   background-color: rgba(0, 0, 0, 0.9);
`;

const Palettes = styled.div`
   margin: 1rem 0;
`;

const PackedByFilledInManuallyContainer = styled.div`
   display: flex;
   justify-content: flex-end;
`;

const PalettesHeader = styled.h4`
   .svg-inline--fa {
      margin-right: 0.5rem;
   }
`;

export default FinalPaletteModal;
