import { useState } from 'react';
import styled from 'styled-components';

import LastUpdatedAt from 'Pages/Dashboards/Warehouse/LastUpdatedAt';
import ShowHideButton from 'Shared/Warehouse/ShowHideButton';

import Group from './Group/Group';

const ForSending = ({ groups, updatedAt }) => {
   const [render, setRender] = useState(true);
   const rendered = render && groups.map((g) => <Group key={g.id} group={g} />);

   return (
      <div className="row">
         <div className="col">
            <HeaderContainer>
               <h2>
                  Zbrojenie bram | Pakowanie do wysyłki
                  <ShowHideButton render={render} onClick={() => setRender((p) => !p)} />
               </h2>
            </HeaderContainer>
            <table className="table table-hover">
               <thead>
                  <tr>
                     <th>Grupa</th>
                     <th>Klient</th>
                     <th>Palety</th>
                     <th />
                  </tr>
               </thead>
               <tbody>{rendered}</tbody>
            </table>
            <LastUpdatedAt updatedAt={updatedAt} />
         </div>
      </div>
   );
};

const HeaderContainer = styled.div`
   margin-bottom: 2rem;
   color: #28a745;
`;

export default ForSending;
