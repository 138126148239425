import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { colors } from 'deprecatedTheme/colors';
import { Response } from 'Shared/Response';
import { closeSplitOrderModal, orderChanged } from 'state/warehouse';

import { Context as WarehouseManagerContext } from '../Context';

import { Context } from './Context';
import OrderPart from './OrderPart/OrderPart';

const SplitOrderModal = () => {
   const dispatch = useDispatch();
   const splitOrderModal = useSelector((s) => s.warehouse.splitOrderModal);
   const [info, setInfo] = useState(null);
   const [originalProductsMap, setOriginalProductsMap] = useState([]);
   const [ordertPartIdOriginalPartIdMap, setOrdertPartIdOriginalPartIdMap] = useState({});
   const { refetch } = useContext(WarehouseManagerContext);
   const formik = useFormik({
      initialValues: {
         id: splitOrderModal.orderId,
         zamowieniaId: 0,
         parts: [],
      },
      onSubmit: (values) => {
         setInfo(null);
         const data = {
            order: {
               id: values.id,
               zamowieniaId: values.zamowieniaId,
               parts: values.parts,
            },
            ordertPartIdOriginalPartIdMap,
         };
         axios.post(`/warehouse/orders/${values.id}/split`, data).then(() => {
            setInfo('Rozdzielone, możesz zamknąć');
            dispatch(orderChanged(values));
            refetch();
         });
      },
   });
   useEffect(() => {
      if (!splitOrderModal.orderId) return;
      axios.get(`/warehouse/orders/${splitOrderModal.orderId}/for-split`).then(({ data }) => {
         const { order } = data;
         formik.setFieldValue('zamowieniaId', order.zamowieniaId);
         formik.setFieldValue('parts', order.parts);
         setOriginalProductsMap(order.originalProductsMap);
      });
   }, [splitOrderModal.orderId]);
   const close = () => dispatch(closeSplitOrderModal());
   const parts = formik.values?.parts.map((p, i) => (
      <OrderPart key={p.id} part={p} partsIndex={i} />
   ));
   const rendered = splitOrderModal.errors.map((e) => <li key={e.id}>{e.message}</li>);
   const closeTxt = info ? 'Zamknij' : 'Anuluj';
   const disabled = formik.isSubmitting || splitOrderModal.errors.length;
   const value = useMemo(
      () => ({
         originalProductsMap,
         setOriginalProductsMap,
         ordertPartIdOriginalPartIdMap,
         setOrdertPartIdOriginalPartIdMap,
      }),
      [
         originalProductsMap,
         setOriginalProductsMap,
         ordertPartIdOriginalPartIdMap,
         setOrdertPartIdOriginalPartIdMap,
      ],
   );

   return (
      <Context.Provider value={value}>
         <Modal className="modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
               <Container className="modal-content">
                  <FormikProvider value={formik}>
                     <form onSubmit={formik.handleSubmit}>
                        <div className="modal-header">
                           <h5 className="modal-title">
                              Rozdzielasz zamówienie numer {formik.values.zamowieniaId}
                           </h5>
                           <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={close}
                           >
                              <span aria-hidden="true">&times;</span>
                           </button>
                        </div>
                        <div className="modal-body">
                           <div>{parts}</div>
                           {!!splitOrderModal.errors.length && (
                              <ErrorsContainer>
                                 <span>Ilości się nie zgadzają:</span>
                                 <ul>{rendered}</ul>
                              </ErrorsContainer>
                           )}
                        </div>
                        <FooterContainer className="modal-footer">
                           <ActionsContainer>
                              <button type="submit" className="btn btn-success" disabled={disabled}>
                                 Zapisz
                              </button>
                              <button
                                 type="button"
                                 className="btn btn-danger"
                                 data-dismiss="modal"
                                 onClick={close}
                              >
                                 {closeTxt}
                              </button>
                           </ActionsContainer>
                           {info && <Response error={false} messages={[info]} />}
                        </FooterContainer>
                     </form>
                  </FormikProvider>
               </Container>
            </div>
         </Modal>
      </Context.Provider>
   );
};

const Container = styled.div`
   overflow-y: auto;
   max-height: calc(100vh - 1.75rem);
`;

const FooterContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-end;
`;

const ActionsContainer = styled.div`
   & > .btn:not(:first-child) {
      margin-left: 1rem;
   }
`;

const ErrorsContainer = styled.div`
   color: ${colors.primary};
`;

const Modal = styled.div`
   display: block;
   background-color: rgba(0, 0, 0, 0.9);
`;

export default SplitOrderModal;
