import styled from 'styled-components';

import Product from './Product/Product';

const Container = styled.div`
   margin-left: 2rem;
`;

const Products = ({ part, partsIndex }) => {
   const rendered = part.products?.map((p, i) => (
      <Product key={p.id} product={p} part={part} partsProductIndex={i} partsIndex={partsIndex} />
   ));

   return <Container>{rendered}</Container>;
};

export default Products;
