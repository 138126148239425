export const form_en = {
   units: {
      mm: 'mm',
   },
   label: {
      addClampSet: 'Add clamp set',
      additionalBolt: 'Additional bolt',
      additionalDoorBirdMonitor: 'Additional Door-Bird monitor',
      additionalPowerSupply: 'Additional power supply',
      additionalProximityKey: 'Additional proximity key',
      additionalProximityKeyQuantity: 'Additional proximity key quantity',
      alternativeTopCarrier: 'Alternative top carrier',
      angleColor: 'Angle color',
      applicableToModel: 'Applicable to model',
      application: 'Application',
      arch: 'Arch',
      assembly: 'Assembly',
      attachments: 'Attachments',
      bftBasic: 'BFT Basic Pole',
      bftPremium: 'BFT Premium Pole',
      black_ral: 'Black - RAL9005',
      blindStopperColor: 'Blind stopper color',
      blix_small: 'Blix small',
      blix_big: 'Blix big',
      blocksCount: '{{quantity}} blocks',
      blocksWithPotCount: '{{quantity}} blocks (block + pot)',
      bottom: 'Bottom',
      boxInstallationMethod: 'Choose box installation method',
      brown: 'Brown',
      brown_8017SM: 'Brown (simmilar to 8017SM)',
      burnoutContent: 'Burn content (line 1)',
      burnoutContentOptional: 'Burn content (line 2)',
      central: 'Central',
      channelColor: 'Channel color',
      clamp: 'Clamp set',
      clampHeight: 'Clamp height',
      closingStrip: 'Closing strip',
      closingStripCustom: 'Choose custom closing strip size',
      closingStripCustomTypeConfirmation: 'Closing strip choice confirmation',
      closingStripOnAngles: 'Closing strip on angles',
      closingStripType: 'Closing-strip type',
      color: 'Color',
      color_metalInner: 'Color metal inner',
      color_metalOuter: 'Color metal outer',
      color_none: 'No color',
      color_other: 'Other Color',
      colore: 'Colore',
      concreteBoard: 'Concrete board {{value}} mm',
      confirm: 'Confirm',
      content: 'Content',
      counterweight: 'Gate total width (counterweight)',
      counterweight_confirmation: 'Counterweight confirmation',
      coverForTrolley: 'Cover for trolley',
      coveringColor: 'Covering color',
      darkGray: 'Dark-gray',
      default_male: 'Default',
      default_female: 'Default',
      degrees180: '180 degrees',
      depth: 'D dimmension (mm)',
      depth_other: 'Other D dimmension (mm)',
      destination: 'Destination',
      detachedWall: 'Detached wall',
      diameter: 'Diameter',
      dimmension_D: 'Dimmension D ($t(form:units.mm))',
      dimmension_H: 'Dimmension H ($t(form:units.mm))',
      dimmension_L: 'Dimmension L ($t(form:units.mm))',
      direction: 'Direction',
      direction_lPo: 'L/PO',
      direction_pPo: 'P/PO',
      direction_lUl: 'L/UL',
      direction_pUl: 'P/UL',
      directionConfirmation: 'Confirm',
      directlyBurnoutInPost: 'Directly burnout in post',
      directlyBurnoutInPost_colorInner: 'Choose inner plate color (outer plate is in pole color)',
      directlyBurnoutInPost_position: 'Define plate position',
      driver: 'Driver',
      duskSensor: 'Dusk sensor',
      electricHarnessForEQPost: 'Electric harness for EQ post',
      electricLock: 'Electric lock',
      equipment_over: 'Over equipment',
      equipment_under: 'Under equipment',
      equipment_inPoleColor: 'Equipment in pole color',
      equipment_inPoleColorPDF: '[Equipment in pole color]',
      expansionBolts: 'Expansion bolts',
      external: 'External',
      factoryPlateCuttingService: 'Factory plate cutting service',
      flushWithTheLowerFrame: ' Flush with the lower frame',
      foundation: 'Foundation',
      galvanized: 'Galvanized',
      gapWidthBetweenPlates: 'Gap width between plates',
      gateModel: 'Gate model',
      gateway: 'Gateway',
      gateWing: 'Gate-wing',
      gateWithoutLock: 'Gate without lock',
      gateWithoutHandrails: 'Gate without handrails',
      glued: 'Glued',
      glued180: 'Glued 180 degrees',
      graphite: 'Graphite',
      graphite_ral: 'Graphite RAL7016',
      graphite_7021SM: 'Graphite (simmilar to 7021SM)',
      handleHandle: 'Handle/handle (black)',
      handleHandrail: 'Handle/handrail (black)',
      handrailHandrail: 'Handrail/handrail (black)',
      handrail: 'Handrail',
      handrail_blackSquareLong: 'Black square handrail H 1400 mm',
      handrail_blackSquareShort: 'Square black handrail H 800 mm',
      handrail_inoxRoundLong: 'INOX round handrail H 1400 mm',
      handrail_inoxRoundShort: 'INOX round handrail H 800 mm',
      handrail_inoxSquareLong: 'INOX square handrail (stainless) H 1400 mm',
      handrail_inoxSquareShort: 'INOX square handrail (stainless) H 800 mm',
      handrail_insideGate: 'Handrail inside gate',
      handrail_nonStandard: 'Non-standard handrail',
      handrail_standardShortColorFromEstateOnly: 'Standard short colored handrail from estate only',
      handrail_standardShortColorFromStreetOnly: 'Standard short colored handrail from street only',
      handrail_standardLongColor: 'Standard long RAL colored handrail',
      handrail_standardShortColor: 'Standard short RAL colored handrail',
      handrail_stainless: 'Stainless handrail',
      handrail_stainlessShort: 'Stainless short handrail (80cm)',
      handrail_stainlessLong: 'Stainless long handrail (140cm)',
      handrail_roundBlackLong: 'Round black handrail H 1400 mm',
      handrail_roundBlackShort: 'Round black handrail H 800 mm',
      handrail_wicketWing: 'Handrail wicket-wing',
      handrail_none: 'No handrail',
      height: 'Height',
      height_other: 'Other height',
      height_gateSelfSupportingAccessStrip: 'Gate self-supporting access-strip height',
      height_gatewayClosingStrip: 'Gateway closing-strip height',
      height_singleLeafGateClosingStrip: 'Single-leaf-gate closing-strip height',
      height_span: 'Span height',
      hinged: 'Hinged',
      hinged_plural: 'Hinged',
      hingedStandard:
         "$t(form:label.hinged, {'context': 'plural'})/$t(form:label.standard, {'context': 'plural'}) (2/2)",
      hingedGlued: "$t(form:label.hinged, {'context': 'plural'})/$t(form:label.glued) (2/2)",
      hinge_180: 'Hinge $t(form:label.degrees180)',
      hinge_180Confirmation: 'Hinge $t(form:label.degrees180) confirmation',
      hinge_plural: 'Hinges',
      hinge_glued: 'Hinge glued',
      hinge_screwed: 'Hinge screwed',
      hingeType: 'Hinge type',
      holder: 'Holder',
      holder_cover: 'Holder cover',
      holder_model: 'Holder model',
      holder_polePlace: 'Holder pole place',
      holder_size: 'Holder {{size}}',
      holder_type: 'Holder type',
      illuminatedSign: 'Illuminated sign',
      illuminatedSign_location: 'Illuminated sign location',
      illuminatedSign_phrase: 'Illuminated sign phrase',
      insertionDepth: 'Insertion depth',
      integratedPlate: 'Integrated plate',
      internal: 'Internal',
      inwards: 'Inwards',
      kns_plateColor: 'KNS plate color',
      kns_plateCProfilesColor: 'Mounting channel color of KNS WALL',
      lampType: 'Lamp type',
      ledLamp: 'LED lamp',
      ledLamp_inPoleColor: 'LED lamp in pole color',
      ledLamp_inPoleColorPDF: '[LED lamp in pole color]',
      left_male: 'Left',
      left_female: 'Left',
      left_wing: 'Left',
      leftTopSpanCorner: 'Left top span corner',
      leftWing_handrail: 'Left wing handrail',
      leftWing_width: 'leftWingWidth',
      length: 'Length',
      lightGray: 'Light-gray',
      locinox: 'Locinox',
      location: 'Location',
      lockSide: 'Lock side',
      long_male: 'Long',
      long_female: 'Long',
      longClosingStrip: 'Long closing strip',
      longDefault:
         "$t(form:label.long, {'context': 'female'}) ($t(form:label.default, {'context': 'female'}))",
      mailboxDiversoConfirmation: 'Confirm, my DIVERSO fence project includes mailbox.',
      mailbox_invisible: 'Mailbox "Invisible"',
      mailbox_kns: 'Mailbox "KNS"',
      mailbox_location: 'Mailbox location',
      mailbox_NonStandardLocation: 'Maximum reduction of list box',
      mailbox_type: 'Mailbox type',
      mailboxWing: 'Mailbox wing',
      maskBetweenWings: 'Mask between wings',
      module: 'Module',
      module_color: 'Module color',
      module_colorOther: 'Other module color',
      module_height: 'Module height',
      module_plural: 'Modules',
      mountingBasesSet: 'Mounting bases set',
      mountingClamps: 'mounting clamps',
      mountingChannelColor: 'Mounting channel color',
      mountingLocation: 'Mounting location',
      mountingHoles: 'Mounting holes in pole',
      mountingStrip: 'Mounting strip',
      mountingStrip_doYouNeed: 'Do you need mounting strip for mounting span for gabion?',
      mountingStrip_amount: 'Mounting strip amount',
      mountingStrip_color: 'Mounting strip color',
      mountingStrip_colorOther: 'Other mounting strip color',
      mountingStrip_height: 'Mounting strip height',
      no: 'No',
      noBolt: 'No bolt',
      noHandrailGate: 'No handrail gate',
      none: 'None',
      nonStandard: 'Non-Standard',
      nonStandard_female: 'Non-Standard',
      nonStandard_male: 'Non-Standard',
      nonStandardDrawingRequired: '$t(form:label.nonStandard) ($t(form:label.requiresDrawing))',
      numberBurnoutInPostEquipment: 'Number burnout in post equipment',
      numberOfClamps: 'Number of clamps',
      oneKeySystem: 'One-key system',
      other_male: 'Other',
      other_female: 'Other',
      other_step5mm: 'Other ({{value}}mm step)',
      outside: 'Outside',
      padlockHandle: 'Padlock handle',
      padlockHandle_type: 'Padlock handle type',
      phrase: 'Phrase',
      plate_height: 'Plate height',
      plate_length: 'Integrated plate length',
      plate_position: 'Plate position',
      pole: 'Pole',
      poleHeight: 'Pole height',
      polePositionAgainstHolder: 'Pole position against holder',
      poles: 'Poles',
      polesInConcrete: 'Poles in concrete',
      polesOnHolder: 'Poles on holder',
      poleProfile: 'Pole profile',
      poleType: 'Pole type',
      poleWithAutomaticMachine: 'Pole With automatic machine',
      poleWithAutomaticMachine_height: 'Pole height',
      poleWithFactoryRivetNuts: 'Pole with factory rivet nuts',
      position: 'Position',
      prefabricatedPlate: 'Prefabricated plate {{value}} mm',
      preparationForAutomation: 'Preparation for automation',
      provideBurnoutContent: 'Provide burnout content',
      rawOption: 'Raw option',
      readyDimension: 'Ready dimmension',
      requiresDrawing: 'Requires drawing',
      resistanceStrip: 'Resistance strip',
      resistanceStrip_confirmation: 'Resistance strip confirmation',
      RFIDSticker: 'RFID sticker',
      RFIDStickerAmount: 'RFID sticker amount',
      right_male: 'Right',
      right_female: 'Right',
      right_wing: 'Right',
      rightWing_handrail: 'Right wing handrail',
      rightWing_width: 'Right wing width',
      rightTopSpanCorner: 'Right top span corner',
      rivetNuts: 'Rivet nuts',
      rivets_amountPerProduct: 'Define rivets amount per mounting channel',
      rivets_totalAmount: 'Rivets - total amount',
      screwed: 'Screwed',
      screwed180: 'Screwed 180 degrees',
      screwIn: 'Screw-in',
      series: 'Series',
      sheetMetalScrews: 'Sheet metal screws',
      sheetMetalWithIndividualBurnout: 'Sheet metal with individual burnout',
      sheetMetalWithIndividualBurnout_colorInner:
         'Choose inner plate color (outer plate in pole color)',
      short_male: 'Short',
      short_female: 'Short',
      shortDefault:
         "$t(form:label.short, {'context': 'female'}) ($t(form:label.default, {'context': 'female'}))",
      slabColor: 'Slab color',
      slidingGate: 'Sliding gate',
      span: 'Span',
      stainless_short: 'Stainless short (80cm)',
      stainless_long: 'Stainless long (140cm)',
      standard: 'Standard',
      standard_female: 'Standard',
      standard_male: 'Standard',
      standardGlued: "$t(form:label.standard, {'context': 'plural'})/$t(form:label.glued) (2/2)",
      standard_plural: 'Standard',
      standard_short: 'Standard short',
      standardCentral: '$t(form:label.standard) ($t(form:label.central))',
      statementContentConfirmation: 'Statement content confirmation',
      technicalIndent: 'Technical indent',
      throughIn: 'Through-in',
      toThePole: 'To the pole',
      top: 'Top',
      tousekBasic: 'TOUSEK Basic pole',
      tousekPremium: 'TOUSEK Premium pole',
      type: 'Type',
      yes: 'Yes',
      verticaleOption: 'VERTICALE option',
      wicketWing: 'Wicket-wing',
      width: 'Width',
      width_gate: 'Gate width',
      width_gateOther: 'Gate width other',
      width_gateway: 'Gateway width',
      width_gatewayOther: 'Other gateway width',
      width_other: 'Other width',
      width_gateSelfSupportingAccessStrip: 'Gate self-supporting access-strip width',
      width_gatewayClosingStrip: 'Gateway closing-strip width',
      width_singleLeafGateClosingStrip: 'Single-leaf-gate closing-strip width',
      width_span: 'Span width',
      width_wings: 'Wings width',
      windChestLength: 'Wind chest length',
      wing_left: 'Left wing',
      wing_right: 'Right wing',
      wire: 'Wire',
      wire_4: 'Wire 4/4 mm',
      wire_5: 'Wire 5/5 mm',
   },
   link: {
      eq: 'Sample equipment configurations for various pole height',
      polesInGateSelfSupporting: 'How to order poles in gate self supporting?',
      readyDimension: 'What is ready dimmension? (PDF)',
      technicalDrawing: 'Technical drawing',
      technologicalCard: 'Technological card',
   },
   validation: {
      mixed: {
         required: 'Required',
      },
      string: {
         min: 'Minimum value {{min}}',
         max: 'Maximum value {{max}}',
         email: 'Incorrect email address',
         handrailSelection: 'The given height cannot be lower than the selected handrail',
      },
      number: {
         min: 'Minimal value {{min}}',
         max: 'Maximum value {{max}}',
         integer: 'The value should be an integer',
         dividedBy: 'The value should be divided by {{dividedBy}}',
         moreThan: 'The value should be greater than {{more}}',
         lessThan: 'The value should be less than {{less}}',
      },
   },
   placeholder: {
      select: 'Select',
      select_channelColor: 'Select channel color',
      select_mountingChannelColor: 'Select mounting channel color',
      select_moduleColor: 'Select module color',
   },
   description: {
      headline: {
         usage: 'Usage:',
         remember: 'Remember:',
      },
      content: {
         automaticPoleWithGateSelfSupporting:
            'This automatic pole is associated with the product "Self-Supporting Gate" located under the number: {{productOrder}}.',
         closingStrips: 'Check standard closing strips for gate models',
         diversoStrengthening:
            'All projects in <strong>DIVERSO</strong> model containing shape 20x20mm in diameter will be enhenced with flat bar in center. Flat bar is applied from 1500 mm in width and refers to spans and filling in self-supporting gates and gates-tilting.',
         foldingGate: {
            additionalOptions: 'Handrail inside gate is standard option in folding-gates.',
         },
         gateSelfSupportingWithAutomaticPole:
            'This self-supporting gate is associated with the "Automatic pole" product located under the number: {{productOrder}}.',
         p302: 'Color chosen in field “Choose color of your gate” refers to structural elements of gate and gateway.',
      },
   },
   explanation: {
      asymmetricGates: {
         message:
            'Left wing - {{leftWingWidthValue}} cm, right - {{rightWingWidthValue}} cm. total: {{wingsWidth}} cm.',
         info: 'Gate width is sum of rounded left and right wing width.',
      },
      blockPoles: {
         blocks:
            "Pole height is multiple blocks with dimmensions 600x296x300 mm arranged upright (one on top the other). Durable connection between blocks. Block doesn't include roof.",
         blocksWithPots:
            'Pole height is multiple blocks with dimmensions 600x296x300 mm arranged upright (one on top the other). Durable connection between blocks. Pot on pole top. Pot filler and basis in set.',
      },
      closingStrips: {
         customTypeConfirmation: 'Selected strip type is not default for given model.',
      },
      colore: 'Colore cannot be the same color as main order color.',
      connectorsForXPSConstruction: {
         height: 'Possible height for mounting channel: 301 mm - 2510 mm',
         totalNumberOfRivets: 'Rivets are quantity multiple of current product: {{quantity}}.',
      },
      direction: 'Define direction standing on the street looking at estate.',
      directionInformation: 'Openning gateway outside information. ',
      foldingGate: {
         height: 'Gate wing should be installed on gate poles with 60 mm distance from bench.',
         width: '$t(form:explanation.gateWidth, {{widthInCm}}) In folding gate there is possible only inward wings direction.',
      },
      functionalPoles: {
         additionalPowerSupply:
            'Additional power supply operating up to 5 BLIX lamps (SMALL/BIG) simultaneously',
         duskSensor:
            'For BLIX lamp with dusk sensor it is recommended additional power supply purchase.',
         equipment_over:
            'Pole burnout will be placed in the middle between upper pole edge (or LED lamp) and upper pole equipment edge.',
         equipment_under:
            'Pole burnout will be placed 40 mm from bottom edge of chosen pole EQ equipment.',
         ledLamp:
            'LED lamp changes minimal pole height. Make sure that provided height is correct.',
         mailboxLocation:
            'Provide mailbox height in mm from the pole basis to mailbox bottom edge.',
      },
      handrail: {
         length: 'Handrail length: {{lengthValue}} cm.',
      },
      holder: {
         type: 'if other type selected adding technical drawing is necessary.',
         cover: 'Attention: No cover option for holder on gate-width {{value}} mm',
         cover_asymmetricGate:
            'Attention: no holder cover option if bigger wing-width {{value}} mm.',
         polePlace: 'Standard (central). Non-standard (drawing required).',
      },
      mailbox_color: 'Mailbox is in span color by default. ',
      mailbox_position: 'Your mailbox will be at {{mailboxPosition}} mm height (placed central).',
      module: {
         info: "Provide module height between 300-1000 cm. Remember: module can't be taller than gates total height.",
         value: 'Gate module height: {{value}} cm.',
      },
      other: {
         appliedGalvanized: 'Applied Galvanized.',
      },
      others: {
         mailboxLocation: 'Mailbox location evaluate standing on street looking at fencing',
      },
      poleWithAutomaton: {
         withGateSelfSupporting: 'Pole height equals gate self-supporting + 100mm',
         withoutGateSelfSupporting:
            'Provide height of gate self-supporting for wich pole is dedicated. In order will be added +100 mm height to pole with automaton.',
      },
      preparationForAutomation:
         'Preparation for automation available for gates taller than 1000 mm.',
      required: 'Value required',
      charge_extra: 'Extra charge {{extraCharge}}%',
      charge_none: 'No extra charge.',
      gateHeight: 'Your gate will have {{heightInCm}} cm height. ',
      gateHeight_module: 'Gate should be heigher than module.',
      gateWidth: 'Your gate will have {{widthInCm}} cm width. ',
      gatewayHeight: 'Your gateway will have {{heightInCm}} cm height. ',
      gatewayWidth: 'Your gateway will have {{widthInCm}} cm width. ',
      gatewayLongerThan1500: 'In case of gateway longer than 1500 mm use single-leaf gate form.',
      heightAttention: 'Attention: Gate has more than {{value}} mm in height.',
      heightAttention_span:
         'Attention: {{model}} cannot be taller than 1440 mm. If width less than 1440 mm maximum height can be 2000 mm.',
      hinge180Confirmation: 'Attention, hinge 180 usage is not recommended by producer.',
      mailboxAvailablity_invisible:
         'Mailbox "Invisible" available in minimal height: {{value}} mm.',
      mailboxAvailablity_kns: 'Mailbox "KNS" available in minimal height: {{value}} mm.',
      noHandrail: 'Short handrail inside gate is standard option for folding gates.',
      nonStandardSize: 'Non standard size is extra paid.',
      resistanceStrip: 'Resistance strip choice means lack of lock in gate.',
      resistanceStrip_gateSelfSupporting: 'Unavailable, if one-key system selected.',
      roundValue: 'Your value will be rounded to {{value}} cm.',
      spacing: 'Spacing for that height: {{spacing}} mm.',
      span: {
         height: 'Elements can be up to 10 mm heigher than order height.',
      },
      statementContent: 'Statement content',
      wicketGate: {
         holder_model: 'Attention! Match holder to wider wing',
         poles: 'Attention! Match poles to wider wing',
         diameter:
            'Diameter left-wing and right-wing is based on rounded wing values. Gate diameter: left - {{leftWingWidthValue}} cm, right - {{rightWingWidthValue}} cm. Total: {{wingsWidth}} cm. ',
      },
      width_gatewayOther: 'Other gateway width',
      wingWidth_left: 'Left gate wing width will be {{value}} cm. ',
      wingWidth_right: 'Right gate wing width will be {{value}} cm. ',
      wingWidth_min: 'Minimal wing diameter: 1 cm. Maximum wing diameter: {{max}} cm.',
   },
   message: {
      addAutomaticPoleError: 'Failed to add bollard with vending machine. Try again.',
      gateSelfSupportingSlidingGateValidation: 'No associated sliding gate in the order.',
      gateSelfSupportingWithAutomaticPoleValidation:
         'The cost estimate for a self-supporting gate with the "Automatic post" option must include a separate "Automatic post" product. Use the button "add a post with a drive" to place it in the cost estimate.',
      modelNotServed: 'Model not served',
   },
   tooltip: {
      alt: {
         asymmetricGate_width: 'Asymmetric-gate width',
         blixLampPole: 'Blix lamp Pole with Blix-{{type}} lamp',
         colore: 'Colore',
         direction: 'Direction',
         height: 'Height',
         height_pole: 'Pole height',
         width: 'Width',
      },
   },
   section: {
      title: {
         additionalOptions: 'Additional options',
         attachments: 'Attachments',
         assembly: 'Assembly',
         automation: 'Automation/Locks/Gate posts',
         automationAndPoles: 'Automaton and poles',
         basicInformation: 'Basic information',
         burnout: 'Burnout',
         handrail_plural: 'Handrails',
         hinge_plural: 'Hinges',
         holder: 'Holder',
         illuminatedSign: 'Illuminated sign',
         lock_plural: 'Locks',
         mailbox_plural: 'Mailboxes',
         module: 'Module',
         mountingStrip: 'Mounting strip',
         padlockHandle: 'Padlock handle',
         pole_plural: 'Poles',
      },
   },
   button: {
      addAutomaticPole: 'Add automatic pole',
      addGateSelfSupporting: 'Add a second self-supporting gate',
      technicalCutting: 'Make technical cutting drawing',
      modelsInfo: 'Models info ',
      fold: '(fold)',
      unfold: '(unfold)',
   },
   table: {
      fold: 'Fold',
      unfold: 'Unfold',
      locks: {
         assortment: 'Assortment',
         allAvailableModels: 'All available models',
         destination: 'Destination',
         fenceModel: 'Fence model',
         gateTilting_plural: 'Gates-tilting',
         gateSelfSupporting_plural: 'Gates-self-supporting',
         gateLock: 'Gate lock',
         gate_plural: 'Gates',
         highwayWickets: 'Highway wickets',
         insertAndKey: 'Brass insert + nickel, brass key + nickel',
         upTo: 'up to',
         wicket_plural: 'Wickets',
         wicketLock: 'Wicket lock',
         wicketWithoutElectricLock: 'Wickets without electromagnetic lock',
      },
      mountingBrackets: {
         model: 'Model',
         mountingBracketType: 'Mounting bracket type',
         comments: 'Comments',
         description: 'Description',
         mountingGrip_long: 'Mounting grip - long',
         mountingGrip_short: 'Mounting grip - short',
         noMountingBrackets: 'No mounting brackets',
      },
   },
};
