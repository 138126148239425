import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import styled from 'styled-components';

export const ZamowieniaLink = ({ displayId, zamowieniaId }) => (
   <Pdf
      href={`https://zamowienia.konsport.com.pl/pl/zamowienia/pdf/${zamowieniaId}/zamowienie/false`}
      target="_blank"
      rel="noopener noreferrer"
   >
      <FontAwesomeIcon icon={faFilePdf} />
      <span>PDF{displayId ? ` ${displayId}` : ''}</span>
   </Pdf>
);

const OpenZamowieniaPdfForm = ({ className }) => {
   const [id, setId] = useState('');

   const ref = useRef();

   const open = (e) => {
      if (!id) {
         e.preventDefault();
      }
      setTimeout(() => setId(''));
   };

   const submit = (e) => {
      e.preventDefault();
      if (id) {
         ref.current.click();
      }
   };

   return (
      <form onSubmit={submit} className={className}>
         <div>
            <Label htmlFor="id_zamowienia_dla_pdf">
               Id zamówienia dla PDF
               <Input
                  type="text"
                  id="id_zamowienia_dla_pdf"
                  className="form-control"
                  value={id}
                  onChange={(e) => setId(e.currentTarget.value)}
                  placeholder="Id zamówienia dla PDF"
                  data-not-scanned
               />
            </Label>
            <Link
               className="btn btn-danger btn-icon"
               href={`https://zamowienia.konsport.com.pl/pl/zamowienia/pdf/${id}/zamowienie/false`}
               target="_blank"
               onClick={open}
               ref={ref}
            >
               <FontAwesomeIcon icon={faFilePdf} />
               Otwórz PDF w nowej karcie
            </Link>
         </div>
      </form>
   );
};

const Label = styled.label`
   margin-bottom: 0;
`;

const Input = styled.input`
   width: 200px;
`;

const Link = styled.a`
   margin-left: 1.5rem;
`;

const Pdf = styled.a`
   span {
      margin-left: 0.5rem;
   }
`;

export default OpenZamowieniaPdfForm;
