// eslint-disable-next-line import/no-extraneous-dependencies
import 'normalize.css';

import { ThemeProvider as ThemeProviderBase } from 'styled-components';

import { createTheme } from 'designSystem/theme/theme';

import { GlobalStyle } from './GlobalStyle';
import { Props } from './ThemeProvider.types';

export const ThemeProvider = ({ children, defaultUnit = 'rem' }: Props): JSX.Element => {
   return (
      <ThemeProviderBase theme={createTheme(defaultUnit, 'light')}>
         <GlobalStyle />
         {children}
      </ThemeProviderBase>
   );
};
