import styled from 'styled-components';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { STATUS_PACKED_DELIVERING_TO_PACKING_PLACE } from 'Shared/Warehouse/OrderPart';
import { paletteTypes } from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';
import { CompanyName } from 'Shared/Warehouse/Warehouse';

import BackToWarehouseButton from './BackToWarehouseButton';
import PackedButton from './PackedButton';
import SentButton from './SentButton';

const Group = ({ group }) => {
   const palettes = group.palettes.map((p) => {
      const products = p.orderParts.map((op) => {
         return <Products key={op.id} products={op.products} />;
      });
      const ordersPublicIds = p.orderParts.map((op) => {
         const place =
            op.warehouseStatus === STATUS_PACKED_DELIVERING_TO_PACKING_PLACE
               ? 'ze strefy'
               : 'z lakierni';
         return (
            <li key={op.id}>
               <ZamowieniaLink
                  displayId={op.order.zamowieniaId}
                  zamowieniaId={op.order.zamowieniaId}
               />{' '}
               {place}
            </li>
         );
      });
      const hooks = p.orderParts.map((op) => {
         return op.order.hooks.map((h) => {
            return <li key={h.name}>{h.name}</li>;
         });
      });
      return (
         <tr key={p.publicId}>
            <td>
               <PaletteCol>
                  <span>
                     <b>Id:</b> {p.publicId}
                  </span>
                  <span>
                     <b>Typ:</b> {paletteTypes[p.type]}
                  </span>
               </PaletteCol>
            </td>
            <td>
               <ul className="list-unstyled">{hooks}</ul>
            </td>
            <td>
               <ul className="list-unstyled">{ordersPublicIds}</ul>
            </td>
            <td>{products}</td>
         </tr>
      );
   });

   return (
      <tr>
         <td>{group.publicId}</td>
         <td>
            <CompanyName
               orderPartsGroup={group}
               createdByExternalClient={group.createdByExternalClient}
            />
         </td>
         <td>
            <table className="table table-hover table-sm">
               <thead>
                  <tr>
                     <th>Paleta</th>
                     <th>Wieszaki</th>
                     <th>Zamówienia / Części zamówień</th>
                     <th>Produkty</th>
                  </tr>
               </thead>
               <tbody>{palettes}</tbody>
            </table>
         </td>
         <td>
            <PackedButton group={group} />
            <SentButton group={group} />
            <BackToWarehouseButton group={group} />
         </td>
      </tr>
   );
};

const PaletteCol = styled.div`
   display: flex;
   flex-direction: column;
   gap: 1rem;
`;

export default Group;
