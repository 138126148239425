import axios from 'axios';
import { useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';

import InternalContainer from 'Layouts/InternalContainer';
import defaults from 'Shared/useQuery';
import FinalPaletteModal from 'Shared/Warehouse/FinalPaletteModal/FinalPaletteModal';

import { Context } from './Context';
import ForPacking from './ForPacking/ForPacking';
import ForSending from './ForSending/ForSending';

const WarehousePacking = () => {
   const [groupsForPacking, setGroupsForPacking] = useState([]);
   const [groupsForPackingForSending, setGroupsForPackingForSending] = useState([]);
   const [groupForFinalPaletteModal, setGroupForFinalPaletteModal] = useState(null);
   const { refetch: refetchForPacking, dataUpdatedAt: updatedAtForPacking } = useQuery(
      ['fetchGroupsForPacking'],
      () => {
         return axios.get('/warehouse/groups-for-packing');
      },
      {
         ...defaults,
         onSuccess: ({ data }) => {
            setGroupsForPacking(data.groups);
         },
      },
   );
   const { refetch: refetchForPackingForSending, dataUpdatedAt: updatedAtForPackingForSending } =
      useQuery(
         ['fetchGroupsForPackingForSending'],
         () => {
            return axios.get('/warehouse/groups-for-packing-for-sending');
         },
         {
            ...defaults,
            onSuccess: ({ data }) => {
               setGroupsForPackingForSending(data.groups);
            },
         },
      );
   const value = useMemo(
      () => ({ refetchForPacking, refetchForPackingForSending, setGroupForFinalPaletteModal }),
      [refetchForPacking, refetchForPackingForSending, setGroupForFinalPaletteModal],
   );

   return (
      <Context.Provider value={value}>
         <Helmet>
            <title>Magazyn &gt; Pakowanie na strefę/na gotowo | Konsport</title>
         </Helmet>
         <InternalContainer>
            <ForPacking groups={groupsForPacking} updatedAt={updatedAtForPackingForSending} />
            <ForSending groups={groupsForPackingForSending} updatedAt={updatedAtForPacking} />
         </InternalContainer>
         {groupForFinalPaletteModal && (
            <FinalPaletteModal
               group={groupForFinalPaletteModal}
               close={() => setGroupForFinalPaletteModal(null)}
               refetch={() => {
                  refetchForPacking();
                  refetchForPackingForSending();
               }}
            />
         )}
      </Context.Provider>
   );
};

export default WarehousePacking;
