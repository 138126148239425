import axios from 'axios';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import defaults from 'Shared/useQuery';
import UseQueryStates from 'Shared/UseQueryStates';
import ShowHideButton from 'Shared/Warehouse/ShowHideButton';

import { Context } from './Context';
import Group from './Group';

const fetchPacked = ({ companyName }) =>
   axios.get('/warehouse/groups/packed-for-manager', {
      params: {
         company_name: companyName,
      },
   });

const Packed = () => {
   const [render, setRender] = useState(true);
   const filters = useSelector((s) => s.warehouse.filters);
   const [groups, setGroups] = useState([]);
   const { error, dataUpdatedAt, isLoading, refetch } = useQuery(
      ['fetchPacked', { companyName: filters.companyName }],
      () => fetchPacked({ companyName: filters.companyName }),
      {
         ...defaults,
         onSuccess: ({ data }) => {
            setGroups(data.groups);
         },
      },
   );
   const rendered = render && groups.map((g) => <Group key={g.id} group={g} />);
   const value = useMemo(
      () => ({
         refetch,
      }),
      [refetch],
   );

   return (
      <Context.Provider value={value}>
         <Container>
            <div>
               <h2 className="h3">
                  Grupy do spakowania | Spakowane
                  <ShowHideButton render={render} onClick={() => setRender((p) => !p)} />
               </h2>
            </div>
            <table className="table table-hover">
               <thead>
                  <tr>
                     <th>Grupa</th>
                     <th>Klient</th>
                     <th>Gdzie</th>
                     <th>Kiedy</th>
                     <th>Palety</th>
                     <th />
                  </tr>
               </thead>
               <tbody>{rendered}</tbody>
            </table>
            <UseQueryStates error={error} updatedAt={dataUpdatedAt} isLoading={isLoading} />
         </Container>
      </Context.Provider>
   );
};

const Container = styled.div`
   margin-top: 2rem;
`;

export default Packed;
