import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { colors } from 'deprecatedTheme/colors';
import { Response } from 'Shared/Response';

const extractIdFromLongId = (longId) => {
   const split = longId.toString().split('/');

   // ESLINT_WARN_TO_BE_FIXED
   // eslint-disable-next-line radix
   return split[0] ? parseInt(split[0]) : parseInt(longId);
};

const Form = ({ refetch, loading, setLoading }) => {
   const dispatch = useDispatch();
   const filters = useSelector((state) => state.warehouse.filters);
   const [info, setInfo] = useState(null);
   const [error, setError] = useState(false);
   const [confirmationInfo, setConfirmationInfo] = useState(null);
   const [confirmationError, setConfirmationError] = useState(false);
   const [id, setId] = useState(null);
   const [order, setOrder] = useState(null);
   const [scannedChars, setScannedChars] = useState('');
   const ref = useRef();
   const save = useCallback(
      (type) => {
         setError(false);
         setInfo(null);
         setLoading(true);
         if (loading) return;
         const getId = () => {
            // user can paste an id and hit enter, we need to catch this
            const scannedId = extractIdFromLongId(scannedChars);
            switch (type) {
               case 'TYPE_SCANNED':
                  return scannedId || id;
               case 'TYPE_MANUAL':
                  return id;
               default:
                  throw new Error();
            }
         };
         const longId = getId();
         if (!longId) {
            setLoading(false);
            setScannedChars('');
            return;
         }
         axios
            .get(`/zamowienia/${longId}`)
            .then(({ data }) => setOrder(data))
            .catch((err) => {
               if (!err.response?.status) {
                  Sentry.captureException(err);
               }
               setError(true);
               setId(null);
               setScannedChars('');
               setInfo(err.response?.data?.message ?? 'Wystąpił błąd. Spróbuj jeszcze raz.');
            })
            .finally(() => setLoading(false));
      },
      [dispatch, id, loading, scannedChars, setLoading, filters],
   );
   const submit = (e) => {
      e.preventDefault();
      save('TYPE_MANUAL');
   };
   const add = () => {
      setConfirmationError(false);
      axios
         .post('/production/orders/check-in', {
            zamowieniaId: order.id,
         })
         .then(() => {
            setOrder(null);
            setConfirmationInfo(`Zamówienie o id: "${order.id}" dodane.`);
            setId(null);
            setScannedChars('');
            refetch();
         })
         .catch((err) => {
            if (!err.response?.status) {
               Sentry.captureException(err);
            }
            setError(true);
            setConfirmationError(true);
            setId(null);
            setScannedChars('');
            setConfirmationInfo(
               err.response?.data?.message ?? 'Wystąpił błąd. Spróbuj jeszcze raz.',
            );
         })
         .finally(() => setLoading(false));
   };
   const cancel = () => {
      setOrder(null);
      setId(null);
      setInfo(null);
      setConfirmationError(false);
      setConfirmationInfo(null);
      setScannedChars('');
   };
   useEffect(() => {
      const handleKeyDown = (e) => {
         if (
            e.key === 'Enter' &&
            e.target !== ref.current &&
            !e.target.getAttribute('data-not-scanned') // to prevent submit it by other forms
         ) {
            if (loading) {
               setScannedChars('');
            } else {
               save('TYPE_SCANNED');
            }
         } else {
            setScannedChars((chars) => (chars ? chars + e.key : e.key));
         }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => document.removeEventListener('keydown', handleKeyDown);
   }, [ref, loading, save]);

   return (
      <Container onSubmit={submit}>
         <PreRequestForm>
            <Row>
               <Label>
                  Id zamówienia
                  <Input
                     type="text"
                     className="form-control"
                     value={id || ''}
                     onChange={(e) => setId(extractIdFromLongId(e.target.value))}
                     placeholder="Id zamówienia"
                     ref={ref}
                     disabled={order}
                     // eslint-disable-next-line jsx-a11y/no-autofocus
                     autoFocus
                  />
               </Label>
               <Button
                  type="button"
                  className={`btn ${order ? 'btn-success' : 'btn-danger'}`}
                  onClick={submit}
                  disabled={order}
               >
                  <FontAwesomeIcon icon={faPlus} />
                  Dodaj
               </Button>
            </Row>
            <Response error={error} messages={[info]} />
         </PreRequestForm>
         {order && (
            <Confirmation>
               <ConfirmationForm>
                  <ConfirmationData>
                     <span>Id: {order.id}</span>
                     <span>Klient: {order.companyName}</span>
                  </ConfirmationData>
                  <ActionsContainer>
                     {!error && !confirmationError && (
                        <ConfirmButton type="button" className="btn btn-danger" onClick={add}>
                           <FontAwesomeIcon icon={faCheck} />
                           Potwierdzam
                        </ConfirmButton>
                     )}
                     <Button type="button" className="btn" onClick={cancel}>
                        <FontAwesomeIcon icon={faTimes} />
                        Anuluj
                     </Button>
                  </ActionsContainer>
               </ConfirmationForm>
               <ResponseStyled error={confirmationError} messages={[confirmationInfo]} />
            </Confirmation>
         )}
      </Container>
   );
};

const Label = styled.label`
   color: #000000;
   margin-bottom: 0 !important;
   margin-right: 1rem;
`;

const PreRequestForm = styled.div`
   display: flex;
   flex-direction: column;
   margin-right: 2rem;
`;

const Row = styled.div`
   display: flex;
   align-items: flex-end;
`;

const Input = styled.input`
   width: 200px;
   font-size: 1.4rem;
`;

const ConfirmButton = styled.button`
   padding: 0.35rem 1rem 0.35rem 1rem;
   font-size: 1.4rem;
   .svg-inline--fa {
      margin-right: 1rem;
   }
`;

const Button = styled.button`
   padding: 0.35rem 1rem 0.35rem 1rem;
   font-size: 1.4rem;
   margin-right: 1rem;
   .svg-inline--fa {
      margin-right: 1rem;
   }
`;

const ResponseStyled = styled(Response)`
   margin-top: 1rem;
   font-size: 1.4rem;
`;

const Confirmation = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 2rem;
`;

const ConfirmationForm = styled.div`
   display: flex;
   flex-direction: column;
`;

const ConfirmationData = styled.div`
   display: flex;
   justify-content: space-between;
   font-size: 1.8rem;
   color: ${colors.green};
`;

const ActionsContainer = styled.div`
   display: flex;
   justify-content: space-between;
`;

const Container = styled.form`
   position: relative;
   display: flex;
`;

export default Form;
