import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Response } from 'Shared/Response';

import { Context } from '../Context';

import CreatedByInternalClientGroupAction from './CreatedByInternalClientGroupAction';

const initialValues = {};

const create = (group) => axios.post('/warehouse/groups', group);

const CreatedByInternalClientActions = ({ order, orderPart }) => {
   const dispatch = useDispatch();
   const [showGroups, setShowGroups] = useState(false);
   const [error, setError] = useState(false);
   const [info, setInfo] = useState(null);
   const { refetch } = useContext(Context);
   const formik = useFormik({
      initialValues,
      onSubmit: () => {
         const id = uuidv4();
         const opg = {
            id,
            orderParts: [
               {
                  ...orderPart,
               },
            ],
         };
         create(opg)
            .then(() => {
               dispatch({ type: 'CREATE_ORDER_PARTS_GROUP', groupId: opg.id });
               refetch();
            })
            .catch((err) => {
               if (!err.response?.status) {
                  Sentry.captureException(err);
               }
               setError(true);
               setInfo(err.response?.data?.message ?? 'Wystąpił błąd. Spróbuj jeszcze raz.');
            });
      },
   });

   return (
      <Container>
         <BtnsContainer>
            <CreatedByInternalClientGroupAction
               order={order}
               orderPart={orderPart}
               showGroups={showGroups}
               setShowGroups={setShowGroups}
            />
            <div>
               <div>
                  <button
                     type="button"
                     className="btn btn-success"
                     onClick={formik.submitForm}
                     disabled={formik.isSubmitting}
                  >
                     Utwórz nową grupę
                  </button>
               </div>
               <Response error={error} messages={[info]} />
            </div>
         </BtnsContainer>
      </Container>
   );
};

const Container = styled.div`
   display: flex;
   flex-direction: column;
   min-width: 410px;
`;

const BtnsContainer = styled.div`
   display: flex;
   justify-content: space-between;
`;

export default CreatedByInternalClientActions;
