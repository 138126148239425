import axios from 'axios';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import InternalContainer from 'Layouts/InternalContainer';
import defaults from 'Shared/useQuery';
import UseQueryStates from 'Shared/UseQueryStates';

import { Context } from './Context';
import ExportButton from './ExportButton';
import Orders from './Orders/Orders';

const fetchOrders = () => {
   return axios.get('/paint-shop-painting/orders');
};

const PaintShopPainting = () => {
   const dispatch = useDispatch();
   const { isLoading, dataUpdatedAt, error, refetch } = useQuery(
      ['fetchOrders'],
      () => fetchOrders(),
      {
         ...defaults,
         onSuccess: ({ data }) => {
            dispatch({
               type: 'FETCH_PAINT_SHOP_PAINTING_ORDERS',
               orders: data.orders,
            });
         },
      },
   );

   const value = useMemo(() => ({ refetch }), [refetch]);

   return (
      <Context.Provider value={value}>
         <Helmet>
            <title>Napylanie | Konsport</title>
         </Helmet>
         <InternalContainer>
            <div className="row">
               <div className="col">
                  <div>
                     <h1>Napylanie</h1>
                     <ExportButton />
                  </div>
                  <Orders />
                  <UseQueryStates error={error} updatedAt={dataUpdatedAt} isLoading={isLoading} />
               </div>
            </div>
         </InternalContainer>
      </Context.Provider>
   );
};

export default PaintShopPainting;
