import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
   *, ::after, ::before {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
   }

   html {
      font-family: 'Open Sans', sans-serif;
      line-height: ${({ theme: { lineHeight } }) => lineHeight};
   }

   body {
      background: ${({ theme: { palette } }) => palette.background.paper.body};
   }

   &:focus {
      outline-style: solid;
      outline-color: ${({ theme: { palette } }) => palette.outline};
      outline-width: 2px;
      outline-offset: 2px;
   }
`;
