import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as dayjs from 'dayjs';
import { useState } from 'react';
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import CloseCalendarButton from 'Shared/CloseCalendarButton';
import { filterChanged } from 'state/warehouse';

const StartedPaintingAt = () => {
   const dispatch = useDispatch();
   const value = useSelector((s) => s.warehouse.filters.importedAt);
   const [show, setShow] = useState(false);
   const onChange = (d) => {
      dispatch(filterChanged('importedAt', dayjs(d).toDate()));
   };
   return (
      <Container>
         <div>
            <Label>
               <LabelIcon icon={faClock} />
               <LabelContent>Dzień wprowadzenia</LabelContent>
               <Input
                  type="text"
                  className="form-control"
                  value={value ? dayjs(value).format('DD-MM-YYYY') : ''}
                  onFocus={() => setShow(true)}
                  readOnly
               />
            </Label>
         </div>
         {show && (
            <div>
               <CloseCalendarButton setShow={setShow} />
               <Calendar onChange={onChange} value={value} className="sm" locale="pl" />
            </div>
         )}
      </Container>
   );
};

const Container = styled.div`
   width: 320px;
`;

const Label = styled.label`
   width: 100%;
   margin-bottom: 1rem;
`;

const LabelIcon = styled(FontAwesomeIcon)`
   margin-right: 0.5rem;
`;

const LabelContent = styled.span`
   margin-right: 2rem;
`;

const Input = styled.input`
   cursor: pointer;
`;

export default StartedPaintingAt;
