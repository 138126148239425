import update from 'immutability-helper';

export const openSplitOrderModal = (orderId) => ({
   type: 'OPEN_SPLIT_ORDER_MODAL',
   orderId,
});

const SET_ERRORS_IN_SPLIT_ORDER_MODAL = 'SET_ERRORS_IN_SPLIT_ORDER_MODAL';
export const setErrorsInSplitOrderModal = (errors) => ({
   type: SET_ERRORS_IN_SPLIT_ORDER_MODAL,
   payload: {
      errors,
   },
});

export const CLOSE_SPLIT_ORDER_MODAL = 'CLOSE_SPLIT_ORDER_MODAL';
export const closeSplitOrderModal = () => ({
   type: CLOSE_SPLIT_ORDER_MODAL,
});

export const WAREHOUSE_ORDER_CHANGED = 'WAREHOUSE_ORDER_CHANGED';
export const orderChanged = (order) => ({
   type: WAREHOUSE_ORDER_CHANGED,
   payload: {
      order,
   },
});

export const ORDERS_GROUP_CHANGED = 'ORDERS_GROUP_CHANGED';
export const ordersGroupChanged = (ordersGroup) => ({
   type: ORDERS_GROUP_CHANGED,
   payload: {
      ordersGroup,
   },
});

export const filterChanged = (key, value) => ({
   type: 'FILTER_CHANGED',
   payload: {
      key,
      value,
   },
});

export const FILTERS_CHANGED = 'FILTERS_CHANGED';
export const filtersChanged = (filters) => ({
   type: FILTERS_CHANGED,
   payload: filters,
});

export const wareHouseFiltersInitialState = {
   companyName: '',
   zamowieniaId: '',
   accountManager: '',
   startedPaintingAt: '',
   startedPaintingAtRange: null,
   orderPartsGroupStatus: '',
   packingPlace: '',
};

export const wareHouseInitialState = {
   orderGroups: [],
   orderGroupsCursor: {
      limit: 10000,
      offset: 0,
      more: true,
   },
   groups: [],
   groupsPackedIntoWarehouse: [],
   groupsToBeSend: [],
   groupsSent: [],
   orders: [],
   filters: wareHouseFiltersInitialState,
   clients: [],
   autoFetch: true,
   splitOrderModal: null,
   planing: null,
   orderPartsGroupIdToScroll: null,
   orderPartsGroupsToBePacked: [],
   orderPartsGroupsPackedIntoWarehouse: [],
   paintShopStatistics: null,
   groupsToBeDelivered: [],
   hooks: [],
};

// eslint-disable-next-line default-param-last
const warehouse = (state = wareHouseInitialState, action) => {
   switch (action.type) {
      case WAREHOUSE_ORDER_CHANGED:
         // ESLINT_WARN_TO_BE_FIXED
         // eslint-disable-next-line no-case-declarations
         const woch__ordersIndex = state.orders.findIndex((o) => o.id === action.payload.order.id);

         return update(state, {
            orders: {
               [woch__ordersIndex]: { $set: action.payload.order },
            },
         });
      case 'WAREHOUSE_ORDER_REMOVED':
         // ESLINT_WARN_TO_BE_FIXED
         // eslint-disable-next-line no-case-declarations
         const wor__ordersIndex = state.orders.findIndex((o) => o.id === action.payload.orderId);

         return update(state, {
            orders: { $splice: [[wor__ordersIndex, 1]] },
         });
      case 'WAREHOUSE_ORDERS_FETCHED':
         return update(state, {
            orders: { $set: action.payload.orders },
         });
      case 'PAINT_SHOP_FETCHED_ORDERS':
         return {
            ...state,
            orders: action.data,
         };
      case 'PAINT_SHOP_HOOKS_FETCHED':
         return {
            ...state,
            hooks: action.data,
         };
      case ORDERS_GROUP_CHANGED:
         // ESLINT_WARN_TO_BE_FIXED
         // eslint-disable-next-line no-case-declarations
         const ogch__orderGroupsIndex = state.orderGroups.findIndex(
            (og) => og.id === action.payload.ordersGroup.id,
         );

         return update(state, {
            orderGroups: {
               [ogch__orderGroupsIndex]: { $set: action.payload.ordersGroup },
            },
         });
      case 'ADD_ORDER_PART_TO_ORDER_PARTS_GROUP':
         return {
            ...state,
            orderPartsGroupIdToScroll: action.ordersGroupId,
            planing: null,
         };
      case FILTERS_CHANGED:
         return {
            ...state,
            filters: action.payload,
         };
      case 'FILTER_CHANGED':
         if (action.payload.key === 'startedPaintingAt') {
            return update(state, {
               filters: {
                  [action.payload.key]: { $set: action.payload.value },
                  startedPaintingAtRange: { $set: null },
               },
            });
         }
         if (action.payload.key === 'startedPaintingAtRange') {
            return update(state, {
               filters: {
                  [action.payload.key]: { $set: action.payload.value },
                  startedPaintingAt: { $set: null },
               },
            });
         }
         return update(state, {
            filters: {
               [action.payload.key]: { $set: action.payload.value },
            },
         });
      case 'CHANGE_FILTER':
         return {
            ...state,
            filters: {
               ...state.filters,
               [action.key]: action.value,
            },
         };
      case 'ORDER_GROUPS_READY_TO_TAKE_EXTRA_ORDERS_FETCHED':
         return {
            ...state,
            orderGroupsReadyToTakeExtraOrders: action.payload.orderGroups,
         };
      case 'OPEN_SPLIT_ORDER_MODAL':
         return {
            ...state,
            splitOrderModal: {
               orderId: action.orderId,
               errors: [],
            },
            autoFetch: false,
         };
      case SET_ERRORS_IN_SPLIT_ORDER_MODAL:
         return {
            ...state,
            splitOrderModal: {
               ...state.splitOrderModal,
               errors: action.payload.errors,
            },
         };
      case CLOSE_SPLIT_ORDER_MODAL:
         return update(state, {
            splitOrderModal: { $set: null },
            autoFetch: { $set: true },
         });
      case 'RESET_FILTERS':
         return {
            ...state,
            filters: action.filters ? action.filters : wareHouseFiltersInitialState,
         };
      case 'FETCH_CLIENTS':
         return {
            ...state,
            clients: action.clients,
         };
      case 'START_PLANING':
         return {
            ...state,
            planing: {
               orderPart: action.orderPart,
               order: action.order,
            },
            filters: {
               ...state.filters,
               companyName: action.companyName,
            },
         };
      case 'CANCEL_PLANING':
         return {
            ...state,
            planing: null,
            filters: {
               ...state.filters,
               companyName: '',
            },
         };
      case 'CREATE_ORDER_PARTS_GROUP':
         return {
            ...state,
            planing: null,
            orderPartsGroupIdToScroll: action.groupId,
         };
      case 'SCREEN_WAS_SCROLLED_TO_ORDER_PARTS_GROUP':
         return {
            ...state,
            orderPartsGroupIdToScroll: null,
         };
      case 'ORDER_PARTS_GROUPS_TO_BE_PACKED_FETCHED':
         return {
            ...state,
            orderPartsGroupsToBePacked: action.data,
         };
      case 'ORDER_PARTS_GROUPS_PACKED_INTO_WAREHOUSE_FETCHED':
         return {
            ...state,
            orderPartsGroupsPackedIntoWarehouse: action.data,
         };
      case 'FETCH_PAINT_SHOP_STATISTICS':
         return {
            ...state,
            paintShopStatistics: action.data,
         };
      case 'FETCH_GROUPS_TO_BE_DELIVERED':
         return {
            ...state,
            groupsToBeDelivered: action.groups,
         };
      case 'FETCH_PALETTES_FOR_DISTRIBUTOR':
         return {
            ...state,
            groupsPackedIntoWarehouse: action.groups,
         };
      case 'FETCH_GROUPS_PACKED_INTO_WAREHOUSE':
         return {
            ...state,
            groupsPackedIntoWarehouse: action.groups,
            clients: action.clients,
         };
      case 'FETCH_GROUPS_TO_BE_SEND':
         return {
            ...state,
            groupsToBeSend: action.groups,
            clients: action.clients,
         };
      case 'FETCH_GROUPS_SENT':
         return {
            ...state,
            groupsSent: action.groups,
            clients: action.clients,
         };
      case 'FETCH_GROUPS':
         return {
            ...state,
            groups: action.groups,
         };
      case 'FETCH_PAINT_SHOP_PAINTING_ORDERS':
         return {
            ...state,
            orders: action.orders,
         };
      case 'FETCH_PRODUCTION_ORDERS':
         return {
            ...state,
            orders: action.orders,
         };
      default:
         return state;
   }
};

export default warehouse;
