import { CompanyName } from 'Shared/Warehouse/Warehouse';

import FinalPalette from './FinalPalette';

const GroupWithFinalPalettes = ({ group }) => {
   const finalPaletts = group.finalPalettes.map((p) => {
      return (
         <tr key={p.id}>
            <td>
               <FinalPalette finalPalette={p} />
            </td>
         </tr>
      );
   });
   const { createdByExternalClient } = group.finalPalettes[0].orderParts[0].order;

   return (
      <tr key={group.id}>
         <td>{group.publicId}</td>
         <td>
            <CompanyName
               orderPartsGroup={group}
               createdByExternalClient={createdByExternalClient}
            />
         </td>
         <td>
            <table className="table table-hover table-sm">
               <thead>
                  <tr>
                     <th />
                  </tr>
               </thead>
               <tbody>{finalPaletts}</tbody>
            </table>
         </td>
      </tr>
   );
};

export default GroupWithFinalPalettes;
