import { Unit } from './units';

export type FontSize = 14 | 16 | 18 | 20 | 24 | 30 | 36 | 42;

export const fontSize = (defaultUnit: Unit, size: FontSize): string => {
   if (defaultUnit === 'px') {
      return `${size}${defaultUnit}`;
   }

   return `${size / 16}${defaultUnit}`;
};
