const Palette = ({ palette }) => {
   if (!palette) return null;
   return (
      <div>
         <table className="table table-hover table-sm">
            <thead>
               <tr>
                  <th>Pakował(a)</th>
                  <th>Kiedy</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>{palette.packedBy || ''}</td>
                  <td>{palette.createdAt || ''}</td>
               </tr>
            </tbody>
         </table>
      </div>
   );
};

export default Palette;
