import { useState } from 'react';
import styled from 'styled-components';

import { getProductName } from 'Shared/Warehouse/Products';

const Show = styled.button`
   margin: 0.5rem 0;
`;

const ProductsCol = styled.td`
   display: flex;
   flex-direction: column;
`;

const Products = styled.div`
   display: flex;
   flex-direction: column;
`;

const Order = ({ log, products, weight, zamowieniaId, restOfProducts }) => {
   const [show, setShow] = useState(false);
   const rendered = products?.map((p) => {
      return (
         <span key={p.id}>
            {p.amount} x {getProductName(p)}
         </span>
      );
   });
   const renderedRestOfProducts = restOfProducts?.map((p) => {
      return (
         <span key={p.id}>
            {p.amount} x {getProductName(p)}
         </span>
      );
   });
   return (
      <tr>
         <td>{zamowieniaId}</td>
         <td>{weight}</td>
         <td>{log}</td>
         <ProductsCol>
            <Products>{rendered}</Products>
            {!!renderedRestOfProducts.length && (
               <Show className="btn btn-link" onClick={() => setShow((s) => !s)}>
                  {show ? '..schowaj' : 'pokaż nieliczące się produkty'}
               </Show>
            )}
            {show && <Products>{renderedRestOfProducts}</Products>}
         </ProductsCol>
      </tr>
   );
};

export default Order;
