import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import OrderPart from './OrderPart';

const Planing = () => {
   const dispatch = useDispatch();
   const planing = useSelector((s) => s.warehouse.planing);

   if (!planing) return null;
   return (
      <Container>
         <Table className="table table-hover">
            <thead>
               <tr>
                  <th />
                  <th>Produkty</th>
                  <th>
                     <CancelButton
                        type="button"
                        className="btn btn-light btn-icon"
                        onClick={() => dispatch({ type: 'CANCEL_PLANING' })}
                     >
                        <FontAwesomeIcon icon={faTimes} />
                        Anuluj planowanie
                     </CancelButton>
                  </th>
               </tr>
            </thead>
            <tbody>
               <OrderPart order={planing.order} orderPart={planing.orderPart} stickyView />
            </tbody>
         </Table>
      </Container>
   );
};

const Container = styled.div`
   position: fixed;
   left: calc(50% - 670px);
   top: 100px;
   background: #fff;
   width: 1340px;
   box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
   padding-top: 0.5rem;
`;

const Table = styled.table`
   margin: 0;
`;

const CancelButton = styled.button`
   width: 100%;
   .svg-inline--fa {
      color: #000;
   }
`;

export default Planing;
