import { useContext, useState } from 'react';
import styled from 'styled-components';

import {
   PACKING_PLACE_WAREHOUSE,
   STATUS_PACKED_INTO_WAREHOUSE,
} from 'Shared/Warehouse/OrderPartsGroup';

import { Context as WarehouseContext } from '../Context';
import Modal from '../PackModal/Modal';

const PackIntoWarehouseButton = ({ group }) => {
   const [open, setOpen] = useState(false);
   const close = () => setOpen(false);
   const { refetch } = useContext(WarehouseContext);
   if (!group.canBeMarkAsToBePacked) return null;

   return (
      <>
         {open && (
            <Modal
               groupId={group.id}
               close={close}
               packingPlace={PACKING_PLACE_WAREHOUSE}
               refetch={refetch}
            />
         )}
         <Button
            type="button"
            className="btn btn-success"
            onClick={() => setOpen(true)}
            disabled={group.status !== STATUS_PACKED_INTO_WAREHOUSE}
         >
            Spakuj na magazyn
         </Button>
      </>
   );
};

const Button = styled.button`
   width: 100%;
`;

export default PackIntoWarehouseButton;
