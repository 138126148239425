import styled, { css, keyframes } from 'styled-components/macro';

import { CircularProgressBaseProps, WrapperProps } from './CircularProgress.types';

const rotation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const getLoaderWidth = (size: number): string => {
   if (size === 0) {
      return '0';
   }

   if (size <= 8) {
      return '1px';
   }

   if (size <= 16) {
      return '2px';
   }

   if (size <= 24) {
      return '2.5px';
   }

   if (size <= 32) {
      return '3px';
   }

   if (size <= 48) {
      return '4px';
   }

   return '5px';
};

export const CircularProgressBase = styled.div<CircularProgressBaseProps>(({ size }) => {
   const sizeInPx = `${size}px`;

   return css`
      width: ${sizeInPx};
      height: ${sizeInPx};
      border: ${getLoaderWidth(size)} solid currentColor;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: block;
      box-sizing: border-box;
      animation: ${rotation} 1s linear infinite;
   `;
});

export const Wrapper = styled.span<WrapperProps>`
   display: inline-block;
   position: ${({ position }) => position};
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
`;
