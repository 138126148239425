import { CircularProgress } from 'common/components/CircularProgress/CircularProgress';
import { isDefined } from 'common/utils/isDefined';

import * as S from './Button.styled';
import type { Props } from './Button.types';

export const Button = ({
   disabled = false,
   type = 'button',
   children,
   loading = false,
   variant = 'contained',
   color = 'primary',
   size = 'medium',
   startIcon,
   endIcon,
   fullWidth = false,
   tabIndex,
   className,
   onClick,
}: Props): JSX.Element => {
   return (
      // eslint-disable-next-line react/button-has-type
      <S.ButtonBase
         onClick={onClick}
         tabIndex={tabIndex}
         className={className}
         type={type}
         disabled={disabled || loading}
         color={color}
         variant={variant}
         size={size}
         isLoading={loading}
         fullWidth={fullWidth}
      >
         <S.Content isLoading={loading}>
            {isDefined(startIcon) && <S.StartIcon>{startIcon}</S.StartIcon>}
            {children}
            {endIcon && <S.EndIcon>{endIcon}</S.EndIcon>}
         </S.Content>
         {loading && <CircularProgress size={20} position="absolute" />}
      </S.ButtonBase>
   );
};
