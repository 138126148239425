import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import InternalContainer from 'Layouts/InternalContainer';
import LastUpdatedAt from 'Pages/Dashboards/Warehouse/LastUpdatedAt';
import CurrentDay from 'Pages/PaintShopCalendar/CurrentDay';
import Error from 'Shared/Error';
import Loading from 'Shared/Loading';
import defaults from 'Shared/useQuery';

import Context from './context';
import Weeks from './Weeks';

const fetchAll = (month) =>
   axios.get(`/paint-shop/calendar/months/${dayjs(month).format('DD-MM-YYYY')}`);

const now = new Date();

const PaintShopCalendar = () => {
   const dispatch = useDispatch();
   const [value, setValue] = useState(now);
   const [month, setMonth] = useState(dayjs(value).toISOString());
   const currentDay = useSelector((s) => s.paintShopCalendar.currentDay);
   const { isLoading, error, refetch, dataUpdatedAt } = useQuery(
      [
         'fetchMonth',
         {
            month,
         },
      ],
      () => fetchAll(month),
      {
         ...defaults,
         enabled: !!value,
         onSuccess: ({ data }) => {
            dispatch({ type: 'FETCH_CALENDAR', days: data.days, weeks: data.weeks });
            // to rerender calendar
            setValue(dayjs(currentDay).toDate());
         },
      },
   );
   useEffect(() => {
      if (!value || !currentDay || dayjs(value).format('MM/DD/YYYY') === currentDay) return;
      setValue(dayjs(currentDay).toDate());
      setMonth(dayjs(currentDay).toDate());
   }, [value, currentDay]);
   const formatDay = (locale, date) => {
      return <Day>{dayjs(date).format('DD')}</Day>;
   };
   const onClickDay = (v) => {
      dispatch({ type: 'OPEN_DAY', currentDay: dayjs(v).format('MM/DD/YYYY') });
   };
   const onActiveStartDateChange = ({ activeStartDate }) => {
      setMonth(activeStartDate);
   };
   const prevLabel = <FontAwesomeIcon icon={faChevronLeft} />;
   const nextLabel = <FontAwesomeIcon icon={faChevronRight} />;

   const contextValue = useMemo(() => ({ refetch, month }), [refetch, month]);

   return (
      <InternalContainer>
         <Helmet>
            <title>Lakiernia &gt; Kalendarz | Konsport</title>
         </Helmet>
         <div className="row">
            <div className="col">
               {isLoading && <Loading />}
               {error && <Error />}
            </div>
         </div>
         <Context.Provider value={contextValue}>
            <div className="row">
               <div className="col">
                  <Calendar
                     onChange={setValue}
                     value={value}
                     formatDay={formatDay}
                     onClickDay={onClickDay}
                     onActiveStartDateChange={onActiveStartDateChange}
                     prevLabel={prevLabel}
                     nextLabel={nextLabel}
                     locale="pl"
                     className="xl"
                  />
               </div>
            </div>
            <CurrentDay refetch={refetch} setValue={setValue} />
            <Weeks />
            {!!dataUpdatedAt && <LastUpdatedAt updatedAt={dataUpdatedAt} />}
         </Context.Provider>
      </InternalContainer>
   );
};

const Day = styled.div`
   display: flex;
   flex-direction: column;
`;

export default PaintShopCalendar;
