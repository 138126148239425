import { useContext } from 'react';
// import styled from 'styled-components';

import { Context } from '../../Context';

// eslint-disable-next-line consistent-return
const PackedButton = ({ group }) => {
   const { setGroupForFinalPaletteModal } = useContext(Context);
   // if (!group.canBePacked) return <Info>Czeka na dostarczenie ze strefy</Info>;
   // if (group.canBePacked)

   const handleClick = () => {
      setGroupForFinalPaletteModal(group);
   };

   return (
      <button type="button" className="btn btn-success" onClick={() => handleClick()}>
         Spakuj na gotowo
      </button>
   );
};

// const Info = styled.span`
//    color: #ff0000;
// `;

export default PackedButton;
