import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { attribute, paletteTypes } from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';

const FinalPalette = ({ finalPalette }) => {
   const ordersPublicIds = finalPalette.orderParts.map((op) => {
      return (
         <li key={op.id}>
            <ZamowieniaLink
               displayId={op.order.zamowieniaId}
               zamowieniaId={op.order.zamowieniaId}
            />
         </li>
      );
   });
   const products = finalPalette.orderParts.map((op) => (
      <Products key={op.id} products={op.products} />
   ));
   return (
      <table className="table table-hover table-sm">
         <thead>
            <tr>
               <th>Część</th>
               <th>Szerokość</th>
               <th>Wysokość</th>
               <th>Długość</th>
               <th>Waga</th>
               <th>Typ</th>
               <th>Pakowane przez</th>
               <th>Zamówienia</th>
               <th>Kiedy</th>
               <th>Produkty</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{finalPalette.publicId || ''}</td>
               <td>{attribute('width', finalPalette.attributes)}</td>
               <td>{attribute('height', finalPalette.attributes)}</td>
               <td>{attribute('length', finalPalette.attributes)}</td>
               <td>{attribute('weight', finalPalette.attributes)}</td>
               <td>{paletteTypes[finalPalette.type]}</td>
               <td>{finalPalette.packedByFilledInManually || ''}</td>
               <td>
                  <ul className="list-unstyled">{ordersPublicIds}</ul>
               </td>
               <td>{finalPalette.packedAt || ''}</td>
               <td>{products}</td>
            </tr>
         </tbody>
      </table>
   );
};

export default FinalPalette;
