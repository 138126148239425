import styled, { css } from 'styled-components';

export const Container = styled.div(
   ({ theme: { spacing } }) => css`
      position: relative;
      width: ${spacing(11)};
      height: ${spacing(6)};
   `,
);

export const Track = styled.div(
   ({ theme: { palette, spacing, borderRadius } }) => css`
      background: ${palette.background.field.switch.default.inactive_container};
      width: 100%;
      height: ${spacing(6)};
      border-radius: ${borderRadius(3)};
      position: absolute;
      top: 0;
      left: 0;
      transition: background 250ms;
   `,
);

export const Thumb = styled.div(
   ({ theme: { palette, spacing, borderRadius } }) => css`
      position: absolute;
      top: 0;
      left: 0;
      padding: ${spacing(1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: ${borderRadius(3)};
      transform: translateX(0);
      cursor: pointer;

      &::before {
         content: '';
         display: block;
         width: ${spacing(4)};
         height: ${spacing(4)};
         border-radius: ${borderRadius(2)};
         background: ${palette.background.field.switch.default.inactive_thumb};
         transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
      }
   `,
);

export const Input = styled.input(
   ({ theme: { palette, spacing } }) => css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      cursor: pointer;

      &:focus {
         & ~ ${Track} {
            outline-color: ${palette.outline};
            outline-width: 2px;
            outline-offset: 2px;
            outline-style: solid;
         }
      }

      &:active {
         & ~ ${Thumb} {
            &::before {
               width: ${spacing(7)};
            }
         }
      }

      &:checked {
         & ~ ${Track} {
            background: ${palette.background.field.switch.default.active_container};
         }

         & ~ ${Thumb} {
            left: 100%;
            transform: translateX(-100%);
         }
      }
   `,
);
