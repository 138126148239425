import axios from 'axios';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import InternalContainer from 'Layouts/InternalContainer';
import defaults from 'Shared/useQuery';
import UseQueryStates from 'Shared/UseQueryStates';

import MarkAsSent from './MarkAsSent/MarkAsSent';
import Palettes from './Palettes';

const WarehouseSending = () => {
   const [palettes, setPalettes] = useState([]);
   const { error, updatedAt, isLoading, refetch } = useQuery(
      ['sentToday'],
      () => axios.get(`/warehouse/final-palettes/sent-today`),
      {
         ...defaults,
         onSuccess: ({ data }) => {
            setPalettes(data.finalPalettes);
         },
      },
   );
   return (
      <InternalContainer>
         <Helmet>
            <title>Magazyn &gt; Wysyłanie | Konsport</title>
         </Helmet>
         <div className="row">
            <div className="col">
               <MarkAsSent refetch={refetch} />
               <Container>
                  <h2>Wysłane dzisiaj</h2>
                  <UseQueryStates error={error} updatedAt={updatedAt} isLoading={isLoading} />
                  <Palettes palettes={palettes} />
               </Container>
            </div>
         </div>
      </InternalContainer>
   );
};

const Container = styled.div`
   margin-top: 2rem;
`;

export default WarehouseSending;
