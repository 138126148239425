import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { Theme } from 'designSystem/theme/theme';

import { ButtonBaseProps, IconButtonColor, IconButtonSize } from './IconButton.types';

const getColors = (
   isLoading: boolean,
   color: IconButtonColor,
   palette: Theme['palette'],
): FlattenSimpleInterpolation => {
   const border = palette.border.iconButton[color];
   const background = palette.background.iconButton[color];
   const text = palette.text.iconButton[color];

   return css`
      border-color: ${border.default};
      background: ${background.default};

      svg {
         fill: ${text.default};
      }

      &:hover {
         border-color: ${border.hover};
         background: ${background.hover};

         svg {
            fill: ${text.hover};
         }
      }

      &:focus {
         border-color: ${border.focus};
         background: ${background.focus};

         svg {
            fill: ${text.focus};
         }
      }

      &:active {
         border-color: ${border.active};
         background: ${background.active};

         svg {
            fill: ${text.active};
         }
      }

      &:disabled,
      &:disabled:hover {
         border-color: ${border.disabled};
         background: ${background.disabled};

         svg {
            fill: ${text.disabled};
         }
      }

      ${isLoading &&
      css`
         &,
         &:hover,
         &:disabled,
         &:disabled:hover {
            border-color: ${border.loading};
            background: ${background.loading};
            cursor: default;

            svg {
               fill: ${text.loading};
            }
         }
      `}
   `;
};

const getSize = (
   size: IconButtonSize,
   borderRadius: Theme['borderRadius'],
): FlattenSimpleInterpolation => {
   switch (size) {
      case 'large':
         return css`
            border-radius: ${borderRadius(4)};
            width: 56px;
            height: 56px;
         `;
      case 'medium':
      default:
         return css`
            border-radius: ${borderRadius(4)};
            width: 48px;
            height: 48px;
         `;
   }
};

export const ButtonBase = styled.button<ButtonBaseProps>(
   ({ theme: { spacing, palette, borderRadius }, $size, $color, $isLoading }) => {
      return css`
         position: relative;
         display: flex;
         align-items: center;
         justify-content: center;
         flex-shrink: 0;
         margin: ${spacing(0)};
         border: 1px solid transparent;
         font-weight: 600;
         transition: background 250ms, color 250ms, border-color 250ms;
         cursor: pointer;

         &,
         &:hover {
            text-decoration: none;
         }

         &:disabled,
         &:disabled:hover {
            cursor: default;
         }

         ${getSize($size, borderRadius)};
         ${getColors($isLoading, $color, palette)};
      `;
   },
);
