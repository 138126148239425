import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import LastUpdatedAt from 'Pages/Dashboards/Warehouse/LastUpdatedAt';
import ShowHideButton from 'Shared/Warehouse/ShowHideButton';

import { Context } from '../Context';
import SplitOrderModal from '../SplitOrderModal/SplitOrderModal';

import OrderPart from './OrderPart';

const OrdersWithoutGroup = ({ isLoading, dataUpdatedAt }) => {
   const dispatch = useDispatch();
   const { refetch } = useContext(Context);
   const [render, setRender] = useState(true);
   const orders = useSelector((s) => s.warehouse.orders);
   const splitOrderModal = useSelector((s) => s.warehouse.splitOrderModal);
   const filters = useSelector((s) => s.warehouse.filters);
   useEffect(() => {
      refetch();
   }, [dispatch, filters]);
   const rendered =
      render &&
      orders.map((o) => o.parts.map((op) => <OrderPart key={op.id} order={o} orderPart={op} />));
   return (
      <Container>
         <div>
            <Header className="h3">
               NOWE ZLECENIA ZAWIESZONE W MCD
               <ShowHideButton render={render} onClick={() => setRender((p) => !p)} />
            </Header>
         </div>
         {render && (
            <table className="table table-hover">
               <thead>
                  <tr>
                     <th>Nr zamówienia</th>
                     <th>Klient</th>
                     <th>Miejsce</th>
                     <th>Produkty</th>
                     <th />
                  </tr>
               </thead>
               <tbody>{rendered}</tbody>
               {!isLoading && !rendered.length && (
                  <tbody>
                     <tr>
                        <td>Brak zamówień</td>
                     </tr>
                  </tbody>
               )}
            </table>
         )}
         {splitOrderModal && <SplitOrderModal />}
         <LastUpdatedAt updatedAt={dataUpdatedAt} />
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
`;

const Header = styled.h2`
   font-weight: 600;
`;

export default OrdersWithoutGroup;
