import styled from 'styled-components';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import Products from 'Shared/Warehouse/Products';

import Painted from './Painted';

const Order = ({ order }) => {
   return (
      <tr>
         <td>
            <Description>
               <ZamowieniaLink displayId={order.zamowieniaId} zamowieniaId={order.zamowieniaId} />
               Lakiernia {order.startedPaintingAt}
            </Description>
         </td>
         <td>
            <Products products={order.products} />
         </td>
         <td>{order.leadingColor}</td>
         <td>
            <Painted order={order} />
         </td>
      </tr>
   );
};

const Description = styled.div`
   display: flex;
   flex-direction: column;
`;

export default Order;
