export const common_pl = {
   button: {
      add_discount: 'Rabat',
      add_newLink: 'Dodaj nowy link',
      add_newProduct: 'Dodaj nowy produkt',
      add_nextProduct: 'Dodaj kolejny produkt',
      add_product: 'Dodaj produkt',
      add_toPricing: 'Dodaj do wyceny',
      add_user: 'Dodaj użytkownika',
      archive: 'Archiwizuj',
      cancel: 'Anuluj',
      close: 'Zamknij',
      copyCode: 'Skopiuj kod',
      delete: 'Usuń',
      discount: 'Rabat %',
      download: 'Pobierz',
      duplicate: 'Duplikuj',
      edit: 'Edytuj',
      edit_product: 'Zaktualizuj produkt',
      enova: 'Kody Enova',
      myAccount: 'Moje konto',
      pdf_download: 'Pobierz PDF',
      pdf_generating: 'Generowanie PDF . . . ',
      print: 'Drukuj',
      publicPreview: 'Podgląd publiczny',
      removeLink: 'Usuń link',
      save: 'Zapisz',
      save_changes: 'Zapisz zmiany',
      save_color: 'Zapisz kolor',
      save_estimate: 'Zapisz jako kosztorys',
      save_newPassword: 'Zapisz nowe hasło',
      save_password: 'Zapisz hasło',
      sendForCompletion: 'Prześlij do realizacji',
      statisticId: 'REGON',
      technicalCatalogue: 'Przejdź do katalogu technicznego',
      usage_add: 'Dodaj zastosowanie',
      usage_addAnother: 'Dodaj kolejne zastosowanie',
      usage_delete: 'Usuń zastosowanie',
   },
   checkbox: {
      announce_accepted: 'Oświadczam, że akceptuję ',
      announce_acknowledged: 'Oświadczam, że zapoznałem się z ',
      checkAll: 'Zaznacz wszystkie',
      termsOfUse: 'ogólne warunki sprzedaży',
      generalTermsOfSale: 'ogólne warunki sprzedaży',
      productCard: 'kartę produktu',
      sendEmail: 'Prześlij kosztorys przez e-mail',
   },
   description: {
      sourceId1:
         '<SourceIdData>Source ID</SourceIdData> czyli kod produktu wprowadzamy tylko kiedy kod jest finalny/kompletny. <br /> Dla przykładu kod <SourceIdData>P/1028</SourceIdData> dla <SourceIdData>Farba proszkowa RAL 1028</SourceIdData> <u>jest kodem kompletnym</u> i nie wymaga dalszych uzupełnień czyli powninniśmy go wprowadzić w polu powyżej. Ale kod <SourceIdData>K/60-40-1,25/R/</SourceIdData> dla <SourceIdData>SŁUPEK 60X40X1,25MM OCYNK + RAL</SourceIdData> <u>wymaga uzupełnienia</u> w postaci wysokości słupka, więc finalnie będzie wyglądał następująco <SourceIdData>K/60-40-1,25/R/150</SourceIdData> czyli nie możemy wprowadzić go w polu powyżej.',
      sourceId2:
         'Zdefiniowanie <SourceIdData>Source ID</SourceIdData> dla produktu nie jest jednoznaczne z poprawnym generowaniem kodu dla <SourceIdData>Enova</SourceIdData> na etapie wyceny. Czasem potrzebne są dalsze kroki oprogramowania generowania kodu.',
   },
   error: {
      errorOccurred: 'Wystąpił błąd.',
      getPrice: 'Niestety nie udało się pobrać ceny',
      incorrectLoginOrPassword: 'Zły login lub hasło.',
      order_notFound: 'Nie znaleziono zamówienia.',
      order_notUpdated: 'Nie udało się zaktualizować zamówienia.',
      order_updated: 'Zamówienie zostało pomyślnie zaktualizowane!',
      product_delete: 'Nie udało się usunąć produktu.',
      relatedProducts: 'Bład przy pobieraniu powiązanych produktów.',
      somethingWentWrong: 'Coś poszło nie tak',
      tryAgain: 'Wystąpił błąd. Spróbuj jeszcze raz.',
      tryAgain_incorrectEmail: 'Email jest niepoprawny. Spróbuj jeszcze raz.',
      tryAgain_estimateUpdate:
         'Wystąpił błąd podczas aktualizowania kosztorysu. Spróbuj jeszcze raz.',
      pdfFailed: 'Nie udało się wygenerować PDF.',
   },
   message: {
      accountInfo: 'Dane firmy będą widoczne na wygenerowanych <br />przez Ciebie zamówieniach.',
      addedLogo: 'Logo zostało dodane',
      addedUser: 'Pomyślnie dodano użytkownika.',
      addNextProduct: 'To nie koniec zamówienia? Dodaj kolejny produkt:',
      browse: 'Przeglądaj',
      coppied: 'Skopiowano',
      dragHere: 'Przeciągnij plik tutaj, lub',
      dropHere: 'Upuść plik tutaj',
      extraCharge: '{{extraCharge}}% dopłaty',
      gettingPrice: 'Trwa pobieranie ceny',
      lackOfEstimates: 'Brak kosztorysów',
      loading: 'Ładowanie...',
      logIn: '<p>Sprawdzaj ceny i twórz zamówienia.<br />Szybko i wygodnie.</p>',
      noEnovaErrorsInfo: 'Brak informacji o błędach oraz kodach Enova.',
      none: 'Brak',
      noModelsFound: 'Żaden model nie został znaleziony',
      noProductsFound: 'Żaden produkt nie został znaleziony',
      noRelatedProducts: 'Brak powiązanych produktów.',
      passwordRemind: 'Pamiętaj, że tylko użytkownik powinien znać swoje hasło.',
      password_notEqual: 'Hasła nie są jednakowe. Spróbuj jeszcze raz.',
      password_repeated: 'Powtórzone hasło musi być takie samo jak hasło.',
      pdfInfo:
         'Dokument wygenerowany automatycznie z systemu kosztorysowania Konsport. Dokument wraz z ceną oraz asortymentem jest aktualny na dzień jego wygenerowania.',
      pricedAt: 'Wycenione o:',
      priceRefresh:
         'Poprzez naciśnięcie przycisku <strong>„Edytuj”</strong> uruchomisz możliwość swobodnej zmiany cech produktów w kosztorysie. Pamiętaj, że zmiana dowolnego parametru produktu spowoduje jego ponową kalkulację cenową w oparciu o najbardziej aktualne ceny. Po zakończonej edycji możesz ponownie przesłać kosztorys do klienta B2C. Klient B2C otrzyma zaktualizowaną wersję kosztorysu uwzględniającą wszystkie zmiany cech produktów oraz ewentualne nowe kalkulacje cenowe wynikające z wprowadzonych zmian.',
      processing: 'Przetwarzanie...',
      productNotServed: 'Ten produkt nie jest w tej chwili obsługiwany',
      productUpdated: 'Produkt został pomyślnie zaktualizowany',
      savedChanges: 'Zmiany zostały zapisane',
      savings: 'Oszczędzasz',
      summary: 'Tutaj pojawi się podsumowanie dotyczące Twojego nowego zamówienia...',
      tooManyRoles: 'Użytkownik ma zbyt wiele ról.',
      totalWithSavings: 'Łącznie po rabatach',
   },
   label: {
      accessoriesWithoutTax: 'netto akcesoriów',
      address: 'Adres',
      address_continued: 'Adres ciąga dalszy',
      attachment: 'Załącznik ({{ordinalNumber}})',
      changeUserRole: 'Zmień rolę użytkownika',
      clientAddress: 'Adres klienta',
      colorRequired: 'Wymagany kolor',
      companyName: 'Nazwa firmy',
      email: 'Adres email',
      email_business: 'Business email adress',
      fencingElement: 'Element ogrodzenia',
      galvanized: 'Ocynk ogniowy',
      internalOrder: 'Zamówienie wewnętrzne',
      maxWidth: 'Maks. szerokość',
      model: 'Model',
      more: 'Więcej...',
      myProducts: 'Moje produkty',
      name: 'Nazwa',
      newClient: 'Nowy klient',
      new_plural: 'Nowe',
      openingHours: 'Godziny otwarcia',
      palettes: 'Palety',
      password: 'Hasło',
      password_change: 'Zmień hasło użytkownika',
      password_repeat: 'Powtórz hasło',
      phone: 'Numer telefonu',
      phoneNumber: 'Numer telefonu',
      priceWithDiscount: 'Cena po rabacie',
      provideEstimateTitle: 'Wpisz nazwę kosztorysu',
      simplifiedView: 'Widok uproszczony',
      sourceId: 'Source ID',
      statisticId: 'REGON',
      taxId: 'NIP',
      usage: 'Zastosowanie',
      website: 'Adres strony internetowej',
   },
   link: {
      addProduct: 'Dodaj produkt',
      backToLogin: 'Wróć do logowania',
      forgotPassword: 'Zapomniałeś hasła?',
      logIn: 'Zaloguj',
      logOut: 'Wyloguj',
      news: 'Aktualności',
      products: 'Produkty',
      technicalCatalogue: 'Link do katalogu technicznego',
   },
   placeholder: {
      colorsSection: 'Wpisz nazwę koloru z palety RAL...',
      johnSmith: 'Np. Jan Kowalski, ogrodzenie domu',
      provideModelName: 'Wprowadź nazwę modelu',
      searchByQuery: 'Wpisz 3 znaki aby rozpocząć wyszukiwanie..',
      searchByType: 'Wybierz element ogrodzenia',
      selectFencingElement: 'Wybierz element ogrodzenia',
      startSearching: 'Rozpocznij wyszukiwanie',
   },
   table: {
      actions: 'Akcje',
      amount: 'Ilość',
      data: 'Dane',
      date: 'Data',
      name: 'Nazwa',
      ordinalNumber: 'Lp.',
      products: 'Produkty',
      productFeatures: 'Cechy produktu',
      vat: 'VAT',
   },
   title: {
      accountInfo: 'Dane konta',
      adminOptions: 'Opcje administratora',
      chosenModel: 'Wybrany model:',
      colors_popular: 'Najczęściej wybierane',
      colors_standard: 'Standardowe kolory',
      colors_other: 'Pozostałe kolory (wymagające dopłaty)',
      company: 'Firma',
      companyInfo: 'Dane firmy',
      companyLogo: 'Logo firmy',
      creationDate: 'Data stworzenia',
      editProduct: 'Zaktualizuj produkt',
      estimate: 'Kosztorys ',
      estimate_date: 'Data kosztorysu: ',
      estimate_plural: 'Kosztorysy ',
      id: 'ID',
      language: 'Wybór języka (wybrano: {{language}})',
      newEstimate: 'Nowy kosztorys',
      noEstimates: 'Brak kosztorysów',
      new_plural: 'Nowe',
      order_total: 'Suma zamówienia: ',
      order_totalWithDiscount: 'Suma zamówienia po rabacie: ',
      orders: 'Zamówienia',
      productList: 'Lista produktów',
      productsAddedToOrder: 'Produkty dodane do wyceny',
      remainingProducts: 'Pozostałe produkty',
      relatedProducts: 'Powiązane produkty',
      searchResults: 'Wyniki wyszukiwania dla: ',
      selectedColor: 'Wybrany kolor',
      selectFencingColor: 'Wybierz kolor Twojego ogrodzenia',
      fencingColor: 'Kolor Twojego ogrodzenia',
      settings: 'Ustawienia',
      summary: 'Podsumowanie',
      user: 'Użytkownik: {{name}}',
      user_plural: 'Użytkownicy',
      yourData: 'Twoje dane',
      importantInformation: 'Ważna informacja',
   },
   shared: {
      addProduct: 'Dodaj produkt',
      clientPanel: 'Panel klienta',
      discount: 'Rabat',
      no: 'Nie',
      percent: '{{percent}}%',
      price: 'Cena',
      price_withTax: 'Cena brutto',
      price_withoutTax: 'Cena netto',
      quantity: 'Ilość',
      search: 'Wyszukaj',
      select: 'Wybierz',
      total: 'Łącznie',
      totalWithTax: 'Łącznie brutto',
      viewDetails: 'Zobacz szczegóły',
      withDiscount: 'Po rabacie',
      withDiscount_withColon: 'po rabacie: ',
      withTax: 'Brutto',
      withTax_withColon: 'brutto: ',
      withoutTax: 'Netto',
      withoutTax_withColon: 'netto: ',
      yes: 'Tak',
   },
   status: {
      discount: 'Rabat {{discount}}%',
   },
};
