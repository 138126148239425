import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import InternalContainer from 'Layouts/InternalContainer';

import Filters from './Filters';
import OrdersGroup from './OrdersGroup';

const WarehouseOffice = () => {
   const orderGroups = useSelector((s) => s.warehouse.orderGroups);
   // const { refetch, dataUpdatedAt } = useQuery(
   //    ['fetchOrderGroups', { filters, limit }],
   //    () => fetchOrderGroups(filters, limit),
   //    {
   //       ...defaults,
   //       onSuccess: ({ data }) => {
   //          dispatch({
   //             type: 'ORDER_GROUPS_FETCHED',
   //             orderGroups: data.orderGroups,
   //          });
   //       },
   //    },
   // );
   // useEffect(() => {
   //    refetch();
   // }, [dispatch, filters]);
   const rendered = orderGroups.map((oG) => <OrdersGroup key={oG.id} ordersGroup={oG} />);

   return (
      <InternalContainer>
         <Helmet>
            <title>Magazyn &gt; Biuro | Konsport</title>
         </Helmet>
         <div className="row">
            <div className="col">
               <div>
                  <h1>Magazyn | Biuro</h1>
               </div>
               <Filters />
               <div>
                  <table className="table table-hover">
                     <thead>
                        <tr>
                           <th>Klient</th>
                           <th>Opiekun</th>
                           <th>Data spakowania</th>
                           <th>Id zamówienia</th>
                           <th>Typ</th>
                           <th>Wymiary (szer x wys x dł)</th>
                           <th>Ilość</th>
                           <th>Stan</th>
                        </tr>
                     </thead>
                     <tbody>{rendered}</tbody>
                  </table>
               </div>
            </div>
         </div>
      </InternalContainer>
   );
};

export default WarehouseOffice;
