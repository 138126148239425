import axios from 'axios';
import { useFormik } from 'formik';
import { useContext } from 'react';

import { STATUS_SENT, STATUS_TO_BE_SEND } from 'Shared/Warehouse/OrderPartsGroup';

import { Context } from '../../Context';

const sent = (groupId) => axios.post(`/warehouse/order-part-groups/${groupId}/sent`);

const initialValues = {};

const SentButton = ({ group }) => {
   const { refetch } = useContext(Context);
   const formik = useFormik({
      initialValues,
      onSubmit: () => {
         sent(group.id).then(() => refetch());
      },
   });
   const showSentBtn = group.status === STATUS_TO_BE_SEND || group.status === STATUS_SENT;
   if (!showSentBtn) return null;
   const disabled = formik.isSubmitting || group.status === STATUS_SENT;

   return (
      <button type="button" className="btn btn-success" onClick={sent} disabled={disabled}>
         Wysłane
      </button>
   );
};

export default SentButton;
