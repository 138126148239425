import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import ClientFilter from 'Shared/Warehouse/Filters/ClientFilter';
import {
   STATUS_TO_BE_PACKED_INTO_WAREHOUSE,
   STATUS_PACKED_INTO_WAREHOUSE,
   STATUS_TO_BE_PACKED,
} from 'Shared/Warehouse/OrderPartsGroup';
import ShowHideButton from 'Shared/Warehouse/ShowHideButton';
import { wareHouseFiltersInitialState } from 'state/warehouse';

const defaultFilters = {
   ...wareHouseFiltersInitialState,
   orderPartsGroupStatus: [
      STATUS_TO_BE_PACKED_INTO_WAREHOUSE,
      STATUS_TO_BE_PACKED,
      STATUS_PACKED_INTO_WAREHOUSE,
   ].join(','),
};

const Filters = () => {
   const dispatch = useDispatch();
   const clients = useSelector((s) => s.warehouse.clients);
   const [render, setRender] = useState(true);
   useEffect(() => {
      dispatch({ type: 'RESET_FILTERS', filters: defaultFilters });
   }, []);

   return (
      <Container>
         <div>
            <h2 className="h3">
               Filtry
               <ShowHideButton render={render} onClick={() => setRender((p) => !p)} />
            </h2>
         </div>
         {render && <ClientFilterStyled clients={clients} />}
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
   display: flex;
   flex-direction: column;
`;

const ClientFilterStyled = styled(ClientFilter)`
   width: 300px;
`;

export default Filters;
