import { useFormikContext } from 'formik';
import update from 'immutability-helper';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid_v4 } from 'uuid';

import { setErrorsInSplitOrderModal } from 'state/warehouse';

import { Context } from '../../Context';
import validate from '../../validate';

import mergeProducts from './mergeProducts';

const ALPHABET = [
   'A',
   'B',
   'C',
   'D',
   'E',
   'F',
   'G',
   'H',
   'J',
   'K',
   'L',
   'M',
   'N',
   'O',
   'P',
   'Q',
   'R',
   'S',
   'T',
   'U',
   'V',
   'W',
   'X',
   'Y',
   'Z',
];

const getLetter = (i) => ALPHABET[i];

const extractZamowieniaId = (publicId) => publicId.split('/')[0];

// input: 123/B, 123/C output: 123/A, 123/B
const regeneratePartIds = (parts) => {
   return parts.map((p, i) => {
      const zamowieniaId = extractZamowieniaId(p.publicId);
      const publicId = `${zamowieniaId}/${getLetter(i)}`;

      return {
         ...p,
         publicId,
      };
   });
};

const CREATE_NEW_PART = 'CREATE_NEW_PART';
const CREATE_NEW_PART_WITH_ONE_PIECE = 'CREATE_NEW_PART_WITH_ONE_PIECE';

const removePartsWithoutProducts = (parts) => {
   return parts
      .map((op) => {
         return op.products.length ? op : null;
      })
      .filter((p) => p !== null);
};

const createNewPartObj = (order, part, product, withOnePiece = false) => {
   const zamowieniaId = extractZamowieniaId(part.publicId);
   const publicId = `${zamowieniaId}/${getLetter(order.parts.length)}`;

   const p = withOnePiece
      ? {
           ...product,
           amount: 1,
        }
      : product;
   return {
      id: uuid_v4(),
      publicId,
      orderId: order.id,
      products: [p],
   };
};

const createNewPart = (
   formik,
   part,
   partsIndex,
   product,
   partsProductIndex,
   dispatch,
   originalProductsMap,
) => {
   const newPart = createNewPartObj(formik.values, part, product);
   const o = update(formik.values, {
      parts: {
         [partsIndex]: {
            products: { $splice: [[partsProductIndex, 1]] },
         },
         $push: [newPart],
      },
   });

   const cleanParts = removePartsWithoutProducts(o.parts);
   const regeneratedPartIds = regeneratePartIds(cleanParts);
   const errors = validate(originalProductsMap, regeneratedPartIds);
   formik.setValues({
      ...o,
      parts: regeneratedPartIds,
   });
   dispatch(setErrorsInSplitOrderModal(errors));
   return newPart;
};

const createNewPartWithOnePiece = (
   formik,
   part,
   partsIndex,
   product,
   partsProductIndex,
   dispatch,
   originalProductsMap,
) => {
   const newPart = createNewPartObj(formik.values, part, product, true);
   const amount = product.amount - 1;
   let products = {};
   if (amount === 0) {
      products = { $splice: [[partsProductIndex, 1]] };
   } else {
      products = {
         [partsProductIndex]: {
            $merge: { amount },
         },
      };
   }
   const o = update(formik.values, {
      parts: {
         [partsIndex]: {
            products,
         },
         $push: [newPart],
      },
   });
   const errors = validate(originalProductsMap, o.parts);
   const regeneratedPartIds = regeneratePartIds(o.parts);
   formik.setValues({
      ...o,
      parts: regeneratedPartIds,
   });
   dispatch(setErrorsInSplitOrderModal(errors));
   return newPart;
};

const assignProductToPart = (
   partId,
   formik,
   partsIndex,
   product,
   partsProductIndex,
   dispatch,
   originalProductsMap,
) => {
   const partsIndexOfMovedProduct = formik.values.parts.findIndex((p) => p.id === partId);
   const spec = {
      parts: {
         [partsIndex]: {
            products: { $splice: [[partsProductIndex, 1]] },
         },
         [partsIndexOfMovedProduct]: {
            products: { $push: [product] },
         },
      },
   };
   const o = update(formik.values, spec);
   const cleanParts = removePartsWithoutProducts(o.parts);
   const regeneratedPartIds = regeneratePartIds(cleanParts);
   const mergedProducts = mergeProducts(regeneratedPartIds);
   const errors = validate(originalProductsMap, mergedProducts);
   formik.setValues({
      ...o,
      parts: mergedProducts,
   });
   dispatch(setErrorsInSplitOrderModal(errors));
};

const ProductPartSelect = ({ part, partsIndex, product, partsProductIndex }) => {
   const dispatch = useDispatch();
   const formik = useFormikContext();
   const { originalProductsMap, setOrdertPartIdOriginalPartIdMap } = useContext(Context);
   const change = (e) => {
      if (e.target.value === CREATE_NEW_PART) {
         const newPart = createNewPart(
            formik,
            part,
            partsIndex,
            product,
            partsProductIndex,
            dispatch,
            originalProductsMap,
         );
         setOrdertPartIdOriginalPartIdMap((p) => {
            const newMap = {
               ...p,
               [newPart.id]: part.id,
            };
            return newMap;
         });
      } else if (e.target.value === CREATE_NEW_PART_WITH_ONE_PIECE) {
         const newPart = createNewPartWithOnePiece(
            formik,
            part,
            partsIndex,
            product,
            partsProductIndex,
            dispatch,
            originalProductsMap,
         );
         setOrdertPartIdOriginalPartIdMap((p) => {
            const newMap = {
               ...p,
               [newPart.id]: part.id,
            };
            return newMap;
         });
      } else {
         assignProductToPart(
            e.currentTarget.value,
            formik,
            partsIndex,
            product,
            partsProductIndex,
            dispatch,
            originalProductsMap,
         );
         setOrdertPartIdOriginalPartIdMap((p) => {
            const newMap = {
               ...p,
            };
            delete newMap[part.id];
            return newMap;
         });
      }
   };
   const options = formik.values.parts.map((p, i) => (
      <option key={p.id} value={p.id}>
         {formik.values.zamowieniaId}/{getLetter(i)}
      </option>
   ));
   const productAmountIsEqualZero = product.amount === 0;
   return (
      <div>
         <label>
            Id części zamówienia
            <select
               className="form-control"
               onChange={change}
               value={part.id}
               onBlur={() => {
                  //
               }}
               disabled={productAmountIsEqualZero}
            >
               <option value="">Wybierz</option>
               <option value={CREATE_NEW_PART}>Nowa część zamówienia</option>
               <option value={CREATE_NEW_PART_WITH_ONE_PIECE} disabled={productAmountIsEqualZero}>
                  Nowa część zamówienia z jedną sztuką
               </option>
               {options}
            </select>
         </label>
      </div>
   );
};

export default ProductPartSelect;
