import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import styled from 'styled-components';

import { Response } from 'Shared/Response';
import ShowHideButton from 'Shared/Warehouse/ShowHideButton';

import { Context } from '../Context';

const CheckInOtherOrders = () => {
   const { refetch } = useContext(Context);
   const [render, setRender] = useState(true);
   const [error, setError] = useState(false);
   const [info, setInfo] = useState(null);
   const formik = useFormik({
      initialValues: {
         publicId: '',
      },
      onSubmit: ({ publicId }) => {
         setInfo(null);
         setError(false);
         axios
            .post('production/orders/check-in', {
               zamowieniaId: publicId,
            })
            .then(() => {
               setInfo('Zamówienie zostało dodane.');
               refetch();
            })
            .catch((e) => {
               if (!e.response?.status) {
                  Sentry.captureException(e);
               }
               setError(true);
               setInfo(e.response?.data?.message ?? 'Wystąpił błąd. Spróbuj jeszcze raz.');
            });
      },
   });
   return (
      <Container>
         <div>
            <h2 className="h3">
               Dodaj inne zamówienie
               <ShowHideButton render={render} onClick={() => setRender((p) => !p)} />
            </h2>
            <Form onSubmit={formik.handleSubmit} className="form-inline">
               <div>
                  <label htmlFor="publicId">
                     Id zamówienia
                     <input
                        type="text"
                        className="form-control"
                        id="publicId"
                        onChange={formik.handleChange}
                        value={formik.values.publicId}
                     />
                  </label>
               </div>
               <Button type="submit" className="btn btn-success">
                  Dodaj
               </Button>
            </Form>
            <Response error={error} messages={[info]} />
         </div>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
`;

const Form = styled.form`
   display: flex;
   align-items: flex-end;
`;

const Button = styled.button`
   margin-left: 1rem;
`;

export default CheckInOtherOrders;
