import { useDispatch, useSelector } from 'react-redux';

import { filterChanged } from 'state/warehouse';

const AccountManagerFilter = ({ accountManagers }) => {
   const dispatch = useDispatch();
   const filters = useSelector((state) => state.warehouse.filters);
   const clientOptions = accountManagers.map((aM) => (
      <option key={aM} value={aM}>
         {aM}
      </option>
   ));

   return (
      <div>
         <label htmlFor="opiekun">
            Opiekun
            <select
               id="opiekun"
               onBlur={() => {
                  //
               }}
               className="form-control"
               onChange={(e) => dispatch(filterChanged('accountManager', e.target.value || ''))}
               value={filters.accountManager}
            >
               <option value="" />
               {clientOptions}
            </select>
         </label>
      </div>
   );
};

export default AccountManagerFilter;
