import styled, { css } from 'styled-components';

import { TextContentStyledProps, WrapperStyledProps } from './Alert.types';

const PERCENT_50 = '32';

export const Wrapper = styled.div<WrapperStyledProps>(
   ({ theme: { spacing, borderRadius, palette }, $type, $noShadow }) => css`
      align-items: center;
      background: ${palette.background.alert[$type]};
      border-radius: ${borderRadius(4)};
      color: ${palette.text.alert[$type].headline};
      display: flex;
      gap: ${spacing(6)};
      padding: ${spacing(6, 6, 6, 8)};

      svg {
         flex-shrink: 0;
      }

      ${$noShadow
         ? css`
              border: 1px solid ${palette.border.alert[$type]};
           `
         : css`
              box-shadow: ${`${palette.border.alert[$type]}${PERCENT_50}`} 0px 5px 15px;
           `}
   `,
);

export const Content = styled.div(() => css``);

export const TextContent = styled.div<TextContentStyledProps>(
   ({ theme: { palette, fontSize }, $type }) => css`
      font-size: ${fontSize(16)};
      font-weight: 400;
      color: ${palette.text.alert[$type].body};
   `,
);

export const Header = styled.div(
   ({ theme: { spacing } }) => css`
      margin-bottom: ${spacing(2)};
   `,
);
