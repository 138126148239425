export type Screen = {
   1024: string;
   1280: string;
   1440: string;
   1600: string;
};

export const screen: Screen = {
   1024: `@media screen and (min-width: 1024px)`,
   1280: `@media screen and (min-width: 1280px)`,
   1440: `@media screen and (min-width: 1440px)`,
   1600: `@media screen and (min-width: 1600px)`,
};
