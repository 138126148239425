import { combineReducers } from 'redux';

import app from './app';
import order from './order';
import paintShopCalendar from './paintShopCalendar';
import warehouse from './warehouse';

const reducers = combineReducers({
   app,
   order,
   warehouse,
   paintShopCalendar,
});

export default reducers;
