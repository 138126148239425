export type Spacings = {
   0: string;
   1: string;
   2: string;
   4: string;
   8: string;
   12: string;
   16: string;
   24: string;
   32: string;
   40: string;
   48: string;
   56: string;
   64: string;
   72: string;
   80: string;
   88: string;
   96: string;
   104: string;
   112: string;
   120: string;
   128: string;
   136: string;
   144: string;
   152: string;
   160: string;
};

export const spacings: Spacings = {
   0: '0',
   1: '1px',
   2: '2px',
   4: '4px',
   8: '8px',
   12: '12px',
   16: '16px',
   24: '24px',
   32: '32px',
   40: '40px',
   48: '48px',
   56: '56px',
   64: '64px',
   72: '72px',
   80: '80px',
   88: '88px',
   96: '96px',
   104: '104px',
   112: '112px',
   120: '120px',
   128: '128px',
   136: '136px',
   144: '144px',
   152: '152px',
   160: '160px',
} as const;

export const spacing = (...args: (keyof Spacings)[]): string => {
   if (args.length > 4) {
      throw new Error('spacing: Maximum number of arguments up to 4.');
   }

   return args.reduce((combine, currentSpacing) => `${combine} ${spacings[currentSpacing]}`, '');
};
