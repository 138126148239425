const appInitialState = {
   auth: {
      authenticated: false,
   },
   colors: {
      all: [],
      popular: [],
      standard: [],
      other: [],
      error: null,
      pending: true,
   },
};

const filterColorsByOrder = (order) => (color) => color.order === order;

// eslint-disable-next-line default-param-last
const app = (state = appInitialState, action) => {
   switch (action.type) {
      case 'SIGN_IN':
      case 'SIGN_IN_FROM_TOKEN':
         return {
            ...state,
            auth: {
               ...state.auth,
               authenticated: true,
            },
         };
      case 'SIGN_OUT':
         return {
            ...state,
            auth: {
               ...state.auth,
               authenticated: false,
            },
         };
      case 'FETCH_COLORS':
         return {
            ...state,
            colors: {
               all: action.colors,
               other: action.colors.filter(filterColorsByOrder(20)),
               popular: action.colors.filter(filterColorsByOrder(0)),
               standard: action.colors.filter(filterColorsByOrder(10)),
               error: null,
               pending: false,
            },
         };
      case 'FETCH_COLORS_FAILED':
         return {
            ...state,
            colors: {
               ...appInitialState.colors,
               error: action.error,
               pending: false,
            },
         };
      case 'SET_USERS':
         return {
            ...state,
            users: action.users,
         };
      default:
         return state;
   }
};

export default app;
