import styled, { css } from 'styled-components';

import { ReactComponent as ErrorIcon } from 'designSystem/icons/error.svg';
import { ReactComponent as InfoIcon } from 'designSystem/icons/info.svg';
import { ReactComponent as SuccessIcon } from 'designSystem/icons/success.svg';
import { ReactComponent as WarningIcon } from 'designSystem/icons/warning.svg';

import { IconStyledProps } from './AlertIcon.types';

export const StyledSuccessIcon = styled(SuccessIcon)<IconStyledProps>(
   ({ $type, theme: { palette } }) => css`
      fill: ${palette.text.alert[$type].headline};
   `,
);

export const StyledWarningIcon = styled(WarningIcon)<IconStyledProps>(
   ({ $type, theme: { palette } }) => css`
      fill: ${palette.text.alert[$type].headline};
   `,
);

export const StyledErrorIcon = styled(ErrorIcon)<IconStyledProps>(
   ({ $type, theme: { palette } }) => css`
      fill: ${palette.text.alert[$type].headline};
   `,
);

export const StyledInfoIcon = styled(InfoIcon)<IconStyledProps>(
   ({ $type, theme: { palette } }) => css`
      fill: ${palette.text.alert[$type].headline};
   `,
);
