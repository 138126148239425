import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { useEffect } from 'react';
import styled from 'styled-components';

import Palette from './Palette/Palette';

const validate = (values) => {
   const errors = {};
   const palettesWithoutTypes = values.palettes.filter((p) => p.type === '');
   if (palettesWithoutTypes.length) {
      errors.palettes = 'Wszystkie palety muszą mieć wybrany typ';
   }
   return errors;
};

const Modal = ({ groupId, close, packingPlace, refetch, desc }) => {
   const formik = useFormik({
      initialValues: {
         palettes: [],
      },
      validate,
      onSubmit: (values) => {
         const palettes = values.palettes.map((p) => {
            const orderParts = p.orderParts.map((op) => {
               return {
                  id: op.id,
               };
            });
            return {
               type: p.type,
               publicId: p.publicId,
               orderParts,
            };
         });
         const data = {
            palettes,
            packingPlace,
         };
         axios.post(`/warehouse/order-part-groups/${groupId}/to-be-packed`, data).then(() => {
            refetch();
            close();
         });
      },
   });
   useEffect(() => {
      const mapGroup = async () => {
         const { data: group } = await axios.get(
            `/warehouse/groups/${groupId}/for-planning-packing`,
         );
         if (!group) return;
         const partsLength = group.parts.length;
         const palettes = group.parts.map((p, i) => {
            const publicId = `${i + 1}/${partsLength}`;
            return {
               type: '',
               publicId,
               orderParts: [
                  {
                     id: p.id,
                     publicId: p.publicId,
                     products: p.products,
                     order: {
                        zamowieniaId: p.order.zamowieniaId,
                     },
                  },
               ],
            };
         });
         formik.setFieldValue('palettes', palettes);
      };
      if (formik.values.palettes.length) return;
      mapGroup();
   }, []);
   const palettes = formik.values.palettes.map((p, i) => (
      <Palette key={p.id} palette={p} index={i} />
   ));
   return (
      <Container className="modal" tabIndex="-1" role="dialog">
         <div className="modal-dialog modal-xl" role="document">
            <InnerContainer className="modal-content">
               <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                     <div className="modal-header">
                        <h5 className="modal-title">Oznaczasz grupę do spakowania na gotowo</h5>
                        <button
                           type="button"
                           className="close"
                           data-dismiss="modal"
                           aria-label="Close"
                           onClick={close}
                        >
                           <span aria-hidden="true">&times;</span>
                        </button>
                     </div>
                     {desc && (
                        <div className="modal-body">
                           <b>{desc}</b>
                        </div>
                     )}
                     <div className="modal-body">
                        <div>{palettes}</div>
                     </div>
                     <FooterContainer className="modal-footer">
                        <ActionsContainer>
                           {formik.errors.palettes && <span>{formik.errors.palettes}</span>}
                           <button
                              type="submit"
                              className="btn btn-success"
                              disabled={Object.keys(formik.errors).length || formik.isSubmitting}
                           >
                              Do spakowania na gotowo
                           </button>
                           <button
                              type="button"
                              className="btn btn-danger"
                              data-dismiss="modal"
                              onClick={close}
                           >
                              Zamknij
                           </button>
                        </ActionsContainer>
                     </FooterContainer>
                  </form>
               </FormikProvider>
            </InnerContainer>
         </div>
      </Container>
   );
};

const Container = styled.div`
   display: block;
   background-color: rgba(0, 0, 0, 0.9);
`;

const InnerContainer = styled.div`
   overflow-y: auto;
   max-height: calc(100vh - 1.75rem);
`;

const FooterContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-end;
`;

const ActionsContainer = styled.div`
   & > .btn:not(:first-child) {
      margin-left: 1rem;
   }
`;

export default Modal;
