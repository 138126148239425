import styled from 'styled-components';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { orderPartsGroupStatuses } from 'Shared/Warehouse/OrderPartsGroup';
import Products from 'Shared/Warehouse/Products';

import Palette from './Palette';

const GroupWithoutFinalPalettes = ({ group }) => {
   const parts = group.parts.map((p) => {
      return (
         <tr key={p.id}>
            <td>
               <ZamowieniaLink
                  displayId={p.order.zamowieniaId}
                  zamowieniaId={p.order.zamowieniaId}
               />
            </td>
            <td>
               <Products products={p.products} />
            </td>
            <td>
               <Palette palette={p.palette} />
            </td>
         </tr>
      );
   });
   return (
      <tr key={group.id}>
         <td>
            <Description>
               <span>Klient: {group.companyName}</span>
               <span>Utworzono: {group.createdAt}</span>
               <span>Na strefę: {group.packedIntoWarehouseAt}</span>
               <span>Status: {orderPartsGroupStatuses[group.status]}</span>
               <span>Id grupy: {group.publicId}</span>
            </Description>
         </td>
         <td>
            <table className="table table-hover table-sm">
               <thead>
                  <tr>
                     <th>Zamówienie</th>
                     <th>Produkty</th>
                     <th>Palety na strefie</th>
                  </tr>
               </thead>
               <tbody>{parts}</tbody>
            </table>
         </td>
      </tr>
   );
};

const Description = styled.div`
   display: flex;
   flex-direction: column;
`;

export default GroupWithoutFinalPalettes;
