import { faDivide } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import CreatedByInternalClientActions from 'Pages/WarehouseManager/OrdersWithoutGroup/CreatedByInternalClientActions';
import WareHouseCountdown from 'Pages/WarehouseManager/WareHouseCountdown';
import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import Products from 'Shared/Warehouse/Products';
import { openSplitOrderModal } from 'state/warehouse';

const OrderPart = ({ order, orderPart }) => {
   const dispatch = useDispatch();
   const split = () => {
      dispatch(openSplitOrderModal(order.id));
   };
   const disabled = order.parts.some((p) => !p.canBeSplit);

   return (
      <tr id={orderPart.id}>
         <td style={{ width: '20%' }}>
            <div>{order.zamowieniaId}</div>
            <div>
               <ZamowieniaLink zamowieniaId={order.zamowieniaId} />
            </div>
            {order.companyName}
            {order.createdByExternalClient && <External> | KLIENT OBCY</External>}
            {order.startedPaintingAt && (
               <p>
                  Lakiernia: <WareHouseCountdown date={dayjs(order.startedPaintingAt)} />
               </p>
            )}
            <p>Dodano: {order.checkedInAt}</p>
         </td>
         <td style={{ width: '68%' }}>
            <Products products={orderPart.products} />
         </td>
         <td style={{ width: '12%' }}>
            <CreatedByInternalClientActions order={order} orderPart={orderPart} />
            <div>
               <button
                  type="button"
                  className="btn btn-link btn-icon-left"
                  onClick={split}
                  disabled={disabled}
               >
                  <FontAwesomeIcon icon={faDivide} />
                  Rozdziel
               </button>
            </div>
         </td>
      </tr>
   );
};

const External = styled.span`
   font-weight: bold;
   color: #28a745;
`;

export default OrderPart;
