import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
   ORDER_ATTRIBUTE_AMOUNT_OF_PAINT,
   ORDER_ATTRIBUTE_LEADING_COLOR,
} from 'Shared/Warehouse/OrderPart';

const now = dayjs().format('DD-MM-YYYY--HH-mm-ss');

const headers = [
   { label: 'Data', key: 'date' },
   { label: 'Id', key: 'zamowieniaId' },
   { label: 'Kolor', key: 'leadingColor' },
   { label: 'Zużyta farba', key: 'amountOfPaint' },
];

const ExportButton = () => {
   const orders = useSelector((state) => state.warehouse.orders);

   const data = orders.map((o) => {
      const amountOfPaint =
         o.attributes.find((a) => a.key === ORDER_ATTRIBUTE_AMOUNT_OF_PAINT)?.value || '';

      return {
         date: o.startedPaintingAt,
         zamowieniaId: o.zamowieniaId,
         leadingColor: o[ORDER_ATTRIBUTE_LEADING_COLOR],
         amountOfPaint,
      };
   });

   return (
      <Container>
         <CSVLinkStyled
            className="btn btn-success btn-icon"
            data={data}
            headers={headers}
            filename={`lakiernia-${now}.csv`}
            target="_blank"
            separator=";"
         >
            <FontAwesomeIcon icon={faFileAlt} />
            Pobierz jako CSV
         </CSVLinkStyled>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 1rem;
`;

const CSVLinkStyled = styled(CSVLink)`
   margin-bottom: 0.5rem;
   width: 260px;
`;

export default ExportButton;
