import { createContext } from 'react';

const Context = createContext({ refetch: undefined, month: undefined });

export const AVERAGE_LOGS_PER_HOUR = 5.25;

const createH = (h) => {
   if (h < 1) {
      return `${h} godzin`;
   }
   if (h >= 1 && h < 2) {
      return `${h} godzina`;
   }
   if (h > 2 && h < 5) {
      return `${h} godziny`;
   }
   if (h >= 5 && h < 22) {
      return `${h} godzin`;
   }
   if (h >= 22 && h < 30) {
      return `${h} godziny`;
   }
   if (h >= 30) {
      return `${h} godzin`;
   }
   return 'godzin';
};

const createM = (m) => {
   if (!m) return '';
   return `${m} minut`;
};

export const timeReadable = (n) => {
   const hours = n / 60;
   const h = Math.floor(hours);
   const minutes = (hours - h) * 60;
   const m = Math.round(minutes);
   if (!h) {
      return createM(m);
   }
   return `${createH(h)} ${createM(m)}`;
};

export const findDay = (days, day) => days.find((d) => d.day === day);

export default Context;
