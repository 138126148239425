import styled, { css } from 'styled-components';

import { Theme } from 'designSystem/theme/theme';

import { Color, HeadingProps, TextProps } from './Typography.types';

const getColor = (
   palette: Theme['palette'],
   variant: 'heading' | 'text',
   color?: Color,
): string => {
   switch (color) {
      case 'neutral':
         return palette.text.typography[variant].neutral;
      case 'positive':
         return palette.text.typography[variant].positive;
      case 'negative':
         return palette.text.typography[variant].negative;
      case 'inherit':
         return 'inherit';
      case 'light':
         return palette.text.typography[variant].light;
      default:
         return palette.text.typography[variant].default;
   }
};

export const Heading = styled.h1<HeadingProps>(
   ({
      theme: { lineHeight, spacing, palette },
      $variant,
      $color,
      $decoration,
      $gutterBottom,
      $align,
   }) => css`
      text-decoration: ${$decoration};
      line-height: ${lineHeight};
      color: ${getColor(palette, 'heading', $color)};
      margin: ${$gutterBottom ? '0 0 1em' : spacing(0)};
      text-align: ${$align};
      padding: ${spacing(0)};
      display: block;

      ${() => {
         switch ($variant) {
            case 'h6':
               return css`
                  font-weight: 600;
                  font-size: 18px;
               `;
            case 'h5':
               return css`
                  font-weight: 600;
                  font-size: 20px;
               `;
            case 'h4':
               return css`
                  font-weight: 600;
                  font-size: 24px;
               `;
            case 'h3':
               return css`
                  font-weight: 600;
                  font-size: 30px;
               `;
            case 'h2':
               return css`
                  font-weight: 500;
                  font-size: 34px;
               `;
            case 'h1':
            default:
               return css`
                  font-weight: 400;
                  font-size: 38px;
               `;
         }
      }};
   `,
);

export const Text = styled.p<TextProps>(
   ({
      theme: { palette, spacing, lineHeight },
      $decoration,
      $color,
      $gutterBottom,
      $variant,
      $align,
   }) => css`
      text-decoration: ${$decoration};
      line-height: ${lineHeight};
      color: ${getColor(palette, 'heading', $color)};
      margin: ${$gutterBottom ? '0 0 1em' : spacing(0)};
      padding: ${spacing(0)};
      display: block;
      text-align: ${$align};

      ${() => {
         switch ($variant) {
            case 'subtitle2':
               return css`
                  font-weight: 700;
                  font-size: 16px;
               `;
            case 'subtitle1':
               return css`
                  font-weight: 700;
                  font-size: 18px;
               `;
            case 'body3':
               return css`
                  font-weight: 400;
                  font-size: 16px;
               `;
            case 'body2':
               return css`
                  font-weight: 400;
                  font-size: 18px;
               `;
            case 'body1':
            default:
               return css`
                  font-weight: 400;
                  font-size: 20px;
               `;
         }
      }}
   `,
);
