import { useDispatch, useSelector } from 'react-redux';

import {
   STATUS_PACKED,
   STATUS_PACKED_INTO_WAREHOUSE,
   STATUS_TO_BE_SEND,
} from 'Shared/Warehouse/OrderPartsGroup';
import { filterChanged } from 'state/warehouse';

const OrdersGroupStatusFilter = () => {
   const dispatch = useDispatch();
   const filters = useSelector((state) => state.warehouse.filters);

   return (
      <div>
         <label>
            Stan
            <select
               onBlur={() => {}}
               className="form-control"
               onChange={(e) =>
                  dispatch(filterChanged('orderPartsGroupStatus', e.currentTarget.value))
               }
               value={filters.orderPartsGroupStatus}
            >
               <option value="" />
               <option value={STATUS_PACKED}>Spakowane na gotowo</option>
               <option value={STATUS_PACKED_INTO_WAREHOUSE}>Niepakowane na gotowo</option>
               <option value={STATUS_TO_BE_SEND}>Zakończone / Do wysłania</option>
            </select>
         </label>
      </div>
   );
};

export default OrdersGroupStatusFilter;
