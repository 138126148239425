import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import UseQueryStates from 'Shared/UseQueryStates';

import Group from './Group';

const Warehouse = ({ error, dataUpdatedAt, isLoading }) => {
   const [showRendered, setShowRendered] = useState(true);
   const groups = useSelector((s) => s.warehouse.groups);
   const rendered = groups.map((g) => <Group key={g.id} group={g} />);

   return (
      <>
         <Section>
            <Header>
               <h2>Plac</h2>
               <ShowRendered
                  type="button"
                  className="btn btn-link"
                  onClick={() => setShowRendered((p) => !p)}
               >
                  {showRendered ? 'Schowaj' : 'Pokaż'}
               </ShowRendered>
            </Header>
            {showRendered && (
               <table className="table table-hover">
                  <thead>
                     <tr>
                        <th>Grupa</th>
                        <th>Klient</th>
                        <th>Status</th>
                        <th>Zamówienia / części zamówień</th>
                     </tr>
                  </thead>
                  <tbody>{rendered}</tbody>
               </table>
            )}
         </Section>
         <UseQueryStates error={error} updatedAt={dataUpdatedAt} isLoading={isLoading} />
      </>
   );
};

const Section = styled.section`
   margin-top: 2rem;
`;

const Header = styled.section`
   display: flex;
   align-items: center;
`;

const ShowRendered = styled.section`
   margin-left: 1rem;
`;

export default Warehouse;
