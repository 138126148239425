import styled from 'styled-components';

import { colors } from 'deprecatedTheme/colors';

type ContainerProps = {
   error: boolean;
};

const Container = styled.div<ContainerProps>`
   margin-top: 1rem;
   color: ${({ error }) => (error ? colors.primary : 'inherit')};
   white-space: break-spaces;
   p:last-child {
      margin-bottom: 0;
   }
`;

type Props = {
   messages: string[];
   error: boolean;
   className?: string;
};

export const Response = ({ messages, error, className = '' }: Props): JSX.Element => {
   const rendered = messages ? messages.map((m) => <p key={Math.random()}>{m}</p>) : null;

   return (
      <Container error={error} className={className}>
         {rendered}
      </Container>
   );
};

Response.defaultProps = {
   className: '',
};
