import * as dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import InternalContainer from 'Layouts/InternalContainer';
import DateRangeCalendar from 'Shared/DateRangeCalendar';
import ResetFiltersButton from 'Shared/ResetFiltersButton';
import ClientFilter from 'Shared/Warehouse/Filters/ClientFilter';
import { STATUS_PACKED_INTO_WAREHOUSE } from 'Shared/Warehouse/OrderPartsGroup';
import SearchGroupByZamowieniaId from 'Shared/Warehouse/SearchGroupByZamowieniaId/SearchGroupByZamowieniaId';
import { wareHouseFiltersInitialState } from 'state/warehouse';

import OrderPartsGroupStatusFilter from './Filters/OrderPartsGroupStatusFilter';
import PackedIntoWarehouse from './PackedIntoWarehouse/PackedIntoWarehouse';
import Sent from './Sent';
import ToBeSent from './ToBeSent/ToBeSent';

const defaultFilters = {
   ...wareHouseFiltersInitialState,
   orderPartsGroupStatus: STATUS_PACKED_INTO_WAREHOUSE,
};

const WarehousePalettes = () => {
   const dispatch = useDispatch();
   const filters = useSelector((s) => s.warehouse.filters);
   const clients = useSelector((s) => s.warehouse.clients);
   const [showExtraFilters, setShowExtraFilters] = useState(false);
   const [groupPackedAt, setGroupPackedAt] = useState({ from: null, to: null });
   useEffect(() => {
      dispatch({ type: 'RESET_FILTERS', filters: defaultFilters });
   }, []);
   const onCalendarChanged = ({ from: fromRaw, to: toRaw }) => {
      const from = dayjs(fromRaw).format('YYYY-MM-DD');
      const to = dayjs(toRaw).format('YYYY-MM-DD');
      setGroupPackedAt({ from, to });
   };
   const info =
      filters.orderPartsGroupStatus === STATUS_PACKED_INTO_WAREHOUSE
         ? 'zamówienia spakowane na strefę'
         : 'zamówienia spakowane na gotowo';

   return (
      <InternalContainer>
         <Helmet>
            <title>Magazyn &gt; Palety | Konsport</title>
         </Helmet>
         <div className="row">
            <div className="col">
               <SearchGroupByZamowieniaId />
               <div>
                  <h1>Palety</h1>
               </div>
               <TopContainer>
                  <Filters>
                     <ClientFilterStyled clients={clients} />
                     <OrderPartsGroupStatusFilterStyled />
                     <MoreFiltersButton
                        className="btn btn-link"
                        onClick={() => setShowExtraFilters((s) => !s)}
                     >
                        {showExtraFilters ? 'Ukryj dodatkowe filtry' : 'Pokaż dodatkowe filtry'}
                     </MoreFiltersButton>
                  </Filters>
                  <DateRangeCalendarStyled info={info} onChange={onCalendarChanged} />
                  <Actions>
                     <ResetFiltersButtonStyled filters={defaultFilters} />
                  </Actions>
               </TopContainer>
               <PackedIntoWarehouse groupPackedAt={groupPackedAt} />
               <ToBeSent groupPackedAt={groupPackedAt} />
               <Sent groupPackedAt={groupPackedAt} />
            </div>
         </div>
      </InternalContainer>
   );
};

const TopContainer = styled.div`
   display: flex;
   justify-content: space-between;
   margin-bottom: 1rem;
`;

const DateRangeCalendarStyled = styled(DateRangeCalendar)`
   margin: 0 1rem;
`;

const Actions = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
`;

const Filters = styled.div`
   display: flex;
   flex-direction: column;
`;

const ResetFiltersButtonStyled = styled(ResetFiltersButton)`
   width: 300px;
`;

const OrderPartsGroupStatusFilterStyled = styled(OrderPartsGroupStatusFilter)`
   width: 300px;
`;

const MoreFiltersButton = styled.button`
   margin: 0.5rem 0;
`;

const ClientFilterStyled = styled(ClientFilter)`
   width: 300px;
`;

export default WarehousePalettes;
