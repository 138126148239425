import { faClock, faFire, faPalette } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';

import Context, { findDay } from 'Pages/PaintShopCalendar/context';
import { Response } from 'Shared/Response';
import Error from 'ui/Error';

import IssuesSummary from './IssuesSummary';

const validationSchema = yup.object().shape({
   minutes: yup.number().min(1).max(480).required(),
});

const Issues = ({ index }) => {
   const { refetch } = useContext(Context);
   const [info, setInfo] = useState(null);
   const [error, setError] = useState(false);
   const currentDay = useSelector((s) => s.paintShopCalendar.currentDay);
   const days = useSelector((s) => s.paintShopCalendar.days);
   const day = findDay(days, dayjs(currentDay).format('DD-MM-YYYY'));
   const formik = useFormik({
      initialValues: {
         type: '',
         minutes: '10',
         description: '',
      },
      onSubmit: (values, { setSubmitting }) => {
         setError(false);
         setInfo(null);
         axios
            .post(
               `/paint-shop/calendar/days/${dayjs(currentDay).format(
                  'DD-MM-YYYY',
               )}/shifts/${index}/issues`,
               {
                  ...values,
                  minutes: parseInt(values.minutes, 10),
               },
            )
            .then(() => {
               setInfo('Zmiany zostały zapisane.');
               refetch();
            })
            .catch((err) => {
               if (!err.response?.status) {
                  Sentry.captureException(err);
               }
               setError(true);
               setInfo(err.response?.data?.message ?? 'Wystąpił błąd. Spróbuj jeszcze raz.');
            });
         setSubmitting(false);
      },
      validationSchema,
   });
   const submit = (type) => {
      formik.setFieldValue('type', type);
      formik.submitForm();
   };
   const disabled = !day?.shifts[index];

   return (
      <Container>
         <h3>Awarie</h3>
         <IssuesSummary index={index} />
         <Label>
            <LabelIcon icon={faClock} />
            <LabelContent>Liczba minut</LabelContent>
            <input
               type="number"
               className="form-control"
               name="minutes"
               value={formik.values.minutes}
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
            />
            <Error>
               {formik.touched?.minutes && formik.errors?.minutes && formik.errors.minutes}
            </Error>
         </Label>
         <Submit
            type="button"
            className="btn btn-danger"
            onClick={() => submit('color-change')}
            disabled={disabled}
         >
            <Icon icon={faPalette} />
            Zmiana koloru
         </Submit>
         {formik.values.type === 'color-change' && (
            <ResponseStyled messages={[info]} error={error} />
         )}
         <Submit
            type="button"
            className="btn btn-danger"
            onClick={() => submit('failure')}
            disabled={disabled}
         >
            <Icon icon={faFire} />
            Awaria
         </Submit>
         {formik.values.type === 'furnace-failure' && (
            <ResponseStyled messages={[info]} error={error} />
         )}
         {formik.values.type === 'failure' && <ResponseStyled messages={[info]} error={error} />}
         <Submit
            type="button"
            className="btn btn-danger"
            onClick={() => submit('custom')}
            disabled={disabled}
         >
            <Icon icon={faClock} />
            Inne
         </Submit>
         <Description
            type="text"
            className="form-control"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Dodatkowe informacje"
            rows={3}
         />
         {formik.values.type === 'custom' && <ResponseStyled messages={[info]} error={error} />}
         {disabled && (
            <ResponseStyled
               messages={['By wprowadź awarie, najpierw zapisz zmianę po lewej stronie.']}
               error={false}
            />
         )}
      </Container>
   );
};

const Container = styled.div`
   flex: 1;
   margin-top: 1rem;
`;

const Submit = styled.button`
   width: 100%;
   margin-top: 1rem;
`;

const Icon = styled(FontAwesomeIcon)`
   margin-right: 0.5rem;
`;

const Label = styled.label`
   margin-bottom: 0;
   margin-right: 1rem;
`;

const LabelIcon = styled(FontAwesomeIcon)`
   margin-right: 0.5rem;
`;

const LabelContent = styled.span`
   margin-right: 2rem;
`;

const Description = styled.textarea`
   margin-top: 1rem;
`;

const ResponseStyled = styled(Response)`
   margin-top: 0.5rem;
`;

export default Issues;
