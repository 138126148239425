import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
   ORDER_ATTRIBUTE_COLOR,
   ORDER_ATTRIBUTE_SKIP_PAINTING,
   paintShopPaintOptions,
} from 'Shared/Warehouse/OrderPart';

const now = dayjs().format('DD-MM-YYYY--HH-mm');

const headers = [
   { label: 'Data', key: 'date' },
   { label: 'Id', key: 'zamowieniaId' },
   { label: 'Email', key: 'email' },
   { label: 'Kolor', key: 'color' },
   { label: 'Bez malowania', key: 'skipPainting' },
];

const findOrderColors = (attributes) => {
   const colors = attributes.find((a) => a.key === ORDER_ATTRIBUTE_COLOR);
   if (!colors) {
      return [];
   }
   if (typeof colors.value === 'string') {
      return JSON.parse(colors.value);
   }
   return [];
};

const ExportButton = () => {
   const orders = useSelector((state) => state.warehouse.orders);

   const data = orders.map((o) => {
      const orderColors = findOrderColors(o.attributes);
      const orderColorsString = orderColors.join(', ');
      const skipPainting = o.attributes
         .find((a) => a.key === ORDER_ATTRIBUTE_SKIP_PAINTING)
         ?.value.map((v) => {
            // ESLINT_WARN_TO_BE_FIXED
            // eslint-disable-next-line no-shadow
            return paintShopPaintOptions.find((order) => order.id === v).label;
         })
         .join(', ');

      return {
         date: dayjs(o.addedAt, 'HH:mm DD-MM-YYYY').format('DD.MM.YYYY HH:mm'),
         zamowieniaId: o.zamowieniaId,
         email: o.createdBy.email,
         color: orderColorsString,
         skipPainting,
      };
   });

   return (
      <Container>
         <CSVLinkStyled
            className="btn btn-success btn-icon"
            data={data}
            headers={headers}
            filename={`lakiernia-${now}.csv`}
            style={{ marginBottom: '.5rem' }}
            target="_blank"
            separator=";"
         >
            <FontAwesomeIcon icon={faFileAlt} />
            Pobierz jako CSV
         </CSVLinkStyled>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 1rem;
`;

const CSVLinkStyled = styled(CSVLink)`
   width: 260px;
`;

export default ExportButton;
