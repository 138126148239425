import dayjs from 'dayjs';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import ExportButton from 'Pages/PaintShopCalendar/ExportButton';

import Context, { timeReadable } from './context';

const createMonthLabel = (m) => {
   switch (dayjs(m).month()) {
      case 0:
         return 'w Styczniu';
      case 1:
         return 'w Lutym';
      case 2:
         return 'w Marcu';
      case 3:
         return 'w Kwietniu';
      case 4:
         return 'w Maju';
      case 5:
         return 'w Czerwcu';
      case 6:
         return 'w Lipcu';
      case 7:
         return 'w Sierpniu';
      case 8:
         return 'we Wrześniu';
      case 9:
         return 'w Październiku';
      case 10:
         return 'w Listopadzie';
      case 11:
         return 'w Grudniu';
      default:
         throw new Error(`1bebc680-8e0f-44c6-84b0-d5e0ad4aae15: unknown month (${m})`);
   }
};

const Weeks = () => {
   const { month } = useContext(Context);
   const weeks = useSelector((s) => s.paintShopCalendar.weeks);
   if (!month || !weeks.length) return null;
   const rendered = weeks.map((w) => {
      return (
         <tr key={`${w.from}-${w.to}`}>
            <td>
               {w.from} - {w.to}
            </td>
            <td>{w.amountOfDays}</td>
            <td>{timeReadable(w.minutes)}</td>
            <td>{w.issuesCostsInMinutes} minut</td>
            <td>
               {w.amountOfLogsDone} / {w.amountOfLogs}
            </td>
         </tr>
      );
   });
   return (
      <Container>
         <Header>
            <span>Tygodnie {createMonthLabel(month)}</span>
            <ExportButton />
         </Header>
         <table className="table">
            <thead>
               <tr>
                  <Th>Tydzień</Th>
                  <Th>Dni w tygodniu</Th>
                  <Th>Godziny</Th>
                  <Th>Awarie</Th>
                  <Th>
                     Belki
                     <br />
                     <small>(zrobione / planowane)</small>
                  </Th>
               </tr>
            </thead>
            <tbody>{rendered}</tbody>
         </table>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 3rem;
`;

const Header = styled.h2`
   display: flex;
   justify-content: space-between;
`;

const Th = styled.th`
   vertical-align: top;
`;

export default Weeks;
