import styled from 'styled-components';

const Footer = () => {
   return (
      <Container>
         <div className="container-xxl">
            <div className="row">
               <Inner>
                  <div className="col-sm-6">
                     <Copyright>© Konsport</Copyright>
                  </div>
                  <ContactInfo className="col-sm-6">
                     <span>Jana Kilińskiego 75</span>
                     <span>95-083 Kazimierz</span>
                     <span>+48 43 677 50 63</span>
                     <span>biuro@konsport.com.pl</span>
                  </ContactInfo>
               </Inner>
            </div>
         </div>
      </Container>
   );
};

const Container = styled.footer`
   position: fixed;
   width: 100%;
   bottom: 0;
   left: 0;
   font-size: 12px;
   background: #2d3234;
   padding: 1rem 0 2rem;
`;

const Inner = styled.div`
   display: flex;
   align-items: baseline;
   flex: 1;
`;

const ContactInfo = styled.div`
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   color: #76797a;
   margin: 0 0 0 2rem;
`;

const Copyright = styled.p`
   color: #76797a;
`;

export default Footer;
