import styled from 'styled-components';

import Order from './Order';

const Orders = ({ orders, refetch }) => {
   const rendered = orders.map((o) => <Order key={o.id} order={o} refetch={refetch} />);

   return (
      <Container>
         <div>
            <table className="table">
               <thead>
                  <tr>
                     <th>Nr zamówienia</th>
                     <th>Klient</th>
                     <th>Dodano</th>
                     <th>Lista produktów</th>
                     <th>Kolory</th>
                     <th>Wieszaki</th>
                     <th>Bez malowania</th>
                     <th />
                  </tr>
               </thead>
               <tbody>{rendered}</tbody>
            </table>
         </div>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
   position: relative;
`;

export default Orders;
