import styled, { css } from 'styled-components';

import { PaginatorStyledProps } from './Paginator.types';

export const Paginator = styled.span<PaginatorStyledProps>(
   ({ theme: { spacing, palette, borderRadius }, $color, $variant, $elevation }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${spacing(15)};
      height: ${spacing(15)};
      background: ${palette.background.paginator[$variant][$color]};
      border: 1.5px dashed ${palette.border.paginator[$variant][$color]};
      color: ${palette.text.paginator[$variant][$color]};
      font-weight: 600;
      font-size: 24px;
      border-radius: ${borderRadius(15)};
      flex-shrink: 0;

      ${$elevation &&
      css`
         box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.12);
      `}

      svg {
         fill: ${palette.text.paginator[$variant][$color]};
      }
   `,
);
