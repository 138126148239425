import axios from 'axios';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import InternalContainer from 'Layouts/InternalContainer';
import defaults, { defaultsOneTime } from 'Shared/useQuery';
import SearchGroupByZamowieniaId from 'Shared/Warehouse/SearchGroupByZamowieniaId/SearchGroupByZamowieniaId';

import CheckInOtherOrders from './CheckInOtherOrders/CheckInOtherOrders';
import { Context } from './Context';
import Filters from './Filters/Filters';
import OrdersWithoutGroup from './OrdersWithoutGroup/OrdersWithoutGroup';
import Packed from './Packed/Packed';
import Planing from './Planing/Planing';
import WarehouseGroups from './WarehouseGroups/WarehouseGroups';
import WarehouseGroupsPackedIntoWarehouse from './WarehouseGroupsPackedIntoWarehouse/WarehouseGroupsPackedIntoWarehouse';

export const fetchGroupsReadyToTakeExtraOrders = () =>
   axios.get('/warehouse/order-parts-groups-ready-to-take-extra-orders');

const fetchOrders = () => {
   return axios.get('/warehouse/orders');
};

const fetchClients = () => axios.get('/warehouse/clients');

const groups = (companyName) => {
   return axios.get('/warehouse/groups', {
      params: {
         company_name: companyName,
      },
   });
};

const packedIntoWarehouse = (companyName) => {
   return axios.get('/warehouse/groups/packed-into-warehouse-for-manager', {
      params: {
         company_name: companyName,
      },
   });
};

const WarehouseManager = () => {
   const dispatch = useDispatch();
   const filters = useSelector((s) => s.warehouse.filters);
   const autoFetch = useSelector((s) => s.warehouse.autoFetch);
   const [warehouseGroups, setWarehouseGroups] = useState([]);
   const [warehouseGroupsPackedIntoWarehouse, setWarehouseGroupsPackedIntoWarehouse] = useState([]);
   const { refetch: refetchClients } = useQuery(['fetchClients'], fetchClients, {
      ...defaultsOneTime,
      enabled: !!filters.orderPartsGroupStatus,
      onSuccess: ({ data }) => {
         dispatch({
            type: 'FETCH_CLIENTS',
            clients: data.clients,
         });
      },
   });
   const { refetch: refetchGroupsReadyToTakeExtraOrders } = useQuery(
      ['fetchGroupsReadyToTakeExtraOrders'],
      () => fetchGroupsReadyToTakeExtraOrders(),
      {
         ...defaults,
         onSuccess: ({ data }) => {
            dispatch({
               type: 'ORDER_GROUPS_READY_TO_TAKE_EXTRA_ORDERS_FETCHED',
               payload: {
                  orderGroups: data.groups,
               },
            });
         },
      },
   );
   const {
      isLoading: isLoadingOrders,
      refetch: refetchOrders,
      dataUpdatedAt: dataUpdatedAtOrders,
   } = useQuery(['fetchOrders'], fetchOrders, {
      ...defaults,
      enabled: autoFetch,
      onSuccess: ({ data }) => {
         dispatch({
            type: 'WAREHOUSE_ORDERS_FETCHED',
            payload: {
               orders: data.orders,
            },
         });
      },
   });
   const {
      error: errorWarehouseGroups,
      isLoading: isLoadingWarehouseGroups,
      refetch: refetchWarehouseGroups,
      dataUpdatedAt: dataUpdatedAtWarehouseGroups,
   } = useQuery(
      ['warehouseGroups', { companyName: filters.companyName }],
      () => groups(filters.companyName),
      {
         ...defaults,
         enabled: autoFetch,
         onSuccess: ({ data }) => {
            setWarehouseGroups(data.groups);
         },
      },
   );
   const {
      error: errorWarehouseGroupsPackedIntoWarehouse,
      isLoading: isLoadingWarehouseGroupsPackedIntoWarehouse,
      refetch: refetchWarehouseGroupsPackedIntoWarehouse,
      dataUpdatedAt: dataUpdatedAtWarehouseGroupsPackedIntoWarehouse,
   } = useQuery(
      ['warehouseGroupsPackedIntoWarehouse', { companyName: filters.companyName }],
      () => packedIntoWarehouse(filters.companyName),
      {
         ...defaults,
         enabled: autoFetch,
         onSuccess: ({ data }) => {
            setWarehouseGroupsPackedIntoWarehouse(data.groups);
         },
      },
   );
   const refetch = () => {
      refetchGroupsReadyToTakeExtraOrders();
      refetchOrders();
      refetchWarehouseGroups();
      refetchWarehouseGroupsPackedIntoWarehouse();
      refetchClients();
   };

   const value = useMemo(
      () => ({
         refetch,
      }),
      [refetch],
   );

   return (
      <Context.Provider value={value}>
         <Helmet>
            <title>Magazyn | Konsport</title>
         </Helmet>
         <InternalContainer>
            <div className="row">
               <div className="col">
                  <HeaderContainer>
                     <h1>Magazyn</h1>
                  </HeaderContainer>
                  <SearchGroupByZamowieniaId />
                  <CheckInOtherOrders />
                  <Filters />
                  <Packed />
                  <OrdersWithoutGroup
                     isLoading={isLoadingOrders}
                     dataUpdatedAt={dataUpdatedAtOrders}
                  />
                  <WarehouseGroupsPackedIntoWarehouse
                     groups={warehouseGroupsPackedIntoWarehouse}
                     error={errorWarehouseGroupsPackedIntoWarehouse}
                     isLoading={isLoadingWarehouseGroupsPackedIntoWarehouse}
                     dataUpdatedAt={dataUpdatedAtWarehouseGroupsPackedIntoWarehouse}
                  />
                  <WarehouseGroups
                     groups={warehouseGroups}
                     error={errorWarehouseGroups}
                     isLoading={isLoadingWarehouseGroups}
                     dataUpdatedAt={dataUpdatedAtWarehouseGroups}
                  />
                  <Planing />
               </div>
            </div>
         </InternalContainer>
      </Context.Provider>
   );
};

const HeaderContainer = styled.div`
   margin-bottom: 2rem;
`;

export default WarehouseManager;
