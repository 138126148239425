import axios from 'axios';
import { FormikProvider, useFormik } from 'formik';
import { useContext } from 'react';

import { Context } from '../Context';

import FormInput from './FormInput';
import PackBtn from './Group/PackBtn';

const PaletteForm = ({ group, orderPart }) => {
   const { refetchForPacking, refetchForPackingForSending } = useContext(Context);

   const formik = useFormik({
      initialValues: {
         attributes: orderPart.palette?.attributes || [],
      },
      onSubmit: (values) => {
         const data = {
            attributes: values.attributes,
         };

         axios
            .post(
               `/warehouse/order-groups/${group.id}/order-parts/${orderPart.id}/pack-palette`,
               data,
            )
            .then(() => {
               refetchForPacking();
               refetchForPackingForSending();
            });
      },
   });

   const rendered = orderPart.palette.requiredAttributes.map((attr) => {
      return <FormInput key={attr} attribute={attr} palette={orderPart.palette} />;
   });

   return (
      <div>
         <FormikProvider value={formik}>
            <PackBtn orderPart={orderPart} group={group} />
            <div>{rendered}</div>
         </FormikProvider>
      </div>
   );
};

export default PaletteForm;
