import { useSelector } from 'react-redux';
import styled from 'styled-components';

import UseQueryStates from 'Shared/UseQueryStates';
import { packingPlacesForklift } from 'Shared/Warehouse/OrderPartsGroup';

import OrderPart from './OrderPart';

const NothingToDeliver = () => {
   return (
      <NothingToDeliverContainer>
         <span className="h3">Wszystko dostarczone, dobra robota!</span>
      </NothingToDeliverContainer>
   );
};

const getSubHeader = (packingPlace) => {
   if (!packingPlace) return '';
   return packingPlacesForklift[packingPlace];
};

const ToBeDelivered = ({ error, dataUpdatedAt, isLoading }) => {
   const groupsToBeDelivered = useSelector((s) => s.warehouse.groupsToBeDelivered);
   const packingPlace = useSelector((s) => s.warehouse.filters.packingPlace);
   const rendered = groupsToBeDelivered.map((opg) => {
      return opg.orderParts.map((op) => <OrderPart key={op.id} group={opg} orderPart={op} />);
   });
   const subHeader = getSubHeader(packingPlace);

   return (
      <>
         <Section>
            <div>
               <h2>
                  Do dostarczenia
                  {subHeader && <small> {subHeader}</small>}
               </h2>
            </div>
            <div>
               {rendered && (
                  <table className="table">
                     <thead>
                        <tr>
                           <th>Id grupy</th>
                           <th>Nr zamówienia</th>
                           <th>Klient</th>
                           <th>Od decyzji</th>
                           <th>Gdzie</th>
                           <th>Produkty</th>
                           <th />
                        </tr>
                     </thead>
                     <tbody>{rendered}</tbody>
                  </table>
               )}
            </div>
            {!rendered.length && <NothingToDeliver />}
         </Section>
         <UseQueryStates error={error} updatedAt={dataUpdatedAt} isLoading={isLoading} />
      </>
   );
};

const NothingToDeliverContainer = styled.div`
   text-align: center;
`;

const Section = styled.section`
   margin-top: 2rem;
`;

export default ToBeDelivered;
