const validate = (originalMap, parts) => {
   const errors = [];

   originalMap.forEach((om) => {
      const expectedAmount = om.amount;
      const amount =
         parts.reduce((pAcc, part) => {
            return (
               pAcc +
               part.products.reduce((acc, p) => {
                  if (p.originalProduct.rawArray.nr_produkt === om.id) {
                     // ESLINT_WARN_TO_BE_FIXED
                     // eslint-disable-next-line radix
                     return acc + parseInt(p.amount);
                  }
                  return acc;
               }, 0)
            );
         }, 0) || 0;

      const message = `zbyt ${amount > expectedAmount ? 'duża' : 'mała'} ilość "${om.name} (${
         om.id
      }): spodziewana ${expectedAmount} | otrzymana ${amount}.`;
      if (expectedAmount !== amount) {
         errors.push({
            id: om.id,
            expectedAmount,
            amount,
            message,
         });
      }
   });

   return errors;
};

export default validate;
