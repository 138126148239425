import * as S from './CircularProgress.styled';
import type { Props } from './CircularProgress.types';

export const CircularProgress = ({ size = 16, position = 'relative' }: Props): JSX.Element => {
   return (
      <S.Wrapper position={position}>
         <S.CircularProgressBase size={size} />
      </S.Wrapper>
   );
};
