import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Shift from './Shift/Shift';

const today = dayjs().format('MM/DD/YYYY');

const calculateDate = (currentDay, action) => {
   switch (action) {
      case 'previous':
         return dayjs(currentDay).subtract(1, 'day');
      case 'next':
         return dayjs(currentDay).add(1, 'day');
      default:
         throw new Error('unknown delta');
   }
};

const CurrentDay = () => {
   const dispatch = useDispatch();
   const currentDay = useSelector((s) => s.paintShopCalendar.currentDay);
   if (!currentDay) return null;
   const previousDay = currentDay === today ? 'wczoraj' : 'poprzedni dzień';
   const nextDay = currentDay === today ? 'jutro' : 'następny dzień';
   const goToDay = (action) => {
      const cd = calculateDate(currentDay, action);
      dispatch({ type: 'OPEN_DAY', currentDay: cd.format('MM/DD/YYYY') });
   };
   return (
      <Container>
         <div className="row">
            <div className="col">
               <HeaderContainer>
                  <PreviousDayButton className="btn" onClick={() => goToDay('previous')}>
                     <PreviousDayIcon icon={faChevronLeft} />
                     {previousDay}
                  </PreviousDayButton>
                  <Header>{dayjs(currentDay).format('dddd DD MMMM YYYY')}</Header>
                  <NextDayButton className="btn" onClick={() => goToDay('next')}>
                     {nextDay}
                     <NextDayIcon icon={faChevronRight} />
                  </NextDayButton>
               </HeaderContainer>
               <Shift index={0} />
               <Shift index={1} />
            </div>
         </div>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 3rem;
`;

const HeaderContainer = styled.div`
   display: flex;
   justify-content: space-between;
   margin-bottom: 1rem;
`;

const PreviousDayButton = styled.button``;

const PreviousDayIcon = styled(FontAwesomeIcon)`
   margin-right: 1rem;
`;

const Header = styled.h2``;

const NextDayButton = styled.button``;

const NextDayIcon = styled(FontAwesomeIcon)`
   margin-left: 1rem;
`;

export default CurrentDay;
