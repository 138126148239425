import styled from 'styled-components';

const Container = styled.div`
   background: #fff;
   border-radius: 4px;
   padding-top: 1rem;
   padding-bottom: 1rem;
   margin-bottom: 6rem;
`;

const InternalContainer = ({ children }) => {
   return <Container className="container-xxl">{children}</Container>;
};

export default InternalContainer;
