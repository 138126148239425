import { faChevronDown, faChevronUp, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';

import Day from 'Pages/PaintShopStatistics/Day';

const Container = styled.div`
   margin-bottom: 2rem;
`;

const Header = styled.div`
   display: flex;
   justify-content: space-between;
`;

const Title = styled.div`
   display: flex;
`;

const now = dayjs().format('DD-MM-YYYY--HH-mm');
const headers = [
   { label: 'Dzień', key: 'day' },
   { label: 'Belkowo', key: 'logs' },
   { label: 'Punktowo', key: 'points' },
   { label: 'Produkty', key: 'products' },
];

const Month = ({ title, month }) => {
   const [show, setShow] = useState(true);
   const rendered = month.days.map((d) => <Day key={d.startedPaintingAt} day={d} />);
   const data = month.days.map((d) => {
      return {
         day: d.day,
         logs: d.log,
         points: d.weight,
      };
   });

   return (
      <Container className="col">
         <Header>
            <Title>
               <button type="button" className="btn" onClick={() => setShow((s) => !s)}>
                  {show && <FontAwesomeIcon icon={faChevronDown} />}
                  {!show && <FontAwesomeIcon icon={faChevronUp} />}
               </button>
               <h2>{title}</h2>
            </Title>
            <CSVLink
               className="btn btn-icon-left"
               type="button"
               data={data}
               headers={headers}
               filename={`lakiernia-podsumowanie-${now}.csv`}
               target="_blank"
               separator=";"
            >
               <FontAwesomeIcon icon={faDownload} />
               Zapisz
            </CSVLink>
         </Header>
         {show && (
            <table className="table">
               <thead>
                  <tr>
                     <th>Dzień</th>
                     <th>Punktowo</th>
                     <th>Belkowo</th>
                     <th>Zamówienia</th>
                  </tr>
               </thead>
               <tbody>{rendered}</tbody>
            </table>
         )}
      </Container>
   );
};

export default Month;
