import { useFormikContext } from 'formik';

import { PALETTE_ATTRIBUTES_KEY_NAMES } from 'Shared/palette';

const FormInput = ({ attribute }) => {
   const formik = useFormikContext();

   const { attributes } = formik.values;
   const attributeIndex = attributes.some((a) => a.key === attribute)
      ? attributes.indexOf(attributes.find((a) => a.key === attribute))
      : attributes.length;

   return (
      <label key={attribute}>
         {PALETTE_ATTRIBUTES_KEY_NAMES[attribute] || ''}
         <input
            type="text"
            id={`attributes[${attributeIndex}].value`}
            name={`attributes[${attributeIndex}].value`}
            className="form-control"
            onChange={(e) =>
               formik.setFieldValue(`attributes[${attributeIndex}]`, {
                  key: attribute,
                  value: e.target.value,
               })
            }
            onBlur={formik.handleBlur}
            value={attributes[attributeIndex]?.value || ''}
            placeholder={PALETTE_ATTRIBUTES_KEY_NAMES[attribute] || ''}
         />
      </label>
   );
};

export default FormInput;
