export const common_en = {
   button: {
      add_discount: 'Discount',
      add_newLink: 'Add new Link',
      add_newProduct: 'Add new product',
      add_nextProduct: 'Add next product',
      add_product: 'Add product',
      add_toPricing: 'Add to pricing',
      add_user: 'Add user',
      archive: 'Archive',
      cancel: 'Cancel',
      close: 'Close',
      copyCode: 'Copy code',
      delete: 'Delete',
      discount: 'Discount %',
      download: 'Download',
      duplicate: 'Duplicate',
      edit: 'Edit',
      edit_product: 'Edit product',
      enova: 'Enova codes',
      myAccount: 'My account',
      pdf_download: 'Download PDF',
      pdf_generating: 'generating PDF . . . ',
      print: 'Print',
      publicPreview: 'Public preview',
      removeLink: 'Remove link',
      save: 'Save',
      save_changes: 'Save changes',
      save_color: 'Save color',
      save_estimate: 'Save as estimate',
      save_newPassword: 'Save new password',
      save_password: 'Save password',
      sendForCompletion: 'Send for Completion',
      statisticId: 'Statistic id',
      technicalCatalogue: 'Go to technical catalogue',
      usage_add: 'Add usage',
      usage_addAnother: 'Add another usage',
      usage_delete: 'Delete usage',
   },
   checkbox: {
      announce_accepted: 'Announce, that aaccept ',
      announce_acknowledged: 'Announce, that acknowleged ',
      checkAll: 'Check all',
      termsOfUse: 'terms of use',
      generalTermsOfSale: 'general terms of sale',
      productCard: 'product card',
      sendEmail: 'Send estimate via e-mail',
   },
   description: {
      sourceId1:
         '<SourceIdData>Source ID</SourceIdData> that is product code introduce only when code is final/complete. <br />For instance code <SourceIdData>P/1028</SourceIdData> for <SourceIdData>Powder paint RAL 1028</SourceIdData> <u>is complete code</u> and doesnt need further complement, so we need put it in field above. But code <SourceIdData>K/60-40-1,25/R/</SourceIdData> for <SourceIdData>POLE 60X40X1,25MM OCYNK + RAL</SourceIdData> <u>need complement</u> in the form of pole height, so finally it will look: <SourceIdData>K/60-40-1,25/R/150</SourceIdData> so that we can not put it in field above.',
      sourceId2:
         'Defined <SourceIdData>Source ID</SourceIdData> for product is not explicit with correct code generating for <SourceIdData>Enova</SourceIdData> on charging stage. Sometimes there is need for further steps of code generativy software.',
   },
   error: {
      errorOccurred: 'Error occurred.',
      getPrice: 'Unfortunately getting price failed',
      incorrectLoginOrPassword: 'Incorrect login or password.',
      order_notFound: 'Order not found',
      order_notUpdated: 'Faild to update order',
      order_updated: 'Order updated!',
      product_delete: 'Failed to delete product',
      relatedProducts: 'Error occurred when downloading related products.',
      somethingWentWrong: 'Something went wrong',
      tryAgain: 'Error occured. Try again.',
      tryAgain_incorrectEmail: 'Incorrct email. Try again.',
      tryAgain_estimateUpdate: 'Error occured during updating estimate. Try again.',
      pdfFailed: 'PDF generating failed.',
   },
   message: {
      accountInfo: 'Company info will be visible <br />on generated by you orders',
      addedLogo: 'Added logo',
      addedUser: 'Added user successfully',
      addNextProduct: 'Is this the order end? Add next product',
      browse: 'Browse',
      coppied: 'Coppied',
      dragHere: 'Drag file here, or',
      dropHere: 'Drop file here',
      extraCharge: '{{extraCharge}}% extra charge',
      gettingPrice: 'Getting price in progress',
      lackOfEstimates: 'Lack of estimates',
      loading: 'Loading...',
      logIn: 'Check prices, create orders.<br />Quick and comfortable.',
      noEnovaErrorsInfo: 'No error and Enova codes info.',
      none: 'None',
      noModelsFound: 'No models found',
      noProductsFound: 'No products found',
      noRelatedProducts: 'No related products',
      passwordRemind: 'Remember, password should be known only by user.',
      password_notEqual: 'Passwords are not equal. Try again.',
      password_repeated: 'Repeated password has to be equal password.',
      pdfInfo:
         'Document generated automatically from Konsport estimation system. Document with price and assortment is updated a day its generation.',
      pricedAt: 'Priced at:',
      priceRefresh:
         'By pressing the <strong>"Edit"</strong> button, you will be able to freely change the product features in the cost estimate. Remember that changing any product parameter will result in its recalculation based on the most current prices. After editing, you can resend the estimate to the B2C client. The B2C customer will receive an updated version of the cost estimate, taking into account all changes in product features and possible new price calculations resulting from the changes.',
      processing: 'Processing...',
      productNotServed: 'Product not served currently',
      productUpdated: 'Product updated successfully',
      savedChanges: 'Saved changes',
      savings: 'Savings',
      summary: 'Your new order summary will show up here ...',
      tooManyRoles: 'User has too many roles.',
      totalWithSavings: 'Total with savings',
   },
   label: {
      accessoriesWithoutTax: 'accessories price without tax',
      address: 'Adress',
      address_continued: 'Adress continued',
      attachment: 'Attachment ({{ordinalNumber}})',
      changeUserRole: 'Change user role',
      clientAddress: 'Client address',
      colorRequired: 'Color required',
      companyName: 'Company name',
      email: 'Email adress',
      email_business: 'Business email adress',
      fencingElement: 'Fencing element',
      galvanized: 'Galvanized',
      internalOrder: 'Internal order',
      maxWidth: 'Max width',
      model: 'Model',
      more: 'More...',
      myProducts: 'My products',
      name: 'Name',
      new_plural: 'New',
      newClient: 'New client',
      openingHours: 'Opening hours',
      password: 'Password',
      password_change: 'Change user password',
      password_repeat: 'Repeat password',
      phoneNumber: 'Phone number',
      priceWithDiscount: 'Price with discount',
      provideEstimateTitle: 'Provide estimate title',
      phone: 'Phone',
      simplifiedView: 'Simplified view',
      sourceId: 'Source ID',
      statisticId: 'Statistic id',
      taxId: 'Tax id',
      usage: 'Usage',
      website: 'Website',
      palettes: 'Palettes',
   },
   link: {
      addProduct: 'Add product',
      backToLogin: 'Back to login',
      forgotPassword: 'Forgot password?',
      logIn: 'Log in',
      logOut: 'Log out',
      news: 'News',
      products: 'Products',
      technicalCatalogue: 'Technical catalogue link',
   },
   placeholder: {
      colorsSection: 'Type color name from RAL pallette...',
      johnSmith: 'ex. John Smith, fencing',
      provideModelName: 'Provide model name',
      searchByQuery: 'Type 3 letters to start searching...',
      searchByType: 'Select fencing element',
      selectFencingElement: 'Select fencing element',
      startSearching: 'Start searching',
   },
   table: {
      actions: 'Actions',
      amount: 'Amount',
      data: 'Data',
      date: 'Date',
      name: 'Name',
      ordinalNumber: 'No.',
      products: 'Products',
      productFeatures: 'Product features',
      vat: 'VAT',
   },
   title: {
      accountInfo: 'Account information',
      adminOptions: 'Admin options',
      chosenModel: 'Chosen model:',
      colors_popular: 'Popular colors',
      colors_standard: 'Standard colors',
      colors_other: 'Other colors (extra charge required)',
      company: 'Company',
      companyInfo: 'Company information',
      companyLogo: 'Company logo',
      creationDate: 'Creation date',
      editProduct: 'Update product',
      estimate: 'Estimate ',
      estimate_date: 'Estimate date: ',
      estimate_plural: 'Estimates ',
      id: 'ID',
      language: 'Select language (selected: {{language}})',
      newEstimate: 'New estimate',
      noEstimates: 'No estimates',
      new_plural: 'New',
      order_total: 'Order total: ',
      order_totalWithDiscount: 'Order total with discount: ',
      orders: 'Orders',
      productList: 'Product list',
      productsAddedToOrder: 'Products added to order',
      remainingProducts: 'Remaining products',
      relatedProducts: 'Related products',
      searchResults: 'Search results for: ',
      selectedColor: 'Selected color',
      selectFencingColor: 'Select your fencing color',
      fencingColor: 'Your fencing color',
      settings: 'Settings',
      summary: 'Summary',
      user: 'User: {{name}}',
      user_plural: 'Users',
      yourData: 'Your data',
      importantInformation: 'Important information',
   },
   shared: {
      addProduct: 'Add product',
      clientPanel: 'Client panel',
      discount: 'Discount',
      no: 'No',
      percent: '{{percent}}%',
      price: 'Price',
      price_withTax: 'Price with Tax',
      price_withoutTax: 'Price without Tax',
      quantity: 'Quantity',
      search: 'Search',
      select: 'Select',
      total: 'Total',
      totalWithTax: 'Total with tax',
      viewDetails: 'View details',
      withDiscount: 'With discount',
      withDiscount_withColon: 'with discount: ',
      withTax: 'With tax',
      withTax_withColon: 'with tax: ',
      withoutTax: 'without tax',
      withoutTax_withColon: 'without tax: ',
      yes: 'Yes',
   },
   status: {
      discount: 'Discount {{discount}}%',
   },
};
