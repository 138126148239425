import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import styled from 'styled-components';

const today = dayjs();

const DateRangeCalendar = ({
   className,
   onChange,
   info = null,
   minDays = 2,
   maxDays = 31,
   defaultAmountOfDays = 31,
}) => {
   const initialFrom = today.subtract(defaultAmountOfDays, 'days').toDate();
   const initialRange = { from: initialFrom, to: today.toDate() };
   const [range, setRange] = useState(initialRange);
   const [error, setError] = useState(null);
   const change = (d) => {
      setError(null);
      const [fromRaw, toRaw] = d;
      setRange({ from: fromRaw, to: toRaw });
      const from = dayjs(fromRaw);
      const to = dayjs(toRaw);
      const diff = to.diff(from, 'days');
      if (diff > maxDays) {
         setError(`maksymalny zakres to ${maxDays} dni`);
         return;
      }
      if (diff + 1 < minDays) {
         setError(`minimalny zakres to ${minDays} dni`);
         return;
      }
      onChange({ from, to });
   };
   useEffect(() => {
      onChange(range);
   }, []);
   const humanFrom = dayjs(range?.from).format('DD (dddd) MM-YYYY');
   const humanTo = dayjs(range?.to).format('DD (dddd) MM-YYYY');

   return (
      <div className={className}>
         <Calendar
            onChange={change}
            value={[range?.from, range?.to]}
            className="sm"
            locale="pl"
            selectRange
         />
         {!error && (
            <Text>
               {info} od {humanFrom} do {humanTo}
            </Text>
         )}
         {error && <Error>{error}</Error>}
         <DefaultRangeButton
            className="btn btn-link"
            type="button"
            onClick={() => setRange(initialRange)}
         >
            przywróć domyśłny zakres
         </DefaultRangeButton>
      </div>
   );
};

const Text = styled.span`
   width: 100%;
   display: inline-block;
   text-align: center;
   margin: 0.5rem 0;
`;

const Error = styled.span`
   width: 100%;
   display: inline-block;
   text-align: center;
   margin-top: 0.5rem;
   color: #ff0000;
`;

const DefaultRangeButton = styled.button`
   width: 100%;
   text-align: center;
`;

export default DateRangeCalendar;
