import { t } from 'i18next';
import * as yup from 'yup';

yup.addMethod(yup.number, 'dividedBy', function dividedBy(num: number) {
   return this.test(
      'dividedBy',
      () => t('form:validation.number.dividedBy', { dividedBy: num }),
      function dividedByTest(value: number) {
         return Number(value) % num === 0;
      },
   );
});

yup.setLocale({
   mixed: {
      required: () => t('form:validation.mixed.required'),
   },
   string: {
      min: ({ min }) => t('form:validation.string.min', { min }),
      max: ({ max }) => t('form:validation.string.max', { max }),
      email: () => t('form:validation.string.email'),
   },
   number: {
      min: ({ min }) => t('form:validation.number.min', { min }),
      max: ({ max }) => t('form:validation.number.max', { max }),
      integer: () => t('form:validation.number.integer'),
      moreThan: ({ more }) => t('form:validation.number.moreThan', { more }),
      lessThan: ({ less }) => t('form:validation.number.lessThan', { less }),
   },
});

export default yup;
