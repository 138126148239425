export const colors = {
   white: '#ffffff',
   green: '#28a745',
   success: '#28a745',
   neutral10: '#F2F2F2',
   neutral20: '#E5E5E5',
   neutral30: '#CACBCC',
   neutral40: '#959899',
   neutral50: '#808080',
   neutral60: '#484D4E',
   neutral70: '#2d3234',
   black: '#000000',
   primary: '#E50019',
   primary20: '#C70016',
};
