export const extractUniqueClientsFromOrderGroups = (orderGroups) => {
   const clients = [];

   if (orderGroups.length) {
      orderGroups.reduce((clientsAcc, og) => {
         clientsAcc.push(og.companyName);
         return clientsAcc;
      }, clients);
   }

   return [...new Set(clients)];
};
