import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components';

import { removeAttribute } from 'Shared/PaintShop/order';
import { Response } from 'Shared/Response';
import { ORDER_ATTRIBUTE_SKIP_PAINTING, paintShopPaintOptions } from 'Shared/Warehouse/OrderPart';

import { Context } from '../Context';

const PaintOptions = ({ order, refetch }) => {
   const { manager, setLoading } = useContext(Context);
   const [info, setInfo] = useState(null);
   const [error, setError] = useState(false);
   if (manager) return null;
   const saved = order.attributes.find((a) => a.key === ORDER_ATTRIBUTE_SKIP_PAINTING)?.value || [];
   const save = (v, e) => {
      setInfo(null);
      setError(false);
      setLoading(true);
      const values = e.currentTarget.checked ? [...saved, v] : removeAttribute(saved, v);
      axios
         .post(`paint-shop/orders/${order.id}/skip-painting `, { values })
         .then(refetch)
         .catch((err) => {
            if (!err.response?.status) {
               Sentry.captureException(err);
            }
            setError(true);
            setInfo(err.response?.data?.message ?? 'Wystąpił błąd. Spróbuj jeszcze raz.');
         })
         .finally(() => setLoading(false));
   };
   const optionsRendered = paintShopPaintOptions.map((o) => {
      return (
         <div key={Math.random()} className="form-group form-check">
            <input
               type="checkbox"
               className="form-check-input"
               id={`${order.id}--${o.id}`}
               checked={saved.includes(o.id)}
               onChange={(e) => save(o.id, e)}
            />
            <label className="form-check-label" htmlFor={`${order.id}--${o.id}`}>
               {o.label}
            </label>
         </div>
      );
   });

   return (
      <Container>
         <div>
            <span>Bez malowania</span>
         </div>
         {optionsRendered}
         <Response error={error} messages={[info]} />
      </Container>
   );
};

const Container = styled.div`
   display: flex;
   flex-direction: column;
   position: relative;
`;

export default PaintOptions;
