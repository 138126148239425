import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { timeReadable } from './context';

const now = dayjs().format('DD-MM-YYYY--HH-mm');

const headers = [
   { label: 'Dzień', key: 'day' },
   { label: 'Belek', key: 'logs' },
   { label: 'Godziny', key: 'hours' },
   { label: 'Awarie', key: 'issues' },
];

const ExportButton = () => {
   const days = useSelector((s) => s.paintShopCalendar.days);
   const data = days.map((d) => {
      const issues = d.shifts.reduce((prev, curr) => {
         return prev + curr.issuesCostsInMinutes;
      }, 0);
      return {
         day: d.day,
         logs: d.amountOfLogs,
         hours: timeReadable(d.minutes),
         issues: timeReadable(issues),
      };
   });

   return (
      <CSVLinkStyled
         className="btn btn-success btn-icon"
         data={data}
         headers={headers}
         filename={`lakiernia-${now}.csv`}
         target="_blank"
         separator=";"
      >
         <FontAwesomeIcon icon={faFileAlt} />
         Pobierz jako CSV
      </CSVLinkStyled>
   );
};

const CSVLinkStyled = styled(CSVLink)`
   width: 248px;
`;

export default ExportButton;
