import dayjs from 'dayjs';

import { STATUS_PACKED, STATUS_PACKED_INTO_WAREHOUSE } from 'Shared/Warehouse/OrderPartsGroup';
import { paletteTypes } from 'Shared/Warehouse/Palette';

const OrdersGroup = ({ ordersGroup }) => {
   const palettesModifiedAt = [];
   const paletteSizes = [];
   const ids = [];
   const types = [];
   const amounts = [];
   ordersGroup.orderParts.forEach((op) => {
      const p = op.finalPalette || op.palette;
      palettesModifiedAt.push(
         <p key={`${p.id}--modifiedAt`} style={{ minHeight: '24px' }}>
            {p.createdAt && dayjs(p.createdAt).format('DD.MM.YYYY HH:mm')}
         </p>,
      );
      types.push(<p key={`${p.id}--paletteType`}>{paletteTypes[p.type]}</p>);
      paletteSizes.push(
         <p key={`${p.id}--paletteSizes`}>
            {p?.width || '_'} x {p.height || '_'} x {p?.length || '_'}
         </p>,
      );
      amounts.push(
         p.amount ? (
            <p key={`${p.id}--paletteAmount`}>{p.amount}</p>
         ) : (
            <p key={`${p.id}--paletteAmount`}>-</p>
         ),
      );
      ids.push(
         <p key={`${op.order.id}--linkToOrder`}>
            <a href={`/orders/${op.order.id}`} target="_blank" rel="noopener noreferrer">
               {op.order.zamowieniaId}
            </a>
         </p>,
      );
   });

   return (
      <tr>
         <td>
            {ordersGroup.companyName} (
            {dayjs(ordersGroup.createdAt.date).format('DD.MM.YYYY HH:mm')})
         </td>
         <td>{ordersGroup.orderParts[0].order.userAccountManager}</td>
         <td>{palettesModifiedAt}</td>
         <td>{ids}</td>
         <td>{types}</td>
         <td>{paletteSizes}</td>
         <td>{amounts}</td>
         {ordersGroup.status === STATUS_PACKED_INTO_WAREHOUSE && <td>Niekompletne</td>}
         {ordersGroup.status === STATUS_PACKED && <td>Spakowane na gotowo</td>}
      </tr>
   );
};

export default OrdersGroup;
