import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
   STATUS_PACKED_INTO_WAREHOUSE,
   STATUS_TO_BE_SEND,
   STATUS_SENT,
} from 'Shared/Warehouse/OrderPartsGroup';
import { filterChanged } from 'state/warehouse';

const OrderPartsGroupStatusFilter = ({ className }) => {
   const dispatch = useDispatch();
   const value = useSelector((state) => state.warehouse.filters.orderPartsGroupStatus);

   return (
      <div className={className}>
         <Label>
            Status grupy
            <select
               className="form-control"
               onChange={(e) =>
                  dispatch(filterChanged('orderPartsGroupStatus', e.target.value || ''))
               }
               onBlur={() => {
                  //
               }}
               value={value}
            >
               <option value="" />
               <option value={STATUS_PACKED_INTO_WAREHOUSE}>TOWAR NA STREFIE</option>
               <option value={STATUS_TO_BE_SEND}>SPAKOWANE DO WYSYŁKI</option>
               <option value={STATUS_SENT}>WYSŁANE</option>
            </select>
         </Label>
      </div>
   );
};

const Label = styled.label`
   width: 100%;
`;

export default OrderPartsGroupStatusFilter;
