import dayjs from 'dayjs';
import ReactCountdown from 'react-countdown';
import styled from 'styled-components';

// eslint-disable-next-line import/no-cycle
import { colors } from 'deprecatedTheme/colors';
// eslint-disable-next-line import/no-cycle
import {
   orderPartsGroupStatuses,
   packingPlaces,
   STATUS_PACKED,
   STATUS_PACKED_INTO_WAREHOUSE,
   STATUS_TO_BE_PACKED,
   STATUS_TO_BE_PACKED_INTO_WAREHOUSE,
} from 'Shared/Warehouse/OrderPartsGroup';

// eslint-disable-next-line no-unused-vars
const countdownRenderInline = ({ hours, minutes, completed }) =>
   completed ? (
      <>
         <br />
         ZAKOŃCZONA
      </>
   ) : (
      <>
         , pozostało:
         <br />
         {hours} godzin {minutes} minut
      </>
   );

const BigRed = styled.p`
   color: ${colors.primary};
`;

export const Countdown = ({ date }) => (
   <div>
      <BigRed>
         Lakiernia ({date.format('DD.MM.YYYY HH:mm')})
         <ReactCountdown renderer={countdownRenderInline} date={date.add(2, 'h').toDate()} />
      </BigRed>
   </div>
);

const External = styled.span`
   font-weight: bold;
   color: ${(props) => props.color};
`;

const Separator = styled.span`
   margin: 0 0.5rem;
`;

export const getColorForOrderGroupStatus = (status, externalClient) => {
   if (externalClient && status === STATUS_TO_BE_PACKED) {
      return '#007bff';
   }

   switch (status) {
      case STATUS_PACKED:
      case STATUS_TO_BE_PACKED:
         return colors.green;
      case STATUS_PACKED_INTO_WAREHOUSE:
      case STATUS_TO_BE_PACKED_INTO_WAREHOUSE:
         return '#ffc107';
      default:
         return '#343a40';
   }
};

export const CompanyName = ({ orderPartsGroup, createdByExternalClient }) => {
   const color = getColorForOrderGroupStatus(orderPartsGroup.status, createdByExternalClient);

   return (
      <>
         <span>{orderPartsGroup.companyName}</span>
         {createdByExternalClient && (
            <>
               <Separator>|</Separator>
               <External color={color}>KLIENT OBCY</External>
            </>
         )}
      </>
   );
};

const OrderPartsGroupCreatedAtContainer = styled.div``;

export const OrderPartsGroupCreatedAt = ({ orderPartsGroup }) => (
   <OrderPartsGroupCreatedAtContainer>
      <span>
         Data utworzenia grupy: {dayjs(orderPartsGroup.createdAt).format('DD.MM.YYYY HH:mm')}
      </span>
   </OrderPartsGroupCreatedAtContainer>
);

export const OrderPartsGroupStatus = ({ orderPartsGroup, createdByExternalClient }) => {
   const color = getColorForOrderGroupStatus(orderPartsGroup.status, createdByExternalClient);

   return (
      <>
         <Separator>|</Separator>
         <External color={color}>
            <span>
               {orderPartsGroupStatuses[orderPartsGroup.status]}{' '}
               {(orderPartsGroup.status === STATUS_TO_BE_PACKED ||
                  orderPartsGroup.status === STATUS_PACKED) &&
                  orderPartsGroup.packingPlace &&
                  ` ${packingPlaces[orderPartsGroup.packingPlace]}`}
            </span>
         </External>
      </>
   );
};

export const OrdersGroupName = ({ ordersGroup }) => {
   return (
      <div>
         <CompanyName
            orderPartsGroup={ordersGroup}
            createdByExternalClient={ordersGroup.orderParts[0].order.createdByExternalClient}
         />
         <OrderPartsGroupStatus
            orderPartsGroup={ordersGroup}
            createdByExternalClient={ordersGroup.orderParts[0].order.createdByExternalClient}
         />
         <OrderPartsGroupCreatedAt orderPartsGroup={ordersGroup} />
      </div>
   );
};

export const DatePickerLabel = styled.label`
   display: flex;
   flex-direction: column;

   input {
      border-radius: 0.25rem;
      border: 1px solid #ced4da;
      padding: 0.375rem 0.75rem;
      height: calc(1.5em + 0.75rem + 2px);
   }
`;
