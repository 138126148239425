import styled from 'styled-components';

import { WrapperProps } from './Stack.types';

export const Wrapper = styled.div<WrapperProps>`
   display: flex;
   width: 100%;
   flex-direction: ${({ $direction }) => $direction};
   gap: ${({ theme: { spacing }, $gap }) => spacing($gap)};
   justify-content: ${({ $justifyContent }) => $justifyContent ?? 'initial'};
   align-items: ${({ $alignItems }) => $alignItems ?? 'initial'};
   margin-top: ${({ $mt, theme: { spacing } }) => spacing($mt)};
   margin-bottom: ${({ $mb, theme: { spacing } }) => spacing($mb)};
   padding-bottom: ${({ $pb, theme: { spacing } }) => spacing($pb)};
   padding-top: ${({ $pt, theme: { spacing } }) => spacing($pt)};
`;
