import * as Sentry from '@sentry/react';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import App from 'App/App';
import AppAuth from 'App/AppAuth';
import { ThemeProvider } from 'designSystem/ThemeProvider/ThemeProvider';
import 'i18n';
import reducers from 'state/reducers';
import 'validation';

import 'dayjs/locale/pl';
import * as serviceWorker from './serviceWorker';

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

axios.defaults.baseURL = process.env.REACT_APP_API_URL.trim();

if (process.env.NODE_ENV === 'production') {
   Sentry.init({
      dsn: 'https://0e761c0496054d37874c64b75c2dcce1@o380431.ingest.sentry.io/5302450',
   });
}

dayjs.locale('pl');
dayjs.extend(customParseFormat);

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root'));

root.render(
   <Provider store={store}>
      <React.StrictMode>
         <HelmetProvider>
            <ThemeProvider>
               <AppAuth>
                  <QueryClientProvider client={queryClient}>
                     <App />
                  </QueryClientProvider>
               </AppAuth>
            </ThemeProvider>
         </HelmetProvider>
      </React.StrictMode>
   </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
