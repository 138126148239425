import { Unit } from './units';

const DEFAULT_SPACING: Record<Unit, number> = {
   rem: 0.25,
   px: 4,
};

export type Spacings =
   | [s: number]
   | [sy: number, sx: number]
   | [st: number, sx: number, sb: number]
   | [st: number, sr: number, sb: number, sl: number];

const createSpacing = (defaultUnit: Unit, currentSpacing: number): string => {
   return `${DEFAULT_SPACING[defaultUnit] * currentSpacing}${defaultUnit}`;
};

const combineSpacings = (previous: string, next: string): string => {
   return `${previous} ${next}`;
};

export const spacing = (defaultUnit: Unit, ...spacings: Spacings): string => {
   return spacings.reduce((spacingsResult, currentSpacing) => {
      return combineSpacings(spacingsResult, createSpacing(defaultUnit, currentSpacing));
   }, '');
};
