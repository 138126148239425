import styled from 'styled-components';

import { colors } from 'deprecatedTheme/colors';

const Container = styled.div`
   border-bottom: 3px solid ${colors.neutral60};
   & > span {
      display: inline-block;
      margin-right: 0.5rem;
      border-right: 2px solid gray;
      padding-right: 0.5rem;

      &:last-of-type {
         border-right: none;
      }
   }
   & > div {
      border-bottom: 1px dashed ${colors.neutral60};
      &:last-of-type {
         border-bottom: 1px solid ${colors.neutral60};
      }
   }
`;

const ProductContainer = styled.div`
   margin-bottom: 0.5rem;
   padding-bottom: 0.5rem;
`;

export const getProductName = (product) => {
   const rawArray = product.originalProduct?.rawArray || product.rawArray;
   const getExtraInfo = () => {
      if (rawArray.model) {
         return ` (${rawArray.model})`;
      }
      if (rawArray.akcesoria2) {
         return ` (${rawArray.akcesoria2})`;
      }
      return '';
   };

   return `${rawArray.typ}${getExtraInfo(rawArray)}`;
};

const getPoleValue = (product, poleKey) =>
   // ESLINT_WARN_TO_BE_FIXED
   // eslint-disable-next-line radix
   typeof product[poleKey] === 'string' ? parseInt(product[poleKey]) : null;

const getPolesInfo = (products) => {
   const withAutomation = products.some(
      (p) =>
         typeof p.originalProduct.rawArray.slupek_z_automatem === 'string' &&
         p.originalProduct.rawArray.slupek_z_automatem === 'TAK',
   );
   const intoGround = products.some((p) =>
      getPoleValue(p.originalProduct.rawArray, 'slupy_do_wbetonowania'),
   );
   const onHolder = products.some((p) =>
      getPoleValue(p.originalProduct.rawArray, 'slupy_na_markach'),
   );

   return {
      withAutomation,
      intoGround,
      onHolder,
   };
};

const isTwistingGate = (products) => {
   return products.some((p) => {
      const type = p.originalProduct.rawArray.typ || '';
      const selfSupporting = type === 'samonosna';
      if (!selfSupporting) {
         return false;
      }
      const w = width(p);
      return w > 5800;
   });
};

export const getProductAmount = (product) => product.amount;

const width = (product) => {
   if (product.originalProduct.rawArray.szerokosc) {
      if (product.originalProduct.rawArray.szerokosc === 'inna_szerokosc') {
         return product.originalProduct.rawArray.inna_szerokosc;
      }
      return product.originalProduct.rawArray.szerokosc;
   }
   return null;
};

const Products = ({ className, products }) => {
   const poles = getPolesInfo(products);
   const twistingGate = isTwistingGate(products);
   const productsRendered = products.map((p) => {
      const h = p.originalProduct.rawArray.wysokosc || '';
      const w = width(p);

      return (
         <ProductContainer key={Math.random()}>
            {(h || w) && (
               <div>
                  <span>
                     Wysokość: {h} Szerokość: {w}
                  </span>
               </div>
            )}
            <div>
               <span>
                  {getProductAmount(p)} x {getProductName(p)}
               </span>
            </div>
         </ProductContainer>
      );
   });

   return (
      <Container className={className}>
         {productsRendered}
         <span>
            Słupy za automatyką:{' '}
            {poles.withAutomation ? <span style={{ color: colors.primary }}>TAK</span> : 'NIE'}
         </span>
         <span>
            Słupy do wbetonowania:{' '}
            {poles.intoGround ? <span style={{ color: colors.primary }}>TAK</span> : 'NIE'}
         </span>
         <span>
            Słupy na markach:{' '}
            {poles.onHolder ? <span style={{ color: colors.primary }}>TAK</span> : 'NIE'}
         </span>
         {twistingGate && <span style={{ color: colors.primary }}>Brama skręcana: TAK</span>}
      </Container>
   );
};

export default Products;
