import { faFill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import Error from 'ui/Error';

import { Context } from '../Context';

const reqPainted = ({ orderId, amountOfPaint }) =>
   axios.post(`/paint-shop/orders/${orderId}/painted`, {
      amountOfPaint,
   });

const createValidationSchema = (lastWithLeadingColor) =>
   yup.object().shape({
      amountOfPaint: lastWithLeadingColor ? yup.number().required().min(1) : yup.number(),
   });

const Painted = ({ order }) => {
   const { refetch } = useContext(Context);
   const [showForm, setShowForm] = useState(false);
   const { submitForm, isSubmitting, errors, values, handleChange, handleBlur, touched } =
      useFormik({
         validationSchema: createValidationSchema(order.lastWithLeadingColor),
         initialValues: {
            orderId: order.id,
            amountOfPaint: '',
         },
         onSubmit: async (v) => {
            await reqPainted({
               orderId: v.orderId,
               amountOfPaint: v.amountOfPaint,
            });
            refetch();
         },
      });
   const painted = () => {
      if (order.lastWithLeadingColor) {
         setShowForm(true);
         return;
      }
      submitForm();
   };

   return (
      <Container>
         {order.paintedAt && (
            <span>
               POMALOWANO
               <br />
               {order.paintedAt}
            </span>
         )}
         {!order.paintedAt && (
            <Button
               className="btn btn-success"
               disabled={showForm || isSubmitting}
               onClick={painted}
            >
               {!order.lastWithLeadingColor && 'Pomalowano'}
               {order.lastWithLeadingColor && 'Pomalowano | PRZEZBROJENIE'}
            </Button>
         )}
         {showForm && !order.paintedAt && (
            <Extra>
               <Label>
                  <LabelIcon icon={faFill} />
                  <LabelContent>Kg farby</LabelContent>
                  <input
                     type="number"
                     className="form-control"
                     name="amountOfPaint"
                     value={values.amountOfPaint}
                     onChange={handleChange}
                     onBlur={handleBlur}
                  />
                  <Error>
                     {touched?.amountOfPaint && errors?.amountOfPaint && errors.amountOfPaint}
                  </Error>
               </Label>
               <Submit className="btn btn-success" disabled={isSubmitting} onClick={submitForm}>
                  Zapisz
               </Submit>
            </Extra>
         )}
      </Container>
   );
};

const Container = styled.div`
   display: flex;
   flex-direction: column;
`;

const Button = styled.button`
   width: 100%;
`;

const Submit = styled.button`
   width: 100%;
`;

const Label = styled.label`
   width: 100%;
`;

const LabelIcon = styled(FontAwesomeIcon)`
   margin-right: 0.5rem;
`;

const LabelContent = styled.span`
   margin-right: 2rem;
`;

const Extra = styled.div`
   margin-top: 1rem;
`;

export default Painted;
