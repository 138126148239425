import axios from 'axios';
import { useFormik } from 'formik';
import { useContext } from 'react';

import { STATUS_SENT } from 'Shared/Warehouse/OrderPartsGroup';

import { Context } from '../../Context';

const backToWarehouse = (groupId) =>
   axios.post(`/warehouse/order-part-groups/${groupId}/back-to-warehouse`);

const initialValues = {};
const BackToWarehouseButton = ({ group }) => {
   const { refetch } = useContext(Context);
   const formik = useFormik({
      initialValues,
      onSubmit: () => {
         backToWarehouse(group.id).then(() => refetch());
      },
   });
   if (group.status !== STATUS_SENT) return null;

   return (
      <button
         type="button"
         className="btn btn-success"
         onClick={backToWarehouse}
         disabled={formik.isSubmitting}
      >
         Powrót do magazynu
      </button>
   );
};

export default BackToWarehouseButton;
