import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import FullInternalContainer from 'Layouts/FullInternalContainer';
// eslint-disable-next-line import/no-cycle
import ToBePacked from 'Pages/Dashboards/Warehouse/ToBePacked';
// eslint-disable-next-line import/no-cycle
import ToPackedIntoWarehouse from 'Pages/Dashboards/Warehouse/ToPackedIntoWarehouse';

export const fetchAll = (status) =>
   axios.get('/dashboards/warehouse/order-parts-groups', {
      params: {
         status,
      },
      auth: {
         username: process.env.REACT_APP_API_DASHBOARD_USER,
         password: process.env.REACT_APP_API_DASHBOARD_PASSWORD,
      },
   });

const DashboardWarehouse = () => {
   return (
      <FullInternalContainer>
         <Helmet>
            <title>Magazyn | Konsport</title>
         </Helmet>
         <div className="row">
            <ToBePacked />
            <ToPackedIntoWarehouse />
         </div>
      </FullInternalContainer>
   );
};

export const HeaderContainer = styled.div`
   margin-bottom: 2rem;
`;

export default DashboardWarehouse;
