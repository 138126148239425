import axios from 'axios';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { defaultsOneTime } from 'Shared/useQuery';

const fetchColors = () => axios.get('/products-colors');

// useKNSColors will fetch and store (Redux store) colors.
// Colors are colors available in Konsport with extra data such as information about the extra charge.

const useKNSColors = () => {
   const dispatch = useDispatch();
   const { authenticated } = useSelector((s) => s.app.auth);

   useQuery(['theme', 'fetchColors'], fetchColors, {
      ...defaultsOneTime,
      enabled: authenticated,
      onSuccess({ data: colors }) {
         dispatch({ type: 'FETCH_COLORS', colors });
      },
      onError() {
         dispatch({ type: 'FETCH_COLORS_FAILED', error: 'FailedToGetColors' });
      },
   });
};

export default useKNSColors;
