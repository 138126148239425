import dayjs from 'dayjs';

const initialState = {
   currentDay: dayjs().format('MM/DD/YYYY'),
   days: [],
   weeks: [],
};

// eslint-disable-next-line default-param-last
const paintShopCalendar = (state = initialState, action) => {
   switch (action.type) {
      case 'OPEN_DAY':
         return {
            ...state,
            currentDay: action.currentDay,
         };
      case 'CLOSE_DAY':
         return {
            ...state,
            currentDay: null,
         };
      case 'FETCH_CALENDAR':
         return {
            ...state,
            days: action.days,
            weeks: action.weeks,
         };
      default:
         return state;
   }
};

export default paintShopCalendar;
