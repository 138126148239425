import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { orderPartsGroupStatuses } from 'Shared/Warehouse/OrderPartsGroup';
import { CompanyName } from 'Shared/Warehouse/Warehouse';

import Palette from './Palette';

const Group = ({ group }) => {
   const parts = group.parts.map((p) => {
      return (
         <tr key={p.id}>
            <td>
               <ZamowieniaLink
                  displayId={p.order.zamowieniaId}
                  zamowieniaId={p.order.zamowieniaId}
               />
            </td>
            <td>
               <Palette palette={p.palette} />
            </td>
         </tr>
      );
   });
   const { createdByExternalClient } = group.parts[0].order;

   return (
      <tr key={group.id}>
         <td>{group.publicId}</td>
         <td>
            <CompanyName
               orderPartsGroup={group}
               createdByExternalClient={createdByExternalClient}
            />
         </td>
         <td>{orderPartsGroupStatuses[group.status]}</td>
         <td>
            <table className="table table-hover table-sm">
               <thead>
                  <tr>
                     <th>Zamówienie / część zamówienia</th>
                     <th>Palety na strefie</th>
                  </tr>
               </thead>
               <tbody>{parts}</tbody>
            </table>
         </td>
      </tr>
   );
};

export default Group;
