import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';

import { filterChanged } from 'state/warehouse';

const ClientFilter = ({ clients, className }) => {
   const dispatch = useDispatch();
   const filters = useSelector((state) => state.warehouse.filters);
   const clientOptions = [
      { label: 'Wszyscy klienci', value: '' },
      ...clients.map((c) => {
         return { label: c, value: c };
      }),
   ];

   return (
      <div className={className}>
         <Label>
            Klient
            <Select
               placeholder=""
               defaultValue={filters.companyName}
               onChange={(e) => dispatch(filterChanged('companyName', e.value || ''))}
               name="clientsFilterSelect"
               options={clientOptions}
               variant="neutral"
            />
         </Label>
      </div>
   );
};

const Label = styled.label`
   width: 100%;
`;

export default ClientFilter;
