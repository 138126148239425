export type Palette = {
   primary: {
      50: string;
      100: string;
      200: string;
   };
   positive: {
      100: string;
      200: string;
   };
   info: {
      100: string;
      200: string;
      300: string;
   };
   neutral: {
      50: string;
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
      700: string;
      800: string;
      900: string;
   };
};

export const palette: Palette = {
   primary: {
      50: '#EDB4BC',
      100: '#E50019',
      200: '#C30015',
   },
   positive: {
      100: '#62AC70',
      200: '#44AF69',
   },
   info: {
      100: '#D9E3EF',
      200: '#0064C7',
      300: '#0059B1',
   },
   neutral: {
      50: '#ffffff',
      100: '#F4F6F7',
      200: '#F1F3F5',
      300: '#E2E5E8',
      400: '#DADDE0',
      500: '#A2ABB3',
      600: '#788692',
      700: '#4E6170',
      800: '#36444E',
      900: '#000000',
   },
};
