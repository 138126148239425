import { faClock, faSave, faStream, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';

import ShiftTimeAfterIssues from 'Pages/PaintShopCalendar/Shift/ShiftTimeAfterIssues';
import { Response } from 'Shared/Response';
import Error from 'ui/Error';

import Context, { AVERAGE_LOGS_PER_HOUR, findDay } from '../context';

import Issues from './Issues/Issues';
import LogsCalculations from './LogsCalculations';
import Supervisor from './Supervisor';

// TODO be consistent and calculate it on back-end.
// There is already domain service for that.
const calculateAmountOfLogs = (shift) => {
   return shift.amountOfPeople * (shift.minutesInShift / 60) * AVERAGE_LOGS_PER_HOUR;
};

const validationSchema = yup.object().shape({
   amountOfPeople: yup.number().min(1).required(),
   minutesInShift: yup.number().min(60).max(1440).required(),
   amountOfLogs: yup.number().min(1).required(),
   supervisor: yup.string().required(),
});

const initialValues = {
   amountOfPeople: '',
   minutesInShift: '',
   amountOfLogs: '',
   supervisor: '',
   status: '',
};

const defaultValues = {
   ...initialValues,
   amountOfPeople: '8',
   minutesInShift: '480',
   amountOfLogs: AVERAGE_LOGS_PER_HOUR * 8,
};

const Shift = ({ index }) => {
   const { refetch } = useContext(Context);
   const days = useSelector((s) => s.paintShopCalendar.days);
   const currentDay = useSelector((s) => s.paintShopCalendar.currentDay);
   const day = findDay(days, dayjs(currentDay).format('DD-MM-YYYY'));
   const [info, setInfo] = useState(null);
   const [error, setError] = useState(false);
   const formik = useFormik({
      initialValues,
      onSubmit: (values, { setSubmitting }) => {
         setError(false);
         setInfo(null);
         axios({
            method: values.status ? 'PUT' : 'POST',
            url: `/paint-shop/calendar/days/${dayjs(currentDay).format(
               'DD-MM-YYYY',
            )}/shifts/${index}`,
            data: values,
         })
            .then(() => {
               setInfo('Zmiany zostały zapisane.');
               refetch();
            })
            .catch((err) => {
               if (!err.response?.status) {
                  Sentry.captureException(err);
               }
               setError(true);
               setInfo(err.response?.data?.message ?? 'Wystąpił błąd. Spróbuj jeszcze raz.');
            });
         setSubmitting(false);
      },
      validationSchema,
   });
   useEffect(() => {
      if (!currentDay || formik.values.day === currentDay) return;
      formik.setFieldValue('day', currentDay);
   }, [formik.values.day, currentDay, day]);
   const shift = day?.shifts[index];
   useEffect(() => {
      if (!shift) {
         formik.setValues(initialValues);
         return;
      }
      formik.setFieldValue(`amountOfPeople`, shift.amountOfPeople || '');
      formik.setFieldValue(`minutesInShift`, shift.minutesInShift || '');
      formik.setFieldValue(`amountOfLogs`, shift.amountOfLogs || '');
      formik.setFieldValue(`supervisor`, shift.supervisor || '');
      formik.setFieldValue(`status`, shift.status || '');
   }, [currentDay, shift]);
   const handleChange = (e) => {
      const v =
         e.currentTarget.dataset.simpleName === 'minutesInShift'
            ? e.currentTarget.value * 60
            : e.currentTarget.value;
      formik.setFieldValue(e.currentTarget.dataset.simpleName, v);
      const s = {
         ...formik.values,
         [e.currentTarget.dataset.simpleName]: v,
      };
      if (!s?.amountOfPeople || !s?.minutesInShift) return;
      const aol = calculateAmountOfLogs(s);
      if (!aol || aol === s.amountOfLogs) return;
      formik.setFieldValue(`amountOfLogs`, aol);
   };
   const setDefaults = () => {
      formik.setValues(defaultValues);
   };
   const hoursInShift = formik.values.minutesInShift ? formik.values.minutesInShift / 60 : '';
   return (
      <Container>
         <IndexContainer>
            <span>{index === 0 && 'Rano'}</span>
            <span>{index === 1 && 'Po południu'}</span>
         </IndexContainer>
         <FormContainer onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
               <Inputs>
                  <div>
                     <Label>
                        <LabelIcon icon={faUserFriends} />
                        <LabelContent>Liczba osób na zmianie</LabelContent>
                        <input
                           type="number"
                           className="form-control"
                           name="amountOfPeople"
                           data-simple-name="amountOfPeople"
                           value={formik.values.amountOfPeople}
                           onChange={handleChange}
                           onBlur={formik.handleBlur}
                        />
                        <Error>
                           {formik.touched?.amountOfPeople &&
                              formik.errors?.amountOfPeople &&
                              formik.errors.amountOfPeople}
                        </Error>
                     </Label>
                  </div>
                  <ShiftDurationContainer>
                     <Label>
                        <LabelIcon icon={faClock} />
                        <LabelContent>Ile godzin trwa zmiana</LabelContent>
                        <input
                           type="number"
                           className="form-control"
                           name="minutesInShift"
                           data-simple-name="minutesInShift"
                           value={hoursInShift}
                           onChange={handleChange}
                           onBlur={formik.handleBlur}
                        />
                        <Error>
                           {formik.touched?.minutesInShift &&
                              formik.errors?.minutesInShift &&
                              formik.errors.minutesInShift}
                        </Error>
                     </Label>
                  </ShiftDurationContainer>
               </Inputs>
               <Inputs2>
                  <LogsCalculations />
                  <div>
                     <AmountOfLogsLabel>
                        <LabelIcon icon={faStream} />
                        <LabelContent>Ile belek</LabelContent>
                        <input
                           type="number"
                           className="form-control"
                           name="amountOfLogs"
                           value={formik.values.amountOfLogs}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                        />
                        <Error>
                           {formik.touched?.amountOfLogs &&
                              formik.errors?.amountOfLogs &&
                              formik.errors.amountOfLogs}
                        </Error>
                     </AmountOfLogsLabel>
                  </div>
               </Inputs2>
               <Supervisor />
               <div>
                  <DefaultValuesButton
                     type="button"
                     className="btn btn-light"
                     onClick={setDefaults}
                  >
                     Ustaw domyślne wartości
                  </DefaultValuesButton>
                  <SaveButton
                     className="btn btn-danger"
                     type="submit"
                     disabled={
                        formik.values.status === 'LOCKED' ||
                        formik.isSubmitting ||
                        Object.keys(formik.errors).length
                     }
                  >
                     <SaveIcon icon={faSave} />
                     Zapisz
                  </SaveButton>
               </div>
               <StyledResponse messages={[info]} error={error} />
               {formik.values.status === 'LOCKED' && (
                  <Info>
                     <span>Nie możesz edytować zmiany, w której były wprowadzone awarie.</span>
                  </Info>
               )}
               <ShiftTimeAfterIssues index={index} />
            </FormikProvider>
         </FormContainer>
         <Issues index={index} />
      </Container>
   );
};

const Container = styled.div`
   margin-top: 3rem;
   display: flex;
`;

const IndexContainer = styled.div`
   font-size: 2rem;
   display: flex;
   align-items: center;
   padding-right: 1rem;
   margin-right: 1rem;
   border-right: 2px solid #000;
   min-width: 200px;
`;

const FormContainer = styled.form`
   padding-right: 1rem;
   margin-right: 1rem;
   border-right: 2px solid #000;
`;

const Label = styled.label`
   margin-bottom: 0;
   margin-right: 1rem;
`;

const AmountOfLogsLabel = styled.label`
   margin-bottom: 0;
   margin-right: 2rem;
   width: 222px;
`;

const Inputs = styled.div`
   display: flex;
   align-items: flex-end;
`;

const Inputs2 = styled.div`
   display: flex;
   align-items: flex-end;
   padding-left: 14px;
   margin-top: 1rem;
`;

const LabelIcon = styled(FontAwesomeIcon)`
   margin-right: 0.5rem;
`;

const LabelContent = styled.span`
   margin-right: 2rem;
`;

const ShiftDurationContainer = styled.div`
   margin-left: 2rem;
`;

const SaveIcon = styled(FontAwesomeIcon)`
   margin-right: 1rem;
`;

const DefaultValuesButton = styled.button`
   width: 100%;
   margin-bottom: 1rem;
`;

const SaveButton = styled.button`
   width: 100%;
`;

const StyledResponse = styled(Response)`
   margin-top: 1rem;
`;

const Info = styled.div`
   margin-top: 1rem;
`;

export default Shift;
