import { useFormikContext } from 'formik';
import { useContext } from 'react';

import {
   STATUS_PACKED as ORDER_PART_STATUS_PACKED,
   STATUS_PACKED_DELIVERED_TO_PACKING_PLACE as ORDER_PART_STATUS_PACKED_DELIVERED_TO_PACKING_PLACE,
   STATUS_PACKED_DELIVERING_TO_PACKING_PLACE as ORDER_PART_STATUS_PACKED_DELIVERING_TO_PACKING_PLACE,
} from 'Shared/Warehouse/OrderPart';
import {
   STATUS_PACKED,
   STATUS_SENT,
   STATUS_TO_BE_PACKED,
   STATUS_TO_BE_SEND,
} from 'Shared/Warehouse/OrderPartsGroup';

import { Context } from '../../Context';

const evaluateDisableSaveBtnForOrderNotCratedByExternalClient = (
   disabled,
   orderPart,
   ordersGroup,
) => {
   if (!orderPart.order.createdByExternalClient && disabled) {
      return true;
   }

   const anyOrderPartInDelivering = ordersGroup.orderParts.some(
      (op) => op.warehouseStatus === ORDER_PART_STATUS_PACKED_DELIVERING_TO_PACKING_PLACE,
   );
   return ordersGroup.status === STATUS_TO_BE_PACKED && anyOrderPartInDelivering;
};

const PackBtn = ({ orderPart, group }) => {
   const { refetchForPacking } = useContext(Context);
   const { submitForm } = useFormikContext();

   const pack = async () => {
      submitForm();
      refetchForPacking();
   };
   const disabled =
      orderPart.warehouseStatus === ORDER_PART_STATUS_PACKED ||
      orderPart.warehouseStatus === ORDER_PART_STATUS_PACKED_DELIVERING_TO_PACKING_PLACE ||
      orderPart.warehouseStatus === ORDER_PART_STATUS_PACKED_DELIVERED_TO_PACKING_PLACE ||
      group.status === STATUS_PACKED ||
      group.status === STATUS_TO_BE_SEND ||
      group.status === STATUS_SENT;
   const disableSaveBtnForOrderNotCratedByExternalClient =
      evaluateDisableSaveBtnForOrderNotCratedByExternalClient(disabled, orderPart, group);

   return (
      <button
         className="btn btn-success"
         disabled={disableSaveBtnForOrderNotCratedByExternalClient}
         type="button"
         onClick={pack}
      >
         Spakuj
      </button>
   );
};

export default PackBtn;
