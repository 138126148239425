export const STATUS_PACKED_DELIVERING_TO_PACKING_PLACE = 'PACKED_DELIVERING_TO_PACKING_PLACE';
export const STATUS_PACKED_DELIVERED_TO_PACKING_PLACE = 'PACKED_DELIVERED_TO_PACKING_PLACE';

export const STATUS_PACKED = 'PACKED';

export const orderPartStatuses = {
   [STATUS_PACKED_DELIVERING_TO_PACKING_PLACE]: 'W trakcie dostarczania do miejsca pakowania',
   [STATUS_PACKED_DELIVERED_TO_PACKING_PLACE]: 'Dostarczane ze strefy',
   [STATUS_PACKED]: 'Spakowane',
};

export const ORDER_ATTRIBUTE_COLOR = 'color';
export const ORDER_ATTRIBUTE_LEADING_COLOR = 'leadingColor';
export const ORDER_ATTRIBUTE_SKIP_PAINTING = 'skipPainting';
export const ORDER_ATTRIBUTE_AMOUNT_OF_PAINT = 'amountOfPaint';

export const paintShopPaintOptions = [
   {
      label: 'Ceowniki',
      id: 'ceowniki',
   },
   {
      label: 'Obejmy do paneli',
      id: 'obejmy-do-paneli',
   },
   {
      label: 'Panele',
      id: 'panele',
   },
];
