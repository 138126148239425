import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as dayjs from 'dayjs';
import { useState } from 'react';
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import CloseCalendarButton from 'Shared/CloseCalendarButton';
import { filterChanged } from 'state/warehouse';

const StartedPaintingAtRange = () => {
   const dispatch = useDispatch();
   const [show, setShow] = useState(false);
   const range = useSelector((s) => s.warehouse.filters.startedPaintingAtRange);
   const onChange = (d) => {
      const [from, to] = d;
      dispatch(
         filterChanged('startedPaintingAtRange', {
            from: dayjs(from).toDate(),
            to: dayjs(to).toDate(),
         }),
      );
   };
   return (
      <Container>
         <div>
            <Label>
               <LabelIcon icon={faClock} />
               <LabelContent>Dzień wprowadzenia (od)</LabelContent>
               <Input
                  type="text"
                  className="form-control"
                  value={range?.from ? dayjs(range?.from).format('DD-MM-YYYY') : ''}
                  onFocus={() => setShow(true)}
                  readOnly
               />
            </Label>
            <Sep>-</Sep>
            <Label>
               <LabelIcon icon={faClock} />
               <LabelContent>Dzień wprowadzenia (do)</LabelContent>
               <Input
                  type="text"
                  className="form-control"
                  value={range?.to ? dayjs(range?.to).format('DD-MM-YYYY') : ''}
                  onFocus={() => setShow(true)}
                  readOnly
               />
            </Label>
         </div>
         {show && (
            <div>
               <CloseCalendarButton setShow={setShow} />
               <Calendar
                  onChange={onChange}
                  value={[range?.from, range?.to]}
                  selectRange
                  className="sm"
                  locale="pl"
               />
            </div>
         )}
      </Container>
   );
};

const Container = styled.div`
   width: 560px;
`;

const Sep = styled.span`
   margin: 1rem;
`;

const Label = styled.label`
   width: 260px;
   margin-bottom: 1rem;
`;

const LabelIcon = styled(FontAwesomeIcon)`
   margin-right: 0.5rem;
`;

const LabelContent = styled.span``;

const Input = styled.input`
   cursor: pointer;
`;

export default StartedPaintingAtRange;
