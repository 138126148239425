import { useFormikContext } from 'formik';
import update from 'immutability-helper';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { setErrorsInSplitOrderModal } from 'state/warehouse';

import { Context } from '../../Context';
import validate from '../../validate';

const calculateMaxAmount = (originalProductsMap, product) => {
   return originalProductsMap.reduce((acc, p) => {
      return p.id === product.originalProduct.rawArray.nr_produkt ? acc + p.amount : acc;
   }, 0);
};

const ProductAmount = ({ product, part, partsProductIndex, partsIndex }) => {
   const dispatch = useDispatch();
   const formik = useFormikContext();
   const { originalProductsMap } = useContext(Context);

   const change = (e) => {
      const o = update(formik.values, {
         parts: {
            [partsIndex]: {
               products: {
                  // ESLINT_WARN_TO_BE_FIXED
                  // eslint-disable-next-line radix
                  [partsProductIndex]: { $merge: { amount: parseInt(e.target.value) } },
               },
            },
         },
      });
      const errors = validate(originalProductsMap, o.parts);

      formik.setValues(o);
      dispatch(setErrorsInSplitOrderModal(errors));
   };
   const min = 1;
   const max = calculateMaxAmount(originalProductsMap, product);
   const amountDisabled = product.amount === 0 || (product.amount <= 1 && max === 1);

   return (
      <div>
         <Label>
            Ilość
            <input
               type="number"
               className="form-control"
               name={`${part.id}--amount--${product.id}`}
               value={product.amount || ''}
               disabled={amountDisabled}
               min={min}
               max={max}
               onChange={change}
            />
         </Label>
      </div>
   );
};

const Label = styled.label`
   width: 80px;
`;

export default ProductAmount;
