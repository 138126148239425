import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { paletteTypes } from 'Shared/Warehouse/Palette';
import { getProductName } from 'Shared/Warehouse/Products';

import MergeSelect from './MergeSelect';

const Palette = ({ palette, index }) => {
   const formik = useFormikContext();
   const parts = palette.orderParts.map((op) => {
      const products = op.products.map((p) => {
         return (
            <li key={p.id}>
               {p.amount} x {getProductName(p)}
            </li>
         );
      });
      return (
         <tr key={op.id}>
            <td>
               <ZamowieniaLink
                  displayId={op.order.zamowieniaId}
                  zamowieniaId={op.order.zamowieniaId}
               />
            </td>
            <td>
               <div>
                  <MergeSelect orderPart={op} paletteIndex={index} />
               </div>
            </td>
            <td>
               <ul className="list-unstyled">{products}</ul>
            </td>
         </tr>
      );
   });
   const typesRendered = Object.keys(paletteTypes).map((t) => {
      const setType = () => {
         formik.setFieldValue(`palettes[${index}].type`, t);
      };
      const className = `btn ${palette.type === t ? 'btn-dark' : 'btn-light'}`;
      return (
         <TypeBtn onClick={setType} className={className} type="button" key={t}>
            {paletteTypes[t]}
         </TypeBtn>
      );
   });
   return (
      <div>
         <table className="table table-hover">
            <caption>Zawiera {palette.orderParts.length} części</caption>
            <thead>
               <tr>
                  <th>Id palety</th>
                  <th>Typ palety</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td>{palette.publicId}</td>
                  <td>{typesRendered}</td>
               </tr>
               <tr>
                  <td />
                  <td>
                     <table className="table table-hover table-sm">
                        <thead>
                           <tr>
                              <th>Numery zamówień</th>
                              <th />
                              <th>Produkty</th>
                           </tr>
                        </thead>
                        <tbody>{parts}</tbody>
                     </table>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   );
};

const TypeBtn = styled.button`
   margin-right: 1rem;
   margin-bottom: 1rem;
`;

export default Palette;
