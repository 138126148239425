export const removeAttribute = (attributes, attribute) => {
   const index = attributes.indexOf(attribute);
   const newArr = [...attributes];
   newArr.splice(index, 1);

   return newArr;
};

export const createNewAttributes = (attributes, key, value) => {
   return attributes.length
      ? [...attributes].map((a) =>
           a.key === key
              ? {
                   ...a,
                   value,
                }
              : a,
        )
      : [
           {
              key,
              value,
           },
        ];
};
