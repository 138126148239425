import styled from 'styled-components';

import { CompanyName, OrderPartsGroupStatus } from 'Shared/Warehouse/Warehouse';

import OrderParts from './OrderParts';

const Container = styled.div`
   display: flex;
   flex-direction: column;
   margin-bottom: 1rem;
`;

const HeaderContainer = styled.div`
   margin-bottom: 1rem;
   font-size: 2rem;
`;

const OrderPartsGroup = ({ orderPartsGroup }) => {
   return (
      <Container>
         <HeaderContainer>
            <CompanyName
               orderPartsGroup={orderPartsGroup}
               createdByExternalClient={orderPartsGroup.orderParts[0].order.createdByExternalClient}
            />
            <OrderPartsGroupStatus
               orderPartsGroup={orderPartsGroup}
               createdByExternalClient={orderPartsGroup.orderParts[0].order.createdByExternalClient}
            />
         </HeaderContainer>
         <OrderParts orderParts={orderPartsGroup.orderParts} />
      </Container>
   );
};

export default OrderPartsGroup;
