import styled from 'styled-components';

export const Container = styled.p`
   font-size: 2rem;
   color: #ff0000;
`;

const Error = () => {
   return <Container>Wystąpił błąd...</Container>;
};

export default Error;
