import { useFormikContext } from 'formik';

import { PALETTE_ATTRIBUTES_KEY_NAMES } from 'Shared/palette';
import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { TYPE_GATE_SELF_SUPPORTING, paletteTypes } from 'Shared/Warehouse/Palette';
import { getProductName } from 'Shared/Warehouse/Products';

const PaletteForm = ({ palette, index }) => {
   const formik = useFormikContext();
   const products = palette.orderParts.map((op) => {
      return op.products.map((p) => {
         return (
            <li key={p.id}>
               {p.amount} x {getProductName(p)}
            </li>
         );
      });
   });

   const ordersPublicIds = palette.orderParts.map((op) => {
      return (
         <li key={op.id}>
            <ZamowieniaLink
               displayId={op.order.zamowieniaId}
               zamowieniaId={op.order.zamowieniaId}
            />
         </li>
      );
   });
   const disabled = palette.type === TYPE_GATE_SELF_SUPPORTING;

   return (
      <table className="table table-hover">
         <thead>
            <tr>
               <th>Część</th>
               <th>Zamówienia</th>
               <th>Wymiary palety</th>
               <th>Typ</th>
               <th>Produkty</th>
            </tr>
         </thead>
         <tbody>
            <tr>
               <td>{palette.publicId}</td>
               <td>
                  <ul className="list-unstyled">{ordersPublicIds}</ul>
               </td>
               <td>
                  {palette?.requiredAttributes.map((attribute) => {
                     const attributes = palette?.attributes;
                     const attributeIndex = attributes.some((a) => a.key === attribute)
                        ? attributes.indexOf(attributes.find((a) => a.key === attribute))
                        : attributes.length;
                     return (
                        <label
                           key={attribute}
                           htmlFor={`palettes[${index}].attributes.${attribute}`}
                        >
                           {PALETTE_ATTRIBUTES_KEY_NAMES[attribute] || ''}
                           <input
                              key={attribute}
                              type="text"
                              id={`palettes[${index}].attributes[${attributeIndex}].value`}
                              name={`palettes[${index}].attributes[${attributeIndex}].value`}
                              className="form-control"
                              onChange={(e) =>
                                 formik.setFieldValue(
                                    `palettes[${index}].attributes[${attributeIndex}]`,
                                    { key: attribute, value: e.target.value },
                                 )
                              }
                              onBlur={formik.handleBlur}
                              value={attributes[attributeIndex]?.value || ''}
                              disabled={disabled}
                              placeholder={PALETTE_ATTRIBUTES_KEY_NAMES[attribute] || ''}
                           />
                        </label>
                     );
                  })}
               </td>

               <td>{paletteTypes[palette.type]} </td>
               <td>
                  <ul>{products}</ul>
               </td>
            </tr>
         </tbody>
      </table>
   );
};

export default PaletteForm;
