import { Navigate } from 'react-router-dom';

import { doesUserHasRole, loadUser } from 'auth';

const Home = () => {
   const user = loadUser();

   const paintShopManager = doesUserHasRole('ROLE_LAKIERNIA', user?.roles);
   const paintShopPainter = doesUserHasRole('ROLE_WIESZAKI', user?.roles);
   const distributor = doesUserHasRole('ROLE_DISTRIBUTOR', user?.roles);
   const warehouseManager = doesUserHasRole('ROLE_MAGAZYN', user?.roles);
   const warehouseOffice = doesUserHasRole('ROLE_MAGAZYN_BIURO', user?.roles);
   const finishingGates = doesUserHasRole('ROLE_FINISHING_GATES', user?.roles);
   const warehousePacking = doesUserHasRole('ROLE_PACKING', user?.roles);
   const warehousePackingIntoWarehouse = doesUserHasRole(
      'ROLE_PACKING_INTO_WAREHOUSE',
      user?.roles,
   );
   const warehouseForklift = doesUserHasRole('ROLE_WAREHOUSE_FORKLIFT', user?.roles);
   const paintShopPainting = doesUserHasRole('ROLE_PAINT_SHOP_PAINTING', user?.roles);
   const sending = doesUserHasRole('ROLE_WAREHOUSE_SENDING', user?.roles);
   const production = doesUserHasRole('ROLE_PRODUCTION', user?.roles);

   const getIndexPath = () => {
      if (!user) {
         return '/sign-in';
      }
      if (distributor) {
         return '/palettes';
      }
      if (paintShopManager || paintShopPainter) {
         return '/paint-shop';
      }
      if (paintShopPainting) {
         return '/paint-shop-painting';
      }
      if (warehouseManager) {
         return '/warehouse-manager';
      }
      if (warehouseForklift) {
         return '/warehouse-forklift';
      }
      if (warehousePacking) {
         return '/warehouse-packing';
      }
      if (finishingGates) {
         return '/finishing-gates';
      }
      if (warehousePackingIntoWarehouse) {
         return '/warehouse-packing-into-warehouse';
      }
      if (warehouseOffice) {
         return '/warehouse-office';
      }
      if (sending) {
         return '/warehouse-sending';
      }
      if (production) {
         return '/production-order-check-in';
      }
      return '/no-index';
   };

   return <Navigate to={{ pathname: getIndexPath() }} />;
};

export default Home;
