import styled from 'styled-components';

import Products from './Products';

const OrderPart = ({ part, partsIndex }) => {
   return (
      <Container>
         <Products part={part} partsIndex={partsIndex} />
      </Container>
   );
};

const Container = styled.div`
   margin-bottom: 1rem;
`;

export default OrderPart;
