import dayjs from 'dayjs';

import WareHouseCountdown from 'Pages/WarehouseManager/WareHouseCountdown';
import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import Products from 'Shared/Warehouse/Products';
import { CompanyName } from 'Shared/Warehouse/Warehouse';

import PaletteForm from '../PaletteForm';

const Group = ({ group }) => {
   const parts = group.orderParts.map((op) => {
      const hooks = op.order.hooks.map((h) => {
         return <li key={h.name}>{h.name}</li>;
      });
      return (
         <tr key={op.id}>
            <td>
               <ZamowieniaLink
                  displayId={op.order.zamowieniaId}
                  zamowieniaId={op.order.zamowieniaId}
               />
            </td>
            <td>
               <WareHouseCountdown date={dayjs(op.order.startedPaintingAt)} />
            </td>
            <td>
               <ul className="list-unstyled">{hooks}</ul>
            </td>
            <td>
               <Products products={op.products} />;
            </td>
            <td>
               <PaletteForm orderPart={op} group={group} />
            </td>
         </tr>
      );
   });

   return (
      <tr>
         <td>{group.publicId}</td>
         <td>
            <CompanyName
               orderPartsGroup={group}
               createdByExternalClient={group.orderParts[0].order.createdByExternalClient}
            />
         </td>
         <td>
            <table className="table table-hover table-sm">
               <colgroup>
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '40%' }} />
                  <col style={{ width: '20%' }} />
               </colgroup>
               <thead>
                  <tr>
                     <th>Zamówienie / część zamówienia</th>
                     <th>Lakiernia</th>
                     <th>Wieszaki</th>
                     <th>Produkty</th>
                  </tr>
               </thead>
               <tbody>{parts}</tbody>
            </table>
         </td>
      </tr>
   );
};

export default Group;
