import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import { fetchAll } from 'Pages/Dashboards/Warehouse/DashboardWarehouse';
import LastUpdatedAt from 'Pages/Dashboards/Warehouse/LastUpdatedAt';
import OrderPartsGroup from 'Pages/Dashboards/Warehouse/OrderPartsGroup';
import Error from 'Shared/Error';
import Loading from 'Shared/Loading';
import defaults from 'Shared/useQuery';
import Header from 'Shared/Warehouse/Header';
import { STATUS_TO_BE_PACKED } from 'Shared/Warehouse/OrderPartsGroup';

const ToBePacked = () => {
   const dispatch = useDispatch();
   const arr = useSelector((s) => s.warehouse.orderPartsGroupsToBePacked);
   const { isLoading, dataUpdatedAt, error } = useQuery(
      ['fetchOrderPartsGroupsToBePacked', { status: STATUS_TO_BE_PACKED }],
      () => fetchAll(STATUS_TO_BE_PACKED),
      {
         ...defaults,
         onSuccess: (data) => {
            dispatch({
               type: 'ORDER_PARTS_GROUPS_TO_BE_PACKED_FETCHED',
               data: data.data.orderPartsGroups,
            });
         },
      },
   );
   const rendered = arr.map((og) => <OrderPartsGroup key={og.id} orderPartsGroup={og} />);

   return (
      <section className="col">
         <Header>
            {arr.length ? `${arr.length} x` : ''}&nbsp;Do spakowania na gotowo&nbsp;
            {!!dataUpdatedAt && <LastUpdatedAt updatedAt={dataUpdatedAt} />}
         </Header>
         <div>
            {isLoading && <Loading />}
            {error && <Error />}
            {rendered}
         </div>
      </section>
   );
};

export default ToBePacked;
