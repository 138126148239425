import axios from 'axios';
import { useFormik } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';

import { ZamowieniaLink } from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { attribute, paletteTypes } from 'Shared/Warehouse/Palette';
import Products from 'Shared/Warehouse/Products';

const Palettes = ({ groupId, palettes }) => {
   const [info, setInfo] = useState('');
   const [paletteId, setPaletteId] = useState('');
   const finalPalettesWeights = palettes.map((p) => p.weight);
   const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
         finalPalettesWeights,
      },
   });
   const submit = (e, i, finalPaletteId) => {
      setInfo('');
      setPaletteId('');
      axios.post(`/warehouse/groups/${groupId}/final-palettes/${finalPaletteId}/weight`, {
         weight: formik.values.finalPalettesWeights[i],
      });
      setInfo('Zapisane.');
      setPaletteId(finalPaletteId);
   };
   const palettesRendered = palettes.map((p, i) => {
      const parts = p.orderParts.map((op) => {
         return (
            <tr key={op.id}>
               <td>
                  <ZamowieniaLink
                     displayId={op.order.zamowieniaId}
                     zamowieniaId={op.order.zamowieniaId}
                  />
               </td>
               <td>
                  <Products products={op.products} />;
               </td>
            </tr>
         );
      });
      return (
         <tr key={p.id}>
            <td>{p.publicId}</td>
            <td>{attribute('width', p.attributes)}</td>
            <td>{attribute('height', p.attributes)}</td>
            <td>{attribute('length', p.attributes)}</td>
            <td>{paletteTypes[p.type]}</td>
            <td>
               <table className="table table-hover table-sm">
                  <thead>
                     <tr>
                        <th>Zamówienia / części zamówień</th>
                        <th>Produkty</th>
                     </tr>
                  </thead>
                  <tbody> {parts} </tbody>
               </table>
            </td>
            <td>
               <Form>
                  <input
                     type="number"
                     name={`finalPalettesWeights.${i}`}
                     className="form-control"
                     onChange={formik.handleChange}
                     value={formik.values.finalPalettesWeights[i] || p.id}
                  />
                  <Submit
                     type="button"
                     className="btn btn-success"
                     onClick={(e) => submit(e, i, p.id)}
                  >
                     Zapisz
                  </Submit>
                  <span>{paletteId === p.id && info}</span>
               </Form>
            </td>
         </tr>
      );
   });

   return (
      <Container>
         <table className="table table-hover">
            <thead>
               <tr>
                  <th>Paleta</th>
                  <th>Szerokość</th>
                  <th>Wysokość</th>
                  <th>Długość</th>
                  <th>Typ</th>
                  <th>Zamówienia / części zamówień</th>
                  <th>Waga</th>
               </tr>
            </thead>
            <tbody>{palettesRendered}</tbody>
         </table>
      </Container>
   );
};

const Container = styled.div`
   margin-top: 2rem;
`;

const Form = styled.div`
   display: flex;
`;

const Submit = styled.button`
   margin: 0 1rem;
`;

export default Palettes;
