import axios from 'axios';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Context } from '../../Context';

const initialValues = {
   hook: '',
};

const Add = ({ order }) => {
   const { refetch, refetchHooks } = useContext(Context);
   const availableHooks = useSelector((s) => s.warehouse.hooks);
   const formik = useFormik({
      initialValues,
      onSubmit: (values, { resetForm }) => {
         axios
            .post(`/paint-shop/orders/${order.id}/assign-hook`, {
               hook: values.hook,
            })
            .then(() => {
               refetch();
               refetchHooks();
               resetForm();
            });
      },
   });
   const hooksRendered = availableHooks.map((h) => (
      <option key={h.name} value={h.name}>
         {h.name}
      </option>
   ));
   const disabled = formik.isSubmitting || Object.keys(formik.errors).length;

   return (
      <Container onSubmit={formik.handleSubmit}>
         <div>
            <label htmlFor="hook">
               Wieszaki
               <select
                  id="hook"
                  className="form-control"
                  name="hook"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.hook}
               >
                  <option value="" />
                  {hooksRendered}
               </select>
            </label>
         </div>
         <div>
            <AddBtn className="btn btn-success" type="submit" disabled={disabled}>
               Dodaj
            </AddBtn>
         </div>
      </Container>
   );
};

const Container = styled.form`
   display: flex;
   flex-direction: column;
   position: relative;
`;

const AddBtn = styled.button`
   margin-top: 1rem;
`;

export default Add;
