export const form_pl = {
   units: {
      mm: 'mm',
   },
   label: {
      addClampSet: 'Dodaj komplet uchwytów montażowych',
      additionalBolt: 'Dodatkowy rygiel',
      additionalDoorBirdMonitor: 'Dodatkowy monitor DoorBird',
      additionalPowerSupply: 'Dodatkowy zasilacz',
      additionalProximityKey: 'Dodatkowy brelok zbliżeniowy',
      additionalProximityKeyQuantity: 'Dodatkowy brelok zbliżeniowy',
      alternativeTopCarrier: 'Alternatywne prowadzenie górne',
      angleColor: 'Kolor kątownika',
      applicableToModel: 'Zastosowanie do modelu',
      application: 'Zastosowanie',
      arch: 'Łuk',
      assembly: 'Montaż',
      attachments: 'Załączniki',
      bftBasic: 'Słupek BFT Basic',
      bftPremium: 'Słupek BFT Premium',
      black_ral: 'Czarna - RAL9005',
      blindStopperColor: 'Kolor zaślepki',
      blix_small: 'Blix small',
      blix_big: 'Blix big',
      blocksCount: '{{quantity}} bloczki',
      blocksWithPotCount: '{{quantity}} bloczki (bloczek + doniczka)',
      bottom: 'Bottom',
      boxInstallationMethod: 'Wybierz sposób montażu',
      brown: 'Brąz',
      brown_8017SM: 'Brąz (Zbliżony do 8017SM)',
      burnoutContent: 'Treść wypalenia (wiersz 1)',
      burnoutContentOptional: 'Treść wypalenia (wiersz 2)',
      central: 'Centralnie',
      channelColor: 'Kolor ceownika',
      clamp: 'Komplet uchwytów montażowych',
      clampHeight: 'Wysokość klem',
      closingStrip: 'Listwa domykowa',
      closingStripCustom: 'Wybierz niestandardowy rozmiar listwy domykowej',
      closingStripCustomTypeConfirmation: 'Potwierdzam wybór listwy',
      closingStripOnAngles: 'Listwa domykowa na kątownikach',
      closingStripType: 'Rodzaj listwy',
      color: 'Kolor',
      color_metalInner: 'Kolor blachy wewnętrznej',
      color_metalOuter: 'Kolor blachy zewnętrznej',
      color_none: 'Brak koloru',
      color_other: 'Inny kolor',
      colore: 'Colore',
      concreteBoard: 'Deska betonowa {{value}} mm',
      confirm: 'Potwierdzam',
      content: 'Treść',
      counterweight: 'Całkowita długość bramy (przeciwwaga)',
      counterweight_confirmation: 'Potwierdzenie informacji o przeciwwadze',
      coverForTrolley: 'Maskownica do wózków',
      coveringColor: 'Kolor okładziny',
      darkGray: 'Ciemno-szary',
      default_male: 'Domyślny',
      default_female: 'Domyślna',
      degrees180: '180 stopni',
      depth: 'Wymiar D (mm)',
      depth_other: 'Inny wymiar D (mm)',
      destination: 'Przeznaczenie',
      detachedWall: 'Mur wolnostojący',
      diameter: 'Światło',
      dimmension_D: 'Wymiar D ($t(form:units.mm))',
      dimmension_H: 'Wymiar H ($t(form:units.mm))',
      dimmension_L: 'Wymiar L ($t(form:units.mm))',
      direction: 'Kierunek',
      direction_lPo: 'L/PO',
      direction_pPo: 'P/PO',
      direction_lUl: 'L/UL',
      direction_pUl: 'P/UL',
      directionConfirmation: 'Potwierdzam',
      directlyBurnoutInPost: 'Wypalenie bezpośrednio w słupku',
      directlyBurnoutInPost_colorInner:
         'Wybierz kolor blachy wewnętrznej (blacha zewnętrzna w kolorze słupka)',
      directlyBurnoutInPost_position: 'Określ położenie płyty',
      driver: 'Napęd',
      duskSensor: 'Czujnik zmierzchu',
      electricHarnessForEQPost: 'Wiązka elektryczna do słupka EQ',
      electricLock: 'Elektrozamek',
      equipment_over: 'Nad wyposażeniem',
      equipment_under: 'Pod wyposażeniem',
      equipment_inPoleColor: 'Wyposażenie w kolorze słupka',
      equipment_inPoleColorPDF: '[Wyposażenie w kolorze słupka]',
      expansionBolts: 'Kołki rozporowe',
      external: 'Zewnętrzny',
      factoryPlateCuttingService: 'Usługa fabrycznego cięcia płyty',
      flushWithTheLowerFrame: 'Równo z dolną ramą',
      foundation: 'Podmurówka',
      galvanized: 'Ocynk ogniowy',
      gapWidthBetweenPlates: 'Szerokość odstępu między płytami',
      gateModel: 'Model bramy',
      gateway: 'Furtka',
      gateWing: 'Bramowe',
      gateWithoutLock: 'Brama bez zamka',
      gateWithoutHandrails: 'Brama bez pochwytów',
      glued: 'Wklejane',
      glued180: 'Wklejane 180 stopni',
      graphite: 'Grafit',
      graphite_ral: 'Grafitowa RAL7016',
      graphite_7021SM: 'Grafit (Zbliżony do 7021SM)',
      handleHandle: 'Klamka/klamka (czarne)',
      handleHandrail: 'Klamka/pochwyt (czarne)',
      handrailHandrail: 'Pochwyt/pochwyt (czarne)',
      handrail: 'Pochwyt',
      handrail_blackSquareLong: 'Pochwyt kwadratowy czarny H 1400 mm',
      handrail_blackSquareShort: 'Pochwyt kwadratowy czarny H 800 mm',
      handrail_inoxRoundLong: 'Pochwyt okrągły INOX H 1400 mm',
      handrail_inoxRoundShort: 'Pochwyt okrągły INOX H 800 mm',
      handrail_inoxSquareLong: 'Pochwyt kwadratowy INOX (nierdzewny) H 1400 mm',
      handrail_inoxSquareShort: 'Pochwyt kwadratowy INOX (nierdzewny) H 800 mm',
      handrail_insideGate: 'Pochwyt wewnątrz bramy',
      handrail_nonStandard: 'Pochwyt niestandardowy',
      handrail_standardShortColorFromEstateOnly:
         'Standardowy pochwyt krótki w kolorze tylko od posesji',
      handrail_standardShortColorFromStreetOnly:
         'Standardowy pochwyt krótki w kolorze tylko od ulicy',
      handrail_standardLongColor: 'Standardowy pochwyt długi w kolorze RAL',
      handrail_standardShortColor: 'Standardowy pochwyt krótki w kolorze RAL',
      handrail_stainless: 'Pochwyt nierdzewny',
      handrail_stainlessLong: 'Pochwyt nierdzewny długi (140cm)',
      handrail_stainlessShort: 'Pochwyt nierdzewny krótki (80cm)',
      handrail_wicketWing: 'Pochwyt skrzydło furtkowe',
      handrail_none: 'Bez pochwytów',
      handrail_roundBlackLong: 'Pochwyt okrągły czarny H 1400 mm',
      handrail_roundBlackShort: 'Pochwyt okrągły czarny H 800 mm',
      height: 'Wysokość',
      height_other: 'Inna wysokość',
      height_gateSelfSupportingAccessStrip: 'Wysokość bramy samonośnej',
      height_gatewayClosingStrip: 'Wysokość furtki',
      height_singleLeafGateClosingStrip: 'Wysokość bramy jednoskrzydłowej',
      height_span: 'Wysokość przęsła',
      hinged: 'Zawiasowy',
      hinged_plural: 'Zawiasowe',
      hingedStandard:
         "$t(form:label.hinged, {'context': 'plural'})/$t(form:label.standard, {'context': 'plural'}) (2/2)",
      hingedGlued: "$t(form:label.hinged, {'context': 'plural'})/$t(form:label.glued) (2/2)",
      hinge_180: 'Zawias $t(form:label.degrees180)',
      hinge_180Confirmation: 'Potwierdzam wybór: $t(form:label.degrees180)',
      hinge_plural: 'Zawiasy',
      hinge_glued: 'Zawias wklejany',
      hinge_screwed: 'Zawias przykręcany',
      hingeType: 'Typ Zawiasu',
      holder: 'Marka',
      holder_cover: 'Maskownica do marki',
      holder_model: 'Model marki',
      holder_polePlace: 'Umieszczenie słupa względem marki',
      holder_size: 'Marka {{size}}',
      holder_type: 'Rodzaj marki',
      illuminatedSign: 'Podświetlany napis',
      illuminatedSign_location: 'Położenie napisu',
      illuminatedSign_phrase: 'Tekst podświetlenia',
      insertionDepth: 'Głębokość wbicia',
      integratedPlate: 'Zintegrowana płyta',
      internal: 'Wewnętrzny',
      inwards: 'Do wewnątrz',
      kns_plateColor: 'Kolor płyty KNS WALL',
      kns_plateCProfilesColor: 'Kolor ceowników do płyty KNS WALL',
      lampType: 'Typ lampki',
      ledLamp: 'Lampka LED',
      ledLamp_inPoleColor: 'Lampka LED w kolorze słupka',
      ledLamp_inPoleColorPDF: '[Lampka LED w kolorze słupka]',
      left_male: 'Lewy',
      left_female: 'Lewa',
      left_wing: 'Lewym',
      leftTopSpanCorner: 'Lewy górny róg przęsła',
      leftWing_handrail: 'Pochwyt lewe skrzydło',
      leftWing_width: 'Lewe skrzydło światło',
      length: 'Długość',
      lightGray: 'Jasno-szary',
      locinox: 'Samodomykacz hydrauliczny',
      location: 'Polożenie',
      lockSide: 'Strona zamka',
      long_male: 'Długi',
      long_female: 'Długa',
      longClosingStrip: 'Długa listwa domykowa',
      longDefault:
         "$t(form:label.long, {'context': 'female'}) ($t(form:label.default, {'context': 'female'}))",
      mailboxDiversoConfirmation:
         'Potwierdzam, że mój projekt ogrodzenia DIVERSO uwzględnia skrzynkę na listy.',
      mailbox_invisible: 'Skrzynka na listy "Invisible"',
      mailbox_kns: 'Skrzynka na listy "KNS"',
      mailbox_location: 'Określ położenie skrzynki',
      mailbox_NonStandardLocation: 'Niestandardowe umiejscowienie skrzynki na listy',
      mailbox_type: 'Typ skrzynki',
      mailboxWing: 'Skrzynka na skrzydle',
      maskBetweenWings: 'Maskownica prześwitu między skrzydłami',
      module: 'Moduł',
      module_color: 'Kolor modułu',
      module_colorOther: 'Inny kolor modułu',
      module_height: 'Wysokość modułu',
      module_plural: 'Moduły',
      mountingBasesSet: 'Komplet podstaw montażowych',
      mountingChannelColor: 'Kolor ceownika montażowego',
      mountingClamps: 'Klemy montażowe',
      mountingLocation: 'Miejsce montażu',
      mountingHoles: 'Otwory montażowe w słupku',
      mountingStrip: 'Listwa montażowa',
      mountingStrip_doYouNeed:
         'Potrzebujesz listwy montażowej za pomocą której zamontujesz przęsła do gabionów?',
      mountingStrip_amount: 'Ilość listwy montażowej',
      mountingStrip_color: 'Kolor listwy montażowej',
      mountingStrip_colorOther: 'Inny kolor listwy montażowej',
      mountingStrip_height: 'Wysokość listwy montażowej',
      no: 'Nie',
      noBolt: 'Brak rygla',
      noHandrailGate: 'Brama bez pochwytów',
      none: 'Brak',
      nonStandard: 'Niestandardowo',
      nonStandard_female: 'Niestandardowa',
      nonStandard_male: 'Niestandardowy',
      nonStandardDrawingRequired: '$t(form:label.nonStandard) ($t(form:label.requiresDrawing))',
      numberBurnoutInPostEquipment: 'Wypalenie numeru w wyposażeniu słupka',
      numberOfClamps: 'Ilość klem',
      oneKeySystem: 'System jednego klucza',
      other_male: 'Inny',
      other_female: 'Inna',
      other_step5mm: 'Inny (co {{value}}mm)',
      outside: 'Na zewnątrz',
      padlockHandle: 'Uchwyt na kłódki',
      padlockHandle_type: 'Rodzaj uchwytu na kłódki',
      phrase: 'Napis',
      plate_height: 'Wysokość płyty',
      plate_length: 'Długość zintegrowanej płyty',
      plate_position: 'Określ położenie blachy',
      pole: 'Słupek',
      poleHeight: 'Wysokość słupa',
      polePositionAgainstHolder: 'Umieszczenie słupa względem marki',
      poles: 'Słupy',
      polesInConcrete: 'Słupy do wbetonowania',
      polesOnHolder: 'Słupy na marce',
      poleProfile: 'Przekrój słupka',
      poleType: 'Typ słupka',
      poleWithAutomaticMachine: 'Słupek z automatem',
      poleWithAutomaticMachine_height: 'Wysokość słupka',
      poleWithFactoryRivetNuts: 'Słup z fabrycznie wstrzeloną nitonakrętką',
      position: 'Pozycja',
      prefabricatedPlate: 'Płyta prefabrykowana {{value}} mm',
      preparationForAutomation: 'Przygotowanie pod automatykę',
      provideBurnoutContent: 'Wpisz treść wypalenia',
      rawOption: 'Opcja na surowo',
      readyDimension: 'Wymiar na gotowo',
      requiresDrawing: 'Wymaga rysunku',
      resistanceStrip: 'Listwa rezystancyjna',
      resistanceStrip_confirmation: 'Potwierdzam wybór listwy rezystencjalnej',
      RFIDSticker: 'Naklejka RFID',
      RFIDStickerAmount: 'Ilość naklejek RFID',
      right_male: 'Prawy',
      right_female: 'Prawa',
      right_wing: 'Prawym',
      rightWing_handrail: 'Pochwyt prawe skrzydło',
      rightWing_width: 'Prawe skrzydło światło',
      rightTopSpanCorner: 'Prawy górny róg przęsła',
      rivetNuts: 'Nitonakrętki',
      rivets_amountPerProduct: 'Określ ilość nitów na ceownik',
      rivets_totalAmount: 'Całkowita ilość nitów',
      screwed: 'Przykręcane',
      screwed180: 'Przykręcane 180 stopni',
      screwIn: 'Wkręcany',
      series: 'Typoszereg',
      sheetMetalScrews: 'Blachowkręty',
      sheetMetalWithIndividualBurnout: 'Blaszka z indywidulanym wypaleniem',
      sheetMetalWithIndividualBurnout_colorInner:
         'Wybierz kolor blachy wewnętrznej (blacha zewnętrzna w kolorze słupka)',
      short_male: 'Krótki',
      short_female: 'Krótka',
      shortDefault:
         "$t(form:label.short, {'context': 'female'}) ($t(form:label.default, {'context': 'female'}))",
      slabColor: 'Kolor okładziny z blachy',
      slidingGate: 'Brama zjeżdżająca się',
      socket230: 'Gniazdko 230V',
      span: 'Przęsło',
      stainless_short: 'Nierdzewny krótki (80cm)',
      stainless_long: 'Nierdzewny długi (140cm)',
      standard: 'Standardowo',
      standard_female: 'Standardowa',
      standard_male: 'Standardowy',
      standardGlued: "$t(form:label.standard, {'context': 'plural'})/$t(form:label.glued) (2/2)",
      standard_plural: 'Standardowe',
      standard_short: 'Standardowy krótki',
      standardCentral: '$t(form:label.standard) ($t(form:label.central))',
      statementContentConfirmation: 'Potwierdzam o zapoznaniu się z treścią oświadczenia ',
      technicalIndent: 'Wycięcie techniczne',
      throughIn: 'Przelotowy',
      toThePole: 'Do słupa',
      top: 'Góra',
      tousekBasic: 'Słupek TOUSEK Basic',
      tousekPremium: 'Słupek TOUSEK Premium',
      type: 'Typ',
      yes: 'Tak',
      verticaleOption: 'Rodzaj VERTICALE',
      wicketWing: 'Furtkowe',
      width: 'Szerokość',
      width_gate: 'Światło bramy',
      width_gateOther: 'Inne światło bramy',
      width_gateway: 'Światło furtki',
      width_gatewayOther: 'Inne światło furtki',
      width_other: 'Inna szerokość',
      width_gateSelfSupportingAccessStrip: 'Szerokość bramy samonośnej',
      width_gatewayClosingStrip: 'Szerokość furtki',
      width_singleLeafGateClosingStrip: 'Szerokość bramy jednoskrzydłowej',
      width_span: 'Szerokość przęsła',
      width_wings: 'Światło skrzydeł',
      windChestLength: 'Długość wiatrownicy',
      wing_left: 'Lewe skrzydło',
      wing_right: 'Prawe skrzydło',
      wire: 'Drut',
      wire_4: 'Drut 4/4 mm',
      wire_5: 'Drut 5/5 mm',
   },
   link: {
      eq: 'Zobacz przykładowe konfiguracje wyposażenia dla różnych wysokości słupka',
      polesInGateSelfSupporting: 'Zobacz jak zamawiać słupy na bramie samonośnej',
      readyDimension: 'Co to jest wymiar na gotowo? (PDF)',
      technicalDrawing: 'Zobacz rysunek techniczny',
      technologicalCard: 'Zobacz kartę technologiczną',
   },
   validation: {
      mixed: {
         required: 'Wartość wymagana',
      },
      string: {
         min: 'Minimalna wartość {{min}}',
         max: 'Maksymalna wartość {{max}}',
         email: 'Niepoprawny adres email',
         handrailSelection: 'Podana wysokość nie może być mniejsza niż wybrany pochwyt',
      },
      number: {
         min: 'Minimalna wartość {{min}}',
         max: 'Maksymalna wartość {{max}}',
         integer: 'Wartość powinna być liczbą całkowitą',
         dividedBy: 'Wartość powinna być podzielna przez {{dividedBy}}',
         moreThan: 'Wartość powinna być większa niż {{more}}',
         lessThan: 'Wartość powinna być mniejsza niż {{less}}',
      },
   },
   placeholder: {
      select: 'Wybierz',
      select_channelColor: 'Wybierz kolor ceownika',
      select_mountingChannelColor: 'Wybierz kolor ceownika montażowego',
      select_moduleColor: 'Wybierz kolor modułu',
   },
   description: {
      headline: {
         usage: 'Zastosowanie:',
         remember: 'Pamiętaj o tym, że:',
      },
      content: {
         automaticPoleWithGateSelfSupporting:
            'Ten słupek z automatem jest powiązany z produktem "Brama samonośna" znajdującym się pod numerem: {{productOrder}}.',
         closingStrips: 'Sprawdź standardowe listwy zamykające dla modeli bram',
         diversoStrengthening:
            'Wszystkie projekty modelu <strong>DIVERSO</strong> zawierające kształtownik o przekroju 20x20mm zostają dodatkowo wzmocnione płaskownikiem umiejscowionym na środku. Płaskownik jest stosowany od szerokości 1500 mm i dotyczy zarówno przęseł jak i wypełnienia w bramach samonośnych i uchylnych.',
         foldingGate: {
            additionalOptions:
               'Bramy łamane są standardowo wyposażone w pochwyt od wewnątrz bramy.',
         },
         gateSelfSupportingWithAutomaticPole:
            'Ta brama samonośna jest powiązana z produktem "Słupek z automatem" znajdującym się pod numerem: {{productOrder}}.',
         p302: 'Kolor wybrany w polu “Wybierz kolor Twojego ogrodzenia” dotyczy elementów konstrukcyjnych furtki i bramy.',
      },
   },
   explanation: {
      asymmetricGates: {
         message:
            'Światło dla bramy: lewe - {{leftWingWidthValue}} cm, prawe - {{rightWingWidthValue}} cm. Razem: {{wingsWidth}} cm.',
         info: 'Światło bramy obliczane jest na podstawie sumowania zaokrąglonych wartości pól skrzydeł, prawego i lewego.',
      },
      blockPoles: {
         blocks:
            'Wysokość słupa jest wielokrotnością bloczka o wymiarach 600x296x300 mm układanego pionowo (jeden na drugim). Miedzy bloczkami zastosowane zostanie trwałe łącznie. Słupek nie uwzględnia daszka.',
         blocksWithPots:
            'Wysokość słupa jest wielokrotnością bloczka o wymiarach 600x296x300 mm układanego pionowo (jeden na drugim). Miedzy bloczkami zastosowane zostanie trwałe łącznie. Na szczycie słupka znajduje się doniczka. Wkład i podstawa doniczki w zestawie.',
      },
      closingStrips: {
         customTypeConfirmation: 'Wybrany rodzaj listwy nie jest domyślny dla danego modelu.',
      },
      colore: 'Colore nie może być taki sam, jak główny kolor zamówienia.',
      connectorsForXPSConstruction: {
         height: 'Dopuszczalna wysokość ceownika montażowego: 301 mm - 2510 mm',
         totalNumberOfRivets: 'Nity są wielokrotnością ilości danego produktu: {{quantity}}.',
      },
      direction: 'Kierunek oceniamy stojąc na ulicy i patrząc na posesję.',
      directionInformation: 'Informacja o otwieraniu furtek na zewnątrz. ',
      foldingGate: {
         height: 'Skrzydło bramy należy zamontować na słupach bramowych zachowując 60 mm od ławy.',
         width: '$t(form:explanation.gateWidth, {{widthInCm}}) W przypadku bram łamanych dwuskrzydłowych dopuszczalny jest tylko kierunek otwierania bramy na posesję.',
      },
      functionalPoles: {
         additionalPowerSupply:
            'Dodatkowy zasilacz obsługuje jednocześnie do 5 lampek BLIX (SMALL/BIG)',
         duskSensor:
            'Do lampki BLIX z czujnikiem zmierzchu rekomendujemy zakup dodatkowego zasilacza.',
         equipment_over:
            'Wypalenie w słupku będzie umiejscowione w równej odległości miedzy górną krawędzią słupka (lub lampki led) a górną krawędzią wyposażenia słupka.',
         equipment_under:
            'Wypalenie w słupku zostanie umiejscowione 40 mm od dolnej krawędzi wybranego wyposażenia słupka EQ.',
         ledLamp:
            'Dodanie lampki zmienia minimalną wysokość słupka. Upewnij się, że podana wysokość jest prawidłowa.',
         mailboxLocation:
            'Podaj w mm wysokość skrzynki na listy licząc od podstawy słupka do dolnej krawędzi skrzynki na listy.',
      },
      handrail: {
         length: 'Długość pochwytu wyniesie: {{lengthValue}} cm.',
      },
      holder: {
         type: 'W przypadku wyboru opcji inna niezbędnym jest dodanie rysunku technicznego marki.',
         cover: 'UWAGA: Brak opcji maskownicy do marek przy szerokości od {{value}} mm',
         cover_asymmetricGate:
            'UWAGA: brak opcji maskownicy do marek gdy większe skrzydło jest szersze niż {{value}} mm.',
         polePlace: 'Standardowo (centralnie). Niestandardowo (wymagany rysunek).',
      },
      mailbox_color: 'Skrzynka domyślnie jest w tym samym kolorze co przęsło. ',
      mailbox_position:
         'Twoja skrzynka na listy znajdzie sie na wysokości: {{mailboxPosition}} mm (umiejscowiona centralnie).',
      module: {
         info: 'Wpisz wysokość modułu w przedziale 300-1000 cm. Pamiętaj aby wysokość modułu nie była większa niż całkowita wysokość bramy.',
         value: 'Moduł w bramie będzie miał wysokość: {{value}} cm.',
      },
      other: {
         appliedGalvanized: 'Zastosowano Ocynk Ogniowy.',
      },
      others: {
         mailboxLocation: 'Położenie skrzynki oceniam stojąc na ulicy i spoglądając na ogrodzenie',
      },
      poleWithAutomaton: {
         withGateSelfSupporting: 'Wysokość słupka jest równa wysokości bramy samonośnej + 100mm',
         withoutGateSelfSupporting:
            'Wpisz wysokość równą bramie samonośnej do której zamawiasz słupek. W zamówieniu zostanie automatycznie dodane +100 mm do h słupka z automatem.',
      },
      preparationForAutomation:
         'Przygotowanie pod automatykę dostępne dla bram od wysokości 1000 mm.',
      required: 'Wartość wymagana',
      charge_extra: 'Dodatkowa opłata {{extraCharge}}%',
      charge_none: 'Brak dodatkowej opłaty.',
      gateHeight: 'Twoja brama będzie miała wysokość {{heightInCm}} cm. ',
      gateHeight_module: 'Wysokość bramy powinna być większa od wysokości modułu.',
      gateWidth: 'Twoja brama będzie miała światło {{widthInCm}} cm. ',
      gatewayHeight: 'Twoja furtka będzie miała wysokość {{heightInCm}} cm. ',
      gatewayLongerThan1500:
         'W przypadku furtek dłuższych niż 1500 mm użyj formularza bram jednoskrzydłowych. ',
      gatewayWidth: 'Twoja furtka będzie miała szerokość {{widthInCm}} cm. ',
      heightAttention: 'Uwaga: Wysokość większa niż {{value}} mm.',
      heightAttention_span:
         'UWAGA: {{model}} nie może być wyższe niż 1440 mm. Jeśli szerokość nie przekracza 1440 mm maksymalna wysokość może wynieść 2000 mm.',
      hinge180Confirmation:
         'Uwaga, stosowanie zawaisu 180 stopni nie hest rozwiązaniem zalecanym przez producenta.',
      mailboxAvailablity_invisible:
         'Skrzynka "Invisible" dostępna przy minimalnej wysokości {{value}} mm.',
      mailboxAvailablity_kns: 'Skrzynka "KNS" dostępna przy minimalnej wysokości {{value}} mm.',
      noHandrail: 'Standardem dla bram łamanych jest pochwyt krótki tylko od wewnątrz bramy.',
      nonStandardSize: 'Wymiar niestandardowy wiąże się z dodatkową opłatą.',
      resistanceStrip: 'Wybór listwy rezystancyjnej oznacza brak zamka w bramie.',
      resistanceStrip_gateSelfSupporting:
         'Wybór nie jest możliwy, jeśli wybrano system jednego klucza',
      roundValue: 'Twoja wartość zostanie zaokrąglona do {{value}} cm.',
      spacing: 'Rozstaw dla tej wysokości wyniesie {{spacing}} mm.',
      span: {
         height: 'Elementy mogą być do 10 mm wyższe niż wysokość zamówienia.',
      },
      statementContent: 'Treść oświadczenia',
      wicketGate: {
         holder_model: 'Uwaga! Markę dopasowujemy do większego skrzydła',
         poles: 'Uwaga! Słupy dopasowujemy do większego skrzydła',
         diameter:
            'Światło dla lewego i prawego skrzydła obliczane jest na podstawie sumowania zaokrąglonych wartości pól skrzydeł. Światło dla bramy: lewe - {{leftWingWidthValue}} cm, prawe - {{rightWingWidthValue}} cm. Razem: {{wingsWidth}} cm. ',
      },
      width_gatewayOther: 'Inne światło furtki',
      wingWidth_left: 'Lewe skrzydło twojej bramy będzie miało szerokość {{value}} cm. ',
      wingWidth_right: 'Prawe skrzydło twojej bramy będzie miało szerokość {{value}} cm. ',
      wingWidth_min: 'Minimalne światło skrzydła to 1 cm. Maksymalne światło to {{max}} cm.',
   },
   message: {
      addAutomaticPoleError: 'Nie udało się dodać słupka z automatem. Spróbuj jeszcze raz.',
      gateSelfSupportingSlidingGateValidation: 'Brak powiązanej bramy zjeżdżającej w zamówieniu.',
      gateSelfSupportingWithAutomaticPoleValidation:
         'Kosztorys z bramą samonośną z opcją "Słupek z automatem" musi uwzględniać osobny produkt "Słupek z automatem". Użyj przycisku "dodaj słupek z automatem" aby umieścić go w kosztorysie.',
      modelNotServed: 'Model nie obsługiwany',
   },
   tooltip: {
      alt: {
         asymmetricGate_width: 'Brama asymetryczna światło',
         blixLampPole: 'Słupek z lampkątool Blix-{{type}}',
         colore: 'Colore',
         direction: 'Kierunek',
         height: 'Wysokość',
         height_pole: 'Wysokość słupka',
         width: 'Szerokość',
      },
   },
   section: {
      title: {
         additionalOptions: 'Dodatkowe opcje',
         attachments: 'Załączniki',
         assembly: 'Montaż',
         automation: 'Automatyka/Zamki/Słupy do bramy',
         automationAndPoles: 'Automatyka i słupy',
         basicInformation: 'Podstawowe informacje',
         burnout: 'Wypalenie',
         handrail_plural: 'Pochwyty',
         hinge_plural: 'Zawiasy',
         holder: 'Marka',
         illuminatedSign: 'Podświetlany napis',
         lock_plural: 'Zamki',
         mailbox_plural: 'Skrzynki na listy',
         module: 'Moduł',
         mountingStrip: 'Listwa montażowa',
         padlockHandle: 'Uchwyt na kłódki',
         pole_plural: 'Słupy',
      },
   },
   button: {
      addAutomaticPole: 'Dodaj słupek z automatem',
      addGateSelfSupporting: 'Dodaj drugą bramę samonośną',
      technicalCutting: 'Wykonaj rysunek techniczny wycięcia',
      modelsInfo: 'Informacje o modelach',
      fold: '(zwiń)',
      unfold: '(rozwiń)',
   },
   table: {
      fold: 'Zwiń',
      unfold: 'Rozwiń',
      locks: {
         assortment: 'Asortyment',
         allAvailableModels: 'Dostępne wszystkie modele',
         destination: 'Przeznaczenie',
         fenceModel: 'Model ogrodzenia',
         gateTilting_plural: 'Bramy uchylne',
         gateSelfSupporting_plural: 'Bramy samonośne',
         gateLock: 'Zamek bramowy',
         gate_plural: 'Bramy',
         highwayWickets: 'Furtki autostradowe',
         insertAndKey: 'Wkadka mosidz + nikiel, klucz mosidz + nikiel',
         upTo: 'do',
         wicket_plural: 'Furtki',
         wicketLock: 'Zamek furtkowy',
         wicketWithoutElectricLock: 'Furtki bez elektrozaczepu',
      },
      mountingBrackets: {
         model: 'Model',
         mountingBracketType: 'Rodzaj obejmy',
         comments: 'Uwagi',
         description: 'Opis',
         mountingGrip_long: 'Uchwyt montażowy - długi',
         mountingGrip_short: 'Uchwyt montażowy - krótki',
         noMountingBrackets: 'Brak obejm',
      },
   },
};
