import axios from 'axios';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import InternalContainer from 'Layouts/InternalContainer';
import PackingPlace from 'Pages/WarehouseForklift/PackingPlace';
import { extractUniqueClientsFromOrderGroups } from 'Shared/PaintShop/filters';
import defaults from 'Shared/useQuery';
import ClientFilter from 'Shared/Warehouse/Filters/ClientFilter';
import { wareHouseFiltersInitialState } from 'state/warehouse';

import Context from './context';
import PutWeight from './PutWeight/PutWeight';
import ToBeDelivered from './ToBeDelivered/ToBeDelivered';
import Warehouse from './Warehouse/Warehouse';

const defaultFilters = wareHouseFiltersInitialState;

export const fetchGroupsToBeDelivered = ({ packingPlace, companyName }) =>
   axios.get('/warehouse/groups-for-forklift-to-be-delivered', {
      params: {
         packingPlace,
         companyName,
      },
   });

const fetchGroups = ({ companyName }) =>
   axios.get('/warehouse/groups-for-forklift', {
      params: {
         companyName,
      },
   });

const WarehouseForklift = () => {
   const dispatch = useDispatch();
   const groups = useSelector((s) => s.warehouse.groups);
   const filters = useSelector((s) => s.warehouse.filters);
   const {
      refetch: refetchToBeDelivered,
      dataUpdatedAt: dataUpdatToBeDelivered,
      error: errorWareToBeDelivered,
      isLoading: isLoadingToBeDelivered,
   } = useQuery(['fetchGroupsToBeDeliered', { filters }], () => fetchGroupsToBeDelivered(filters), {
      ...defaults,
      onSuccess: ({ data }) => {
         dispatch({
            type: 'FETCH_GROUPS_TO_BE_DELIVERED',
            groups: data.groups,
         });
      },
   });
   const {
      refetch: refetchWarehouse,
      dataUpdatedAt: dataUpdatedAtWarehouse,
      error: errorWarehouse,
      isLoading: isLoadingWarehouse,
   } = useQuery(['fetchGroups', { filters }], () => fetchGroups(filters), {
      ...defaults,
      onSuccess: ({ data }) => {
         dispatch({
            type: 'FETCH_GROUPS',
            groups: data.groups,
         });
      },
   });
   useEffect(() => {
      dispatch({ type: 'RESET_FILTERS', filters: defaultFilters });
   }, []);
   const refetch = () => {
      refetchToBeDelivered();
      refetchWarehouse();
   };
   useEffect(() => {
      refetch();
   }, [dispatch, filters]);
   const clients = extractUniqueClientsFromOrderGroups(groups);
   const value = useMemo(
      () => ({
         refetch,
      }),
      [refetch],
   );

   return (
      <InternalContainer>
         <Helmet>
            <title>Magazyn &gt; Wózek widłowy | Konsport</title>
         </Helmet>
         <div className="row">
            <div className="col">
               <Container>
                  <Context.Provider value={value}>
                     <div>
                        <h1>Magazyn</h1>
                     </div>
                     <Filters>
                        <ClientFilter clients={clients} />
                        <PackingPlace />
                     </Filters>
                     <PutWeight />
                     <ToBeDelivered
                        error={errorWareToBeDelivered}
                        dataUpdatedAt={dataUpdatToBeDelivered}
                        isLoading={isLoadingToBeDelivered}
                     />
                     <Warehouse
                        error={errorWarehouse}
                        dataUpdatedAt={dataUpdatedAtWarehouse}
                        isLoading={isLoadingWarehouse}
                     />
                  </Context.Provider>
               </Container>
            </div>
         </div>
      </InternalContainer>
   );
};

const Container = styled.div``;

const Filters = styled.div`
   display: flex;
   & > div {
      margin-right: 2rem;
   }
`;

export default WarehouseForklift;
