import axios from 'axios';
import * as dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { doesUserHasRole, loadUser } from 'auth';
import InternalContainer from 'Layouts/InternalContainer';
import LastUpdatedAt from 'Pages/Dashboards/Warehouse/LastUpdatedAt';
import CheckedInFilters from 'Shared/CheckedInFilters/CheckedInFilters';
import ImportOrderForm from 'Shared/ImportOrderForm';
import { Response } from 'Shared/Response';
import defaults from 'Shared/useQuery';
import OpenZamowieniaPdfForm from 'Shared/Warehouse/OpenZamowieniaPdfForm';
import { filterChanged } from 'state/warehouse';
import { Loader } from 'ui/Loader';

import { Context } from './Context';
import Orders from './Orders/Orders';

const today = new Date();

const fetchOrders = (filters) => {
   return axios.get('/paint-shop/orders', {
      params: {
         startedPaintingAt: filters.importedAt
            ? dayjs(filters.importedAt).format('YYYY-MM-DD')
            : null,
         startedPaintingAtFrom: filters.importedAtRange?.from
            ? dayjs(filters.importedAtRange?.from).format('YYYY-MM-DD')
            : null,
         startedPaintingAtTo: filters.importedAtRange?.to
            ? dayjs(filters.importedAtRange?.to).format('YYYY-MM-DD')
            : null,
         zamowieniaId: filters.zamowieniaId,
      },
   });
};

const fetchHooks = () => {
   return axios.get('/paint-shop/hooks');
};

const PaintShop = () => {
   const dispatch = useDispatch();
   const user = loadUser();
   const [loading, setLoading] = useState(false);
   const [info, setInfo] = useState(null);
   const orders = useSelector((state) => state.warehouse.orders);
   const filters = useSelector((state) => state.warehouse.filters);
   const manager = doesUserHasRole('ROLE_LAKIERNIA', user?.roles);
   const enabled = !!(
      filters.importedAt ||
      (filters.importedAtRange?.from && filters.importedAtRange?.to) ||
      filters.zamowieniaId
   );
   useEffect(() => {
      dispatch(filterChanged('importedAt', today));
   }, [dispatch]);
   const { refetch, isLoading, dataUpdatedAt, error } = useQuery(
      ['fetchOrders', { filters }],
      () => fetchOrders(filters),
      {
         ...defaults,
         enabled,
         onSuccess: ({ data }) => {
            setInfo(null);
            dispatch({
               type: 'PAINT_SHOP_FETCHED_ORDERS',
               data: data.orders,
            });
         },
         onError: (err) => {
            setInfo(err.response?.data?.message ?? 'Wystąpił błąd. Spróbuj jeszcze raz.');
         },
      },
   );
   const { refetch: refetchHooks } = useQuery(['fetchHooks'], () => fetchHooks(), {
      ...defaults,
      enabled,
      onSuccess: ({ data }) => {
         setInfo(null);
         dispatch({
            type: 'PAINT_SHOP_HOOKS_FETCHED',
            data: data.hooks,
         });
      },
      onError: (err) => {
         setInfo(err.response?.data?.message ?? 'Wystąpił błąd. Spróbuj jeszcze raz.');
      },
   });
   useEffect(() => {
      if (!enabled) return;
      refetch();
      refetchHooks();
   }, [dispatch, filters]);

   const value = useMemo(
      () => ({ manager, loading, setLoading, refetch, refetchHooks }),
      [manager, loading, setLoading, refetch, refetchHooks],
   );

   return (
      <Context.Provider value={value}>
         <Helmet>
            <title>Lakiernia | Konsport</title>
         </Helmet>
         <InternalContainer>
            <div className="row">
               <div className="col">
                  <div>
                     <h1>Lakiernia</h1>
                  </div>
                  <ActionsContainer>
                     <FormsContainer>
                        {!manager && (
                           <ImportOrderForm
                              refetch={refetch}
                              refetchHooks={refetchHooks}
                              loading={loading}
                              setLoading={setLoading}
                              chooseHook
                           />
                        )}
                        <OpenZamowieniaPdfForm />
                     </FormsContainer>
                     <CheckedInFilters />
                  </ActionsContainer>
                  <Orders orders={orders} refetch={refetch} />
                  <ResponseStyled messages={[info]} error={error} />
                  {(isLoading || loading) && <Loader />}
                  <LastUpdatedAt updatedAt={dataUpdatedAt} />
               </div>
            </div>
         </InternalContainer>
      </Context.Provider>
   );
};

const ActionsContainer = styled.div`
   display: flex;
   flex-direction: column;
`;

const FormsContainer = styled.div`
   > form:not(:last-child) {
      margin-bottom: 2rem;
   }
`;

const ResponseStyled = styled(Response)`
   font-size: 2rem;
`;

export default PaintShop;
