import { useContext } from 'react';
import styled from 'styled-components';

import { Context } from '../../Context';

// eslint-disable-next-line consistent-return
const PackedButton = ({ group }) => {
   const { setGroupForFinalPaletteModal } = useContext(Context);
   if (!group.canBePacked) return <Info>Czeka na dostarczenie ze strefy</Info>;
   if (group.canBePacked)
      return (
         <button
            type="button"
            className="btn btn-success"
            onClick={() => setGroupForFinalPaletteModal(group)}
         >
            Spakuj na gotowo
         </button>
      );
};

const Info = styled.span`
   color: #ff0000;
`;

export default PackedButton;
