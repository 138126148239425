import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { Token } from './auth.types';

export const doesUserHasRole = (role: string, roles?: string[]): boolean => {
   return roles ? roles.some((r) => r === role) : false;
};

export const signOut = (): void => {
   localStorage.removeItem('token');
   localStorage.removeItem('refreshToken');
   axios.defaults.headers.common.Authorization = '';
   window.location.replace('/');
};

export const setTokens = (token: string, refreshToken: string): void => {
   localStorage.setItem('token', token);
   localStorage.setItem('refreshToken', refreshToken);
   axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const rolesTranslation = {
   ROLE_MAGAZYN: 'Kierownik magazynu',
   ROLE_WAREHOUSE_FORKLIFT: 'Wózek',
   ROLE_LAKIERNIA: 'Kierownik lakierni',
   ROLE_WIESZAKI: 'Lakiernia',
   ROLE_PAINT_SHOP_PAINTING: 'Lakiernia > Napylanie',
   ROLE_WAREHOUSE_SENDING: 'Wysyłacz',
   ROLE_PRODUCTION: 'Produkcja',
   ROLE_PACKING: 'Pakowanie na strefe/na gotowo',
   ROLE_PACKING_INTO_WAREHOUSE: 'Pakowanie na magazyn',
   ROLE_FINISHING_GATES: 'Zbrojenie bram',
} as const;

export const loadUser = (): Token | null => {
   const token = localStorage.getItem('token');
   const refreshToken = localStorage.getItem('token');

   if (!token || !refreshToken) return null;

   return jwtDecode(token);
};
