import { useSelector } from 'react-redux';
import styled from 'styled-components';

import OrderZamowieniaId from 'Shared/OrderZamowieniaId';
import { extractUniqueClientsFromOrderGroups } from 'Shared/PaintShop/filters';
import ResetFiltersButton from 'Shared/ResetFiltersButton';
import ClientFilter from 'Shared/Warehouse/Filters/ClientFilter';

import AccountManagerFilter from './AccountManagerFilter';
import OrdersGroupStatusFilter from './OrdersGroupStatusFilter';

const Container = styled.div`
   display: flex;
   flex-direction: column;
   & > div {
      display: flex;
      align-items: flex-end;
      margin-bottom: 1rem;
      &:last-child {
         margin-bottom: 2rem;
      }
      & > div {
         width: 250px;
         &:not(:last-child) {
            margin-right: 1rem;
         }
      }
   }
   label {
      width: 100%;
   }
`;

const extractUniqueAccountManagersFromOrderGroups = (orderGroups) => {
   const accountManagers = orderGroups.map((og) => og.orderParts[0].order.userAccountManager);

   return [...new Set(accountManagers)];
};

const Filters = () => {
   const orderGroups = useSelector((state) => state.warehouse.orderGroups);
   const clients = extractUniqueClientsFromOrderGroups(orderGroups);
   const accountManagers = extractUniqueAccountManagersFromOrderGroups(orderGroups);

   return (
      <Container>
         <div>
            <OrdersGroupStatusFilter />
            <ClientFilter clients={clients} />
            {/* TODO */}
            <AccountManagerFilter accountManagers={accountManagers} />
            <OrderZamowieniaId />
            <ResetFiltersButton />
         </div>
      </Container>
   );
};

export default Filters;
