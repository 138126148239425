import styled, { css } from 'styled-components';

export const Label = styled.label(
   ({ theme: { spacing } }) => css`
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: ${spacing(3)};
   `,
);
