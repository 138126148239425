import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styled from 'styled-components';

import OrderZamowieniaId from 'Shared/OrderZamowieniaId';

import ExportButton from './ExportButton';
import PaintShopResetFiltersButton from './PaintShopResetFiltersButton';
import StartedPaintingAt from './StartedPaintingAt';
import StartedPaintingAtRange from './StartedPaintingAtRange';

const Filters = () => {
   const [show, setShow] = useState(false);
   const toggle = () => setShow((s) => !s);

   return (
      <Container>
         <HeaderContainer>
            {show && <PaintShopResetFiltersButton />}
            <div>
               <button type="button" className="btn" onClick={toggle}>
                  <FontAwesomeIcon icon={faFilter} />
               </button>
            </div>
         </HeaderContainer>
         {show && (
            <InnerContainer>
               <InnerContainerTop>
                  <OrderZamowieniaIdStyled />
               </InnerContainerTop>
               <InnerContainerBottom>
                  <StartedPaintingAt />
                  <div>
                     <Sep>lub</Sep>
                  </div>
                  <StartedPaintingAtRange />
               </InnerContainerBottom>
               <ExportContainer>
                  <ExportButton />
               </ExportContainer>
            </InnerContainer>
         )}
      </Container>
   );
};

const Container = styled.div`
   display: flex;
   flex-direction: column;
`;

const HeaderContainer = styled.div`
   display: flex;
   justify-content: flex-end;
   & > div:not(:last-child) {
      margin-right: 1rem;
   }
`;

const InnerContainer = styled.div`
   display: flex;
   flex-direction: column;
`;

const InnerContainerTop = styled.div`
   display: flex;
   justify-content: flex-end;
   align-items: flex-start;
   margin-bottom: 1rem;
`;

const InnerContainerBottom = styled.div`
   display: flex;
   justify-content: flex-end;
   align-items: flex-start;
`;

const Sep = styled.span`
   padding-top: 2rem;
   display: inline-block;
   margin: 0 2rem;
`;

const OrderZamowieniaIdStyled = styled(OrderZamowieniaId)`
   width: 260px;
`;

const ExportContainer = styled.div`
   display: flex;
   justify-content: flex-end;
`;

export default Filters;
