import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { common_en } from 'translations/en/common_en';
import { form_en } from 'translations/en/form_en';
import { common_pl } from 'translations/pl/common_pl';
import { form_pl } from 'translations/pl/form_pl';

i18n
   .use(initReactI18next)
   .use(LanguageDetector)
   .init({
      detection: {
         order: ['localStorage', 'navigator'],
         caches: ['localStorage'],
      },
      resources: {
         en: { common: common_en, form: form_en },
         pl: { common: common_pl, form: form_pl },
         de: { common: common_pl, form: form_pl },
      },
      ns: 'common',
      interpolation: { escapeValue: false },
      supportedLngs: ['de', 'en', 'pl'],
      fallbackLng: 'en',
   });

export default i18n;
