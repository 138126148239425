import { useContext } from 'react';

import { ORDER_ATTRIBUTE_SKIP_PAINTING, paintShopPaintOptions } from 'Shared/Warehouse/OrderPart';

import { Context } from '../Context';

const PaintOptionsManager = ({ order }) => {
   const { manager } = useContext(Context);
   if (!manager) return null;
   const saved = order.attributes.find((a) => a.key === ORDER_ATTRIBUTE_SKIP_PAINTING)?.value || [];
   const rendered = paintShopPaintOptions.map((o) =>
      saved.includes(o.id) ? <li key={o.id}>{o.label}</li> : null,
   );
   return (
      <div>
         <div>
            <span>Bez malowania</span>
         </div>
         <ul>{rendered}</ul>
      </div>
   );
};

export default PaintOptionsManager;
